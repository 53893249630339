import { io } from "socket.io-client";
import env from "../env";

let socket = null;
let manager = null;

export const initSocket = (socketUrl, socketPath) => {
  if (manager === null && socket === null) {
    if (socketUrl === undefined || socketPath === undefined) {
      socketUrl = env.SOCKET;
      socketPath = env.SOCKET_PATH;
    }
    manager = new io.Manager(socketUrl, {
      path: socketPath,
      extraHeaders: {},
      timeout: 5000,
      transports: ["websocket"],
    });
    socket = manager.socket("/", {
      path: socketPath,
    });
  }
};

export const getSocket = (socketUrl, socketPath) => {
  if (!socket) initSocket(socketUrl, socketPath);
  return socket;
};

export const getManager = (socketUrl, socketPath) => {
  if (!manager) initSocket(socketUrl, socketPath);
  return manager;
};
