import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18next";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "./LanguageSelector.scss";

import {
  selectors as AppSelectors,
  actions as AppActions,
} from "../../redux/app/redux";

import Bloc from "../../atoms/Bloc/Bloc";
import conf from "../../conf";

const LanguageSelector = React.memo(
  () => {
    const dispatch = useDispatch();
    const { t } = useTranslation("translation", {
      keyPrefix: "languageSelector",
    });
    const lang = useSelector(AppSelectors.lang);

    const onChange = useCallback(
      (e) => {
        dispatch(AppActions.setLang(e.target.value))
        i18n.changeLanguage(e.target.value)
        const prevMomentLocale = moment.locale()
        if (moment.locale(e.target.value) === prevMomentLocale)
          moment.locale('en')
      },
      []
    );

    const options = useMemo(() => 
      conf.LANGUAGE_SELECTOR_DATA.map(({lang, label}) => ({value: lang, label}))
    , [])

    return (
      <Bloc className="language-selector-container">
        <select
          onChange={onChange}
          defaultValue={lang}
          className="language-selector"
        >
          {options.map(({ value, label }, index) => (
            <option key={index} value={value}>
              {t(label)}
            </option>
          ))}
        </select>
      </Bloc>
    );
  }
);

LanguageSelector.displayName = "LanguageSelector";

LanguageSelector.propTypes = {};

export default LanguageSelector;
