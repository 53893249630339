import { createAction, createReducer } from "redux-act";
import makeImmutable from "../makeImmutable";

const initialState = {
  isCreationPopupOpen: false,
  editFocusedEventId: null,
  eventsByDates: {},
  eventByIds: {},
  concurrentEvents: [],
  concurrentBlockingEvents: [],
  isCheckingConcurrentEvents: false,
  isSendingRequest: false,
  errorRequest: "",
};

export const actions = {
  openCreationPopup: createAction("open creation popup"),
  closeCreationPopup: createAction("close creation popup"),
  setEditFocusedEventId: createAction(
    "set edit focused event id",
    (eventId) => ({
      eventId,
    })
  ),
  removeEditFocusedEventId: createAction("remove edit focused event id"),
  requestLoadEvents: createAction(
    "request load next events",
    (startDate, endDate) => ({
      startDate,
      endDate,
    })
  ),
  loadEventsSuccess: createAction("load next events success", (events) => ({
    events,
  })),
  requestCreateEvent: createAction("request create event", (event) => ({
    event,
  })),
  requestEditEvent: createAction("request edit event", (eventId, event) => ({
    eventId,
    event,
  })),
  requestDeleteEvent: createAction("request delete event", (eventId) => ({
    eventId,
  })),
  setIsSendingRequest: createAction("set is sending request"),
  requestFailed: createAction("request failed", (errorCode) => ({
    errorCode,
  })),
  clearRequestError: createAction("clear request error"),
  createEventSuccess: createAction("create event success", (event) => ({
    event,
  })),
  editEventSuccess: createAction("edit event success", (eventId, event) => ({
    eventId,
    event,
  })),
  deleteEventSuccess: createAction("delete event success", (eventId) => ({
    eventId,
  })),
  requestCheckConcurrentEvents: createAction(
    "request check concurrent events",
    (startDate, endDate, eventId) => ({
      startDate,
      endDate,
      eventId,
    })
  ),
  loadConcurrentEventsSuccess: createAction(
    "create event success",
    (events) => ({
      events,
    })
  ),
  loadConcurrentBlockingEventsSuccess: createAction(
    "create event success",
    (events) => ({
      events,
    })
  ),
  setIsCheckingConcurrentEvents: createAction(
    "set is checking current events",
    (data) => ({
      data,
    })
  ),
  clearAll: createAction("clear all"),
  requestLoadEvent: createAction("request load event", (type, eventId) => ({
    type,
    eventId,
  })),
  loadEventCreatedSuccess: createAction(
    "load event created success",
    (event) => ({
      event,
    })
  ),
  loadEventEditedSuccess: createAction(
    "load event edited success",
    (eventId, event) => ({
      eventId,
      event,
    })
  ),
  eventDeleted: createAction("event deleted", (eventId) => ({
    eventId,
  })),
};

function addEventToDateAndSort(state, event) {
  if (!event) return;
  const day = new Date(event.scheduleDate);
  day.setHours(0, 0, 0, 0);
  state.eventByIds[event.id] = event;
  if (state.eventsByDates[Number(day)]) {
    state.eventsByDates[Number(day)].push(event);
    state.eventsByDates[Number(day)].sort(
      (a, b) =>
        new Date(a.scheduleDate).getTime() - new Date(b.scheduleDate).getTime()
    );
  } else {
    state.eventsByDates[Number(day)] = [event];
  }
}

function findEventInDateAndRemove(
  state,
  eventId,
  deleteFromEventByIds = false
) {
  const oldEvent = state.eventByIds[eventId];
  if (!oldEvent) return;
  const oldDay = new Date(oldEvent.scheduleDate);
  oldDay.setHours(0, 0, 0, 0);
  if (state.eventsByDates[Number(oldDay)]) {
    const eventIndex = state.eventsByDates[Number(oldDay)].findIndex(
      (data) => data.id === eventId
    );
    if (eventIndex > -1) {
      state.eventsByDates[Number(oldDay)].splice(eventIndex, 1);
      if (state.eventsByDates[Number(oldDay)].length === 0)
        delete state.eventsByDates[Number(oldDay)];
    }
    if (deleteFromEventByIds) delete state.eventByIds[eventId];
  }
}

const loadEventsSuccess = (state, { events }) => {
  const newEventByIds = {};
  const newEventsByDates = {};
  events.map((data) => {
    newEventByIds[data.id] = data;
    const day = new Date(data.scheduleDate);
    day.setHours(0, 0, 0, 0);
    if (newEventsByDates[Number(day)]) {
      newEventsByDates[Number(day)].push(data);
    } else {
      newEventsByDates[Number(day)] = [data];
    }
  });
  state.eventByIds = { ...state.eventByIds, ...newEventByIds };
  state.eventsByDates = { ...state.eventsByDates, ...newEventsByDates };
};

const createEventSuccess = (state, { event }) => {
  addEventToDateAndSort(state, event);
  state.errorRequest = "";
  state.isSendingRequest = false;
};

const editEventSuccess = (state, { eventId, event }) => {
  findEventInDateAndRemove(state, eventId);
  addEventToDateAndSort(state, event);
  state.errorRequest = "";
  state.isSendingRequest = false;
};

const deleteEventSuccess = (state, { eventId }) => {
  findEventInDateAndRemove(state, eventId, true);
  state.errorRequest = "";
  state.isSendingRequest = false;
};

const loadEventCreatedSuccess = (state, { event }) => {
  addEventToDateAndSort(state, event);
};

const loadEventEditedSuccess = (state, { eventId, event }) => {
  if (eventId === state.editFocusedEventId) state.editFocusedEventId = null;
  findEventInDateAndRemove(state, eventId);
  addEventToDateAndSort(state, event);
};

const eventDeleted = (state, { eventId }) => {
  if (eventId === state.editFocusedEventId) state.editFocusedEventId = null;
  findEventInDateAndRemove(state, eventId, true);
};

const checkConcurrentEventsSuccess = (state, { events }) => {
  state.concurrentEvents = events;
  state.concurrentBlockingEvents = [];
  state.isCheckingConcurrentEvents = false;
};
const checkConcurrentBlockingEventsSuccess = (state, { events }) => {
  state.concurrentBlockingEvents = events;
  state.concurrentEvents = [];
  state.isCheckingConcurrentEvents = false;
};

const reducers = {
  [actions.openCreationPopup]: (state) => {
    state.isCreationPopupOpen = true;
  },
  [actions.closeCreationPopup]: (state) => {
    state.isCreationPopupOpen = false;
  },
  [actions.setEditFocusedEventId]: (state, { eventId }) => {
    state.editFocusedEventId = eventId;
  },
  [actions.removeEditFocusedEventId]: (state) => {
    state.editFocusedEventId = null;
  },
  [actions.loadEventsSuccess]: loadEventsSuccess,
  [actions.createEventSuccess]: createEventSuccess,
  [actions.editEventSuccess]: editEventSuccess,
  [actions.deleteEventSuccess]: deleteEventSuccess,
  [actions.setIsSendingRequest]: (state) => {
    state.errorRequest = "";
    state.isSendingRequest = true;
  },
  [actions.requestFailed]: (state, { errorCode = 500 }) => {
    state.errorRequest = `error_${errorCode}`;
    state.isSendingRequest = false;
  },
  [actions.clearRequestError]: (state) => {
    state.errorRequest = "";
    state.isSendingRequest = false;
  },
  [actions.loadConcurrentEventsSuccess]: checkConcurrentEventsSuccess,
  [actions.loadConcurrentBlockingEventsSuccess]:
    checkConcurrentBlockingEventsSuccess,
  [actions.setIsCheckingConcurrentEvents]: (state, { data }) => {
    state.isCheckingConcurrentEvents = data;
    state.concurrentEvents = [];
    state.concurrentBlockingEvents = [];
  },
  [actions.clearAll]: (state) => {
    const keys = Object.keys(state);
    keys.forEach((key) => {
      state[key] = initialState[key];
    });
  },
  [actions.loadEventCreatedSuccess]: loadEventCreatedSuccess,
  [actions.loadEventEditedSuccess]: loadEventEditedSuccess,
  [actions.eventDeleted]: eventDeleted,
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  isCreationPopupOpen: (state) => state.events.isCreationPopupOpen,
  editFocusedEventId: (state) => state.events.editFocusedEventId,
  eventsByDates: (state) => state.events.eventsByDates,
  eventByIds: (state) => state.events.eventByIds,
  isCheckingConcurrentEvents: (state) =>
    state.events.isCheckingConcurrentEvents,
  concurrentEvents: (state) => state.events.concurrentEvents,
  concurrentBlockingEvents: (state) => state.events.concurrentBlockingEvents,
  isSendingRequest: (state) => state.events.isSendingRequest,
  errorRequest: (state) => state.events.errorRequest,
};
