export const Live = {
  //chat
  MESSAGE_TO_ALL: "live:message_to_all",

  AUTHENTICATE_SUCCESS: "authenticate.success",
  //USER_JOIN_ROOM:'live:user_join_room', // TO DEPRECATE
  USER_LEFT_LIVE: "live:user_left_live",
  ROOM_STATUS: "room.status",
  USER_JOIN_LIVE: "live:user_join_live",
  TOTAL_UPDATE_LIVE: "live:total_update_live",

  //products
  FOCUS_PRODUCT_TO_ALL: "live:focus_product_to_all",

  //boradcast
  BROADCAST_CONSUMER_ASK_SELLER: "live:broadcast_consumer_ask_seller",
  BROADCAST_SELLER_ANSWER_CONSUMER: "live:broadcast_seller_answer_consumer",
  BROADCAST_SELLER_STOP_CONSUMER: "live:broadcast_seller_stop_consumer",
  BROADCAST_SELLER_INVITE_CONSUMER: "live:broadcast_invite_consumer",
  BROADCAST_CONSUMER_ANSWER_INVITATION_SELLER:
    "live:broadcast_consumer_answer_invitation_seller",

  //ban
  BAN_FOR_SESSION: "live:ban_for_session",
  BAN_FOR_LIFE: "live:ban_for_life",
  BAN_USER: "live:ban_user",

  //camera control
  SET_VIDEO_ROOM_STATUS: "live:set_video_room_status",
  MUTE_PUBLISHER_AUDIO: "publisher.mute.audio",
};

export const Event = {
  EVENT_CREATED: "event:event_created",
  EVENT_EDITED: "event:event_edited",
  EVENT_DELETED: "event:event_deleted",
  MUTE_SELF_AUDIO: "self.mute.audio",
};

export const Subscribe = {
  SUBSCRIBE_BOUTIQUE_ROOM: "subscribe:subscribe_boutique_room",
};
