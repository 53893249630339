import React from "react";
import "./Loader.scss";

const Loader = React.memo(() => {
  return <div className="loader" />;
});

Loader.displayName = "Loader";

Loader.propTypes = {};

export default Loader;
