import { useMemo } from "react";
import { useSelector } from "react-redux";

import { selectors as MeSelectors } from "./redux";

import { ROLES } from "../../utils/const";

const { ADMIN, HQ_GLOBAL, BA_MANAGER, BA_MOBILE } = ROLES;

export const useMeRole = () => {
  const me = useSelector(MeSelectors.me);

  const isAdmin = useMemo(() => me?.roles?.includes(ADMIN), [me]);
  const isBaManager = useMemo(() => me?.roles?.includes(BA_MANAGER), [me]);
  const isBAMobile = useMemo(() => me?.roles?.includes(BA_MOBILE), [me]);
  const isHqGlobal = useMemo(() => me?.roles?.includes(HQ_GLOBAL), [me]);

  return { isAdmin, isHqGlobal, isBaManager, isBAMobile };
};
