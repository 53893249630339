import { createAction, createReducer } from "redux-act";
import makeImmutable from "../makeImmutable";

const initialState = {
  boutiqueIds: [],
  boutiqueByIds: {},
  selectedBoutiqueId: {},
  error: "",
};

export const actions = {
  requestLoadBoutiques: createAction("request load boutiques"),
  loadBoutiquesSuccess: createAction("load boutiques success", (boutiques) => ({
    boutiques,
  })),
  setSelectedBoutiqueId: createAction(
    "set selected boutique id",
    (boutiqueId) => ({
      boutiqueId,
    })
  ),
  setError: createAction("set error", (error) => ({ error })),
};

const loadBoutiquesSuccess = (state, { boutiques }) => {
  state.boutiqueIds = [];
  boutiques.forEach((boutique) => {
    state.boutiqueByIds[boutique.id] = boutique;
    state.boutiqueIds.push(boutique.id);
  });
  state.selectedBoutiqueId = boutiques[0].id;
};

const setSelectedBoutiqueId = (state, { boutiqueId }) => {
  state.selectedBoutiqueId = boutiqueId;
};

const reducers = {
  [actions.loadBoutiquesSuccess]: loadBoutiquesSuccess,
  [actions.setSelectedBoutiqueId]: setSelectedBoutiqueId,
  [actions.setError]: (state, { error }) => {
    state.error = error;
  },
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  boutiqueByIds: (state) => state.boutiques.boutiqueByIds,
  boutiqueIds: (state) => state.boutiques.boutiqueIds,
  selectedBoutiqueId: (state) => state.boutiques.selectedBoutiqueId,
  error: (state) => state.boutiques.error,
};
