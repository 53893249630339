import { all, takeLeading, put, call } from "redux-saga/effects";
import { actions as BoutiquesActions } from "./redux";
import { actions as SocketActions } from "../socket/redux";
import ApolloService from "../../services/ApolloService";
import { LIST_BOUTIQUES } from "./graph";
import AppSagas from "../app/sagas";
import { Subscribe } from "../socket/messageTypes";

export default class BoutiquesSagas {
  static *loadBoutiques({ skip, limit }) {
    try {
      const result = yield ApolloService.query(LIST_BOUTIQUES, {
        pagination: {
          skip,
          limit,
          sort: { field: "creationDate", order: "ASC" },
        },
      });
      if (result.ok) return result.data.boutiques;
      return [];
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestLoadBoutiques() {
    try {
      const limit = 25;
      let skip = 0;
      let boutiques = [];
      let loadedBoutiques = [];
      loadedBoutiques = yield call(BoutiquesSagas.loadBoutiques, {
        skip: skip,
        limit: limit,
      });
      if (loadedBoutiques && loadedBoutiques.length > 0) {
        boutiques.push(...loadedBoutiques);
        skip += loadedBoutiques.length;
        while (loadedBoutiques.length === limit) {
          loadedBoutiques = yield call(BoutiquesSagas.loadBoutiques, {
            skip: skip,
            limit: limit,
          });
          if (loadedBoutiques) {
            boutiques.push(...loadedBoutiques);
            skip += loadedBoutiques.length;
          }
        }
        if (boutiques.length) {
          yield put(BoutiquesActions.loadBoutiquesSuccess(boutiques));
          yield put(
            SocketActions.requestSendMessage(
              Subscribe.SUBSCRIBE_BOUTIQUE_ROOM,
              {
                boutiqueId: boutiques[0].id,
              }
            )
          );
        }
      } else {
        yield put(BoutiquesActions.setError("errorBoutiqueNotFound"));
        throw new Error("boutique not found");
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *loop() {
    yield all([
      yield takeLeading(
        BoutiquesActions.requestLoadBoutiques.getType(),
        BoutiquesSagas.requestLoadBoutiques
      ),
    ]);
  }
}
