import { createAction, createReducer } from "redux-act";
import conf from "../../conf";
import makeImmutable from "../makeImmutable";

const initialState = {
  userIds: [],
  userByIds: {},
  videoGroupIds: [],
  videoGroupByIds: {},
  videoIds: [],
  videoByIds: {},
  isSendingRequest: false,
  errorRequest: "",
  errorMsg: null,
  boutiqueIds: [],
  boutiqueByIds: {},
  pageState: {
    isUserCreation: false,
    isUserEdition: false,
    isBoutiqueCreation: false,
    isBoutiqueEdition: false,
    isVideoGroupCreation: false,
    isVideoGroupEdition: false,
    isVideoCreation: false,
    isVideoEdition: false,
  },
  selectedUserId: null,
  selectedBoutiqueId: null,
  selectedVideoGroupId: null,
  selectedVideoId: null,
  selectedVideoEditionLang: conf.DEFAULT_LANG || 'en',
  selectedVideoGroupEditionLang: conf.DEFAULT_LANG || 'en',
};

export const actions = {
  setSelectedBoutiqueId: createAction(
    "set selected boutique id",
    (boutiqueId) => ({
      boutiqueId,
    })
  ),
  setSelectedUserId: createAction("set selected user id", (userId) => ({
    userId,
  })),
  setSelectedVideoGroupId: createAction("set selected video group id", (videoGroupId) => ({
    videoGroupId,
  })),
  setSelectedVideoId: createAction("set selected video id", (videoId) => ({
    videoId,
  })),
  setIsUserCreation: createAction("set is user creation"),
  setIsBoutiqueCreation: createAction("set is boutique creation"),
  setIsVideoGroupCreation: createAction("set is video group creation"),
  setIsVideoCreation: createAction("set is video creation"),
  requestCreateUser: createAction(
    "request create user",
    (email, password, user, baImage) => ({
      email,
      password,
      user,
      baImage
    })
  ),
  requestCreateUserSuccess: createAction(
    "request create user success",
    (user) => ({
      user,
    })
  ),
  requestLoadUsers: createAction("request load users"),
  requestLoadUsersSuccess: createAction(
    "request load users success",
    (users) => ({
      users,
    })
  ),
  clearAll: createAction("clear all"),
  setIsSendingRequest: createAction("set is sending request"),
  requestFailed: createAction("request failed", (errorCode, errorMsg) => ({
    errorCode,
    errorMsg,
  })),
  clearRequestError: createAction("clear request error"),
  requestUpdateUser: createAction("request update user", (userId, user, baImage) => ({
    userId,
    user,
    baImage
  })),
  requestUpdateUserSuccess: createAction(
    "request update user success",
    (user) => ({
      user,
    })
  ),
  requestCreateBoutique: createAction(
    "request create boutique",
    (boutique) => ({
      boutique,
    })
  ),
  requestCreateBoutiqueSuccess: createAction(
    "request create boutique success",
    (boutique) => ({
      boutique,
    })
  ),
  requestUpdateBoutique: createAction(
    "request update boutique",
    (boutiqueId, boutique) => ({
      boutiqueId,
      boutique,
    })
  ),
  requestUpdateBoutiqueSuccess: createAction(
    "request update boutique success",
    (boutique) => ({
      boutique,
    })
  ),
  requestLoadBoutiquesAsAdmin: createAction("request load boutiques as admin"),
  loadBoutiquesSuccess: createAction("load boutiques success", (boutiques) => ({
    boutiques,
  })),
  requestCreateVideoGroup: createAction(
    "request create video group",
    (lang, videoGroup) => ({
      lang, 
      videoGroup,
    })
  ),
  requestCreateVideo: createAction(
    "request create video",
    (lang, video, videoFile, coverFile) => ({
      lang,
      video,
      videoFile,
      coverFile
    })
  ),
  requestCreateVideoSuccess: createAction(
    "request video create success",
    (video) => ({
      video,
    })
  ),
  requestUpdateVideo: createAction(
    "request video create",
    (videoId, lang, video, videoFile, coverFile) => ({
      videoId,
      lang,
      video,
      videoFile,
      coverFile
    })
  ),
  requestUpdateVideoSuccess: createAction(
    "request video create success",
    (video) => ({
      video,
    })
  ),
  requestCreateVideoGroupSuccess: createAction(
    "request create video group success",
    (videoGroup) => ({
      videoGroup,
    })
  ),
  deleteVideoGroupSuccess: createAction(
    "request video group delete"
  ),
  requestDeleteVideoGroup: createAction(
    "video group delete",
    (videoGroupId) => ({
      videoGroupId,
    })
  ),
  requestDeleteVideo: createAction(
    "video delete",
    (videoId) => ({
      videoId,
    })
  ),
  deleteVideoSuccess: createAction(
    "request video delete"
  ),
  requestUpdateVideoGroup: createAction(
    "request video group boutique",
    (videoGroupId, lang, videoGroup) => ({
      videoGroupId,
      lang,
      videoGroup,
    })
  ),
  requestUpdateVideoGroupSuccess: createAction(
    "request update video group success",
    (videoGroup) => ({
      videoGroup,
    })
  ),
  requestLoadVideoGroups: createAction("request load video groups"),
  loadVideoGroupsSuccess: createAction("load video groups success", (videoGroups) => ({
    videoGroups,
  })),
  requestLoadVideos: createAction("request load videos"),
  loadVideosSuccess: createAction("load videos success", (videos) => ({
    videos,
  })),
  requestLoadVideoById: createAction("request load video by id", (id, lang) => ({
    id,
    lang,
  })),
  requestLoadVideoGroupById: createAction("request load video group by id", (id, lang) => ({
    id,
    lang,
  })),
  loadVideoByIdSuccess: createAction("load video by id success", (video) => ({
    video
  })),
  loadVideoGroupByIdSuccess: createAction("load video group by id success", (videoGroup) => ({
    videoGroup
  })),
  setVideoEditionLang: createAction("set video edition lang", (lang) => ({
    lang,
  })),
  setVideoGroupEditionLang: createAction("set video group edition lang", (lang) => ({
    lang,
  })),
};

const requestCreateUserSuccess = (state, { user }) => {
  state.userByIds[user.id] = user;
  state.userIds.push(user.id);
  state.errorRequest = "";
  state.isSendingRequest = false;
  state.selectedUserId = user.id;
  state.pageState = {
    isUserCreation: false,
    isUserEdition: true,
    isBoutiqueCreation: false,
    isBoutiqueEdition: false,
    isVideoGroupCreation: false,
    isVideoGroupEdition: false,
    isVideoCreation: false,
    isVideoEdition: false,
  };
};

const requestCreateBoutiqueSuccess = (state, { boutique }) => {
  state.boutiqueByIds[boutique.id] = boutique;
  state.boutiqueIds.push(boutique.id);
  state.errorRequest = "";
  state.errorMsg = null;
  state.isSendingRequest = false;
  state.selectedBoutiqueId = boutique.id;
  state.pageState = {
    isUserCreation: false,
    isUserEdition: false,
    isBoutiqueCreation: false,
    isBoutiqueEdition: true,
    isVideoGroupCreation: false,
    isVideoGroupEdition: false,
    isVideoCreation: false,
    isVideoEdition: false,
  };
};

const requestCreateVideoGroupSuccess = (state, { videoGroup }) => {
  state.videoGroupByIds[videoGroup.id] = videoGroup;
  state.videoGroupIds.push(videoGroup.id);
  state.errorRequest = "";
  state.errorMsg = null;
  state.isSendingRequest = false;
  state.selectedVideoGroupId = videoGroup.id;
  state.pageState = {
    isUserCreation: false,
    isUserEdition: false,
    isBoutiqueCreation: false,
    isBoutiqueEdition: false,
    isVideoGroupCreation: false,
    isVideoGroupEdition: true,
    isVideoCreation: false,
    isVideoEdition: false,
  };
};

const requestCreateVideoSuccess = (state, { video }) => {
  state.videoByIds[video.id] = video;
  state.videoIds.push(video.id);
  state.errorRequest = "";
  state.errorMsg = null;
  state.isSendingRequest = false;
  state.selectedVideoId = video.id;
  state.pageState = {
    isUserCreation: false,
    isUserEdition: false,
    isBoutiqueCreation: false,
    isBoutiqueEdition: false,
    isVideoGroupCreation: false,
    isVideoGroupEdition: false,
    isVideoCreation: false,
    isVideoEdition: true,
  };
};

const requestLoadUsersSuccess = (state, { users }) => {
  state.userIds = [];
  state.errorRequest = "";
  state.errorMsg = null;
  users.map((user) => {
    state.userByIds[user.id] = user;
    state.userIds.push(user.id);
  });
};

const requestUpdateUserSuccess = (state, { user }) => {
  state.userByIds[user.id] = user;
  if (state.selectedBoutiqueId !== null && user.boutique?.id)
    state.selectedBoutiqueId = user.boutique.id;
  state.isSendingRequest = false;
};

const requestUpdateVideoSuccess = (state, { video }) => {
  state.videoByIds[video.id] = video;
  if (state.selectedVideoGroupId !== null && video.videoGroup?.id)
    state.selectedVideoGroupId = video.videoGroup.id;
  state.isSendingRequest = false;
};

const requestUpdateBoutiqueSuccess = (state, { boutique }) => {
  state.boutiqueByIds[boutique.id] = boutique;
  state.isSendingRequest = false;
};

const requestUpdateVideoGroupSuccess = (state, { videoGroup }) => {
  state.videoGroupByIds[videoGroup.id] = videoGroup;
  state.isSendingRequest = false;
};

const deleteVideoGroupSuccess = (state) => {
  state.selectedVideoGroupId = null
  state.pageState = {
    isUserCreation: false,
    isUserEdition: false,
    isBoutiqueCreation: false,
    isBoutiqueEdition: false,
    isVideoGroupCreation: false,
    isVideoGroupEdition: false,
    isVideoCreation: false,
    isVideoEdition: false,
  };
  state.isSendingRequest = false;
}

const deleteVideoSuccess = (state) => {
  state.selectedVideoId = null
  state.pageState = {
    isUserCreation: false,
    isUserEdition: false,
    isBoutiqueCreation: false,
    isBoutiqueEdition: false,
    isVideoGroupCreation: false,
    isVideoGroupEdition: false,
    isVideoCreation: false,
    isVideoEdition: false,
  };
  state.isSendingRequest = false;
}


const loadBoutiquesSuccess = (state, { boutiques }) => {
  state.boutiqueIds = [];
  boutiques.map((boutique) => {
    state.boutiqueByIds[boutique.id] = boutique;
    state.boutiqueIds.push(boutique.id);
  });
};

const loadVideoGroupsSuccess = (state, { videoGroups }) => {
  state.videoGroupIds = [];
  videoGroups.map((videoGroup) => {
    state.videoGroupByIds[videoGroup.id] = videoGroup;
    state.videoGroupIds.push(videoGroup.id);
  });
};

const loadVideosSuccess = (state, { videos }) => {
  state.videoIds = [];
  videos.map((video) => {
    state.videoByIds[video.id] = video;
    state.videoIds.push(video.id);
  });
};

const loadVideoByIdSuccess = (state, { video }) => {
  state.videoByIds[video.id] = video;
};

const loadVideoGroupByIdSuccess = (state, { videoGroup }) => {
  state.videoGroupByIds[videoGroup.id] = videoGroup;
};

const reducers = {
  [actions.setSelectedBoutiqueId]: (state, { boutiqueId }) => {
    state.selectedBoutiqueId = boutiqueId;
    state.selectedUserId = null;
    state.selectedVideoGroupId = null
    state.selectedVideoId = null;
    if (boutiqueId) {
      state.pageState = {
        isUserCreation: false,
        isUserEdition: false,
        isBoutiqueCreation: false,
        isBoutiqueEdition: true,
        isVideoGroupCreation: false,
        isVideoGroupEdition: false,
        isVideoCreation: false,
        isVideoEdition: false,
      };
    } else {
      state.pageState = {
        isUserCreation: false,
        isUserEdition: false,
        isBoutiqueCreation: false,
        isBoutiqueEdition: false,
        isVideoGroupCreation: false,
        isVideoGroupEdition: false,
        isVideoCreation: false,
        isVideoEdition: false,
      };
    }
  },
  [actions.setSelectedUserId]: (state, { userId }) => {
    state.selectedUserId = userId;
    state.selectedVideoGroupId = null
    state.selectedVideoId = null;
    if (userId)
      state.pageState = {
        isUserCreation: false,
        isUserEdition: true,
        isBoutiqueCreation: false,
        isBoutiqueEdition: false,
        isVideoGroupCreation: false,
        isVideoGroupEdition: false,
        isVideoCreation: false,
        isVideoEdition: false,
      };
  },
  [actions.setSelectedVideoId]: (state, { videoId }) => {
    state.selectedVideoId = videoId;
    state.selectedUserId = null;
    state.selectedBoutiqueId = null;
    if (videoId)
      state.pageState = {
        isUserCreation: false,
        isUserEdition: false,
        isBoutiqueCreation: false,
        isBoutiqueEdition: false,
        isVideoGroupCreation: false,
        isVideoGroupEdition: false,
        isVideoCreation: false,
        isVideoEdition: true,
      };
  },
  [actions.setSelectedVideoGroupId]: (state, { videoGroupId }) => {
    state.selectedVideoGroupId = videoGroupId;
    state.selectedBoutiqueId = null;
    state.selectedUserId = null
    state.selectedVideoId = null;
    if (videoGroupId) {
      state.pageState = {
        isUserCreation: false,
        isUserEdition: false,
        isBoutiqueCreation: false,
        isBoutiqueEdition: false,
        isVideoGroupCreation: false,
        isVideoGroupEdition: true,
        isVideoCreation: false,
        isVideoEdition: false,
      };
    } else {
      state.pageState = {
        isUserCreation: false,
        isUserEdition: false,
        isBoutiqueCreation: false,
        isBoutiqueEdition: false,
        isVideoGroupCreation: false,
        isVideoGroupEdition: false,
        isVideoCreation: false,
        isVideoEdition: false,
      };
    }
  },
  [actions.setIsUserCreation]: (state) => {
    state.selectedUserId = null;
    state.selectedBoutiqueId = null;
    state.selectedVideoGroupId = null
    state.selectedVideoId = null;
    state.pageState = {
      isUserCreation: true,
      isUserEdition: false,
      isBoutiqueCreation: false,
      isBoutiqueEdition: false,
      isVideoGroupCreation: false,
      isVideoGroupEdition: false,
      isVideoCreation: false,
      isVideoEdition: false,
    };
  },
  [actions.setIsBoutiqueCreation]: (state) => {
    state.selectedUserId = null;
    state.selectedBoutiqueId = null;
    state.selectedVideoGroupId = null
    state.selectedVideoId = null;
    state.pageState = {
      isUserCreation: false,
      isUserEdition: false,
      isBoutiqueCreation: true,
      isBoutiqueEdition: false,
      isVideoGroupCreation: false,
      isVideoGroupEdition: false,
      isVideoCreation: false,
      isVideoEdition: false,
    };
  },
  [actions.setIsVideoGroupCreation]: (state) => {
    state.selectedVideoGroupId = null;
    state.selectedVideoId = null;
    state.selectedBoutiqueId = null;
    state.selectedUserId = null;
    state.pageState = {
      isUserCreation: false,
      isUserEdition: false,
      isBoutiqueCreation: false,
      isBoutiqueEdition: false,
      isVideoGroupCreation: true,
      isVideoGroupEdition: false,
      isVideoCreation: false,
      isVideoEdition: false,
    };
  },
  [actions.setIsVideoCreation]: (state) => {
    state.selectedVideoId = null;
    state.selectedBoutiqueId = null;
    state.selectedVideoGroupId = null
    state.selectedUserId = null;
    state.pageState = {
      isUserCreation: false,
      isUserEdition: false,
      isBoutiqueCreation: false,
      isBoutiqueEdition: false,
      isVideoGroupCreation: false,
      isVideoGroupEdition: false,
      isVideoCreation: true,
      isVideoEdition: false,
    };
  },
  [actions.requestCreateUserSuccess]: requestCreateUserSuccess,
  [actions.requestCreateBoutiqueSuccess]: requestCreateBoutiqueSuccess,
  [actions.requestCreateVideoGroupSuccess]: requestCreateVideoGroupSuccess,
  [actions.requestCreateVideoSuccess]: requestCreateVideoSuccess,
  [actions.requestLoadUsersSuccess]: requestLoadUsersSuccess,
  [actions.clearAll]: (state) => {
    state.userIds = [];
    state.userByIds = {};
  },
  [actions.setIsSendingRequest]: (state) => {
    state.errorRequest = "";
    state.errorMsg = null;
    state.isSendingRequest = true;
  },
  [actions.clearRequestError]: (state) => {
    state.errorRequest = "";
    state.errorMsg = null;
  },
  [actions.requestFailed]: (state, { errorCode, errorMsg }) => {
    state.errorRequest = `error_${errorCode}`;
    state.errorMsg = errorMsg;
    state.isSendingRequest = false;
  },
  [actions.requestUpdateUserSuccess]: requestUpdateUserSuccess,
  [actions.requestUpdateBoutiqueSuccess]: requestUpdateBoutiqueSuccess,
  [actions.loadBoutiquesSuccess]: loadBoutiquesSuccess,
  [actions.requestUpdateVideoGroupSuccess]: requestUpdateVideoGroupSuccess,
  [actions.loadVideoGroupsSuccess]: loadVideoGroupsSuccess,
  [actions.loadVideosSuccess]: loadVideosSuccess,
  [actions.loadVideoByIdSuccess]: loadVideoByIdSuccess,
  [actions.loadVideoGroupByIdSuccess]: loadVideoGroupByIdSuccess,
  [actions.requestUpdateVideoSuccess]: requestUpdateVideoSuccess,
  [actions.deleteVideoGroupSuccess]: deleteVideoGroupSuccess,
  [actions.deleteVideoSuccess]: deleteVideoSuccess,
  [actions.setVideoEditionLang]: (state, { lang }) => {
    state.selectedVideoEditionLang = lang;
  },
  [actions.setVideoGroupEditionLang]: (state, { lang }) => {
    state.selectedVideoGroupEditionLang = lang;
  },
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  userIds: (state) => state.admin.userIds,
  userByIds: (state) => state.admin.userByIds,
  isSendingRequest: (state) => state.admin.isSendingRequest,
  errorRequest: (state) => state.admin.errorRequest,
  errorMsg: (state) => state.admin.errorMsg,
  boutiqueByIds: (state) => state.admin.boutiqueByIds,
  boutiqueIds: (state) => state.admin.boutiqueIds,
  videoGroupByIds: (state) => state.admin.videoGroupByIds,
  videoGroupIds: (state) => state.admin.videoGroupIds,
  videoByIds: (state) => state.admin.videoByIds,
  videoIds: (state) => state.admin.videoIds,
  pageState: (state) => state.admin.pageState,
  selectedUserId: (state) => state.admin.selectedUserId,
  selectedBoutiqueId: (state) => state.admin.selectedBoutiqueId,
  selectedVideoGroupId: (state) => state.admin.selectedVideoGroupId,
  selectedVideoId: (state) => state.admin.selectedVideoId,
  selectedVideoEditionLang: (state) => state.admin.selectedVideoEditionLang,
  selectedVideoGroupEditionLang: (state) => state.admin.selectedVideoGroupEditionLang
};
