import { LocalPeer } from "janus-front-sdk";

export default function (socketRoom, peer) {
  const user = socketRoom.users.get(peer.publisher.socketId);
  console.log("user ", user);
  return {
    socketId: peer.publisher?.socketId,
    publisherId: peer.publisher?.publisherId,
    displayName: peer.publisher?.displayName,
    userName:
      user.userData?.private?.displayName ||
      user.userData?.private?.firstName +
        " " +
        user.userData?.private?.lastName,
    isTalking: false,
    isLocal: peer instanceof LocalPeer,
    useAudio: peer.publisher.useAudio,
    useVideo: peer.publisher.useVideo,
  };
}
