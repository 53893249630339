import gql from "graphql-tag";

export const boutiquesFragment = gql`
  fragment BoutiquesInfo on Boutique {
    name
    city
    country
    socket
    socketPath
    id
    isVisible
    slotDays {
      dayOfWeek
      slots {
        hours
        minutes
        duration
      }
    }
  }
`;

export const usersFragment = gql`
  fragment UsersInfo on User {
    id
    email
    firstName
    lastName
    roles
    boutique {
      id
    }
    imageUrl
  }
`;

export const videoGroupsFragment = gql`
  fragment VideoGroupsInfo on VideoGroup {
    name(lang: $lang)
    order
    isVisible
    id
  }
`;

export const videosFragment = gql`
  fragment VideosInfo on Video {
    name(lang: $lang)
    description(lang: $lang)
    order
    clientOrder
    videoUrl(lang: $lang)
    coverUrl
    duration
    isOnClientHome
    videoGroup {
      id
    }
    id
  }
`;

export const CREATE_VIDEO_GROUP = gql`
  mutation ($lang: String!, $videoGroup: VideoGroupInputCreate!) {
    createVideoGroup(lang: $lang, videoGroup: $videoGroup) {
      ...VideoGroupsInfo
    }
  }
  ${videoGroupsFragment}
`;

export const UPDATE_VIDEO_GROUP = gql`
  mutation ($lang: String!, $id: ID!, $videoGroup: VideoGroupInputUpdate!) {
    updateVideoGroup(lang: $lang, id: $id, videoGroup: $videoGroup) {
      ...VideoGroupsInfo
    }
  }
  ${videoGroupsFragment}
`;

export const LOAD_VIDEO_GROUP = gql`
  query ListVideoGroups($lang: String!, $id: ID!) {
    videoGroup(id: $id) {
      ...VideoGroupsInfo
    }
  }
  ${videoGroupsFragment}
`;

export const LIST_VIDEO_GROUPS = gql`
  query listVideoGroups($lang: String!, $pagination: Pagination) {
    videoGroups(pagination: $pagination) {
      ...VideoGroupsInfo
    }
  }
  ${videoGroupsFragment}
`;

export const DELETE_VIDEO_GROUPS = gql`
  mutation deleteVideoGroups($id: ID!) {
    deleteVideoGroup(id: $id)
  }
`;

export const LOAD_VIDEO = gql`
  query ListVideos($lang: String!, $id: ID!) {
    video(id: $id) {
      ...VideosInfo
    }
  }
  ${videosFragment}
`;

export const LIST_VIDEOS = gql`
  query ListVideos($lang: String!, $pagination: Pagination, $filters: VideoFilters!) {
    videos(pagination: $pagination, filters: $filters) {
      ...VideosInfo
    }
  }
  ${videosFragment}
`;

export const CREATE_VIDEO = gql`
  mutation ($lang: String!, $video: VideoInputCreate!) {
    createVideo(lang: $lang, video: $video) {
      ...VideosInfo
    }
  }
  ${videosFragment}
`;

export const UPDATE_VIDEO = gql`
  mutation ($lang: String!, $id: ID!, $video: VideoInputUpdate!) {
    updateVideo(lang: $lang, id: $id, video: $video) {
      ...VideosInfo
    }
  }
  ${videosFragment}
`;

export const DELETE_VIDEO = gql`
  mutation deleteVideo($id: ID!) {
    deleteVideo(id: $id)
  }
`;


export const CREATE_USER = gql`
  mutation ($email: String!, $password: String!, $user: UserInputUpdate!) {
    createUser(email: $email, password: $password, user: $user) {
      ...UsersInfo
    }
  }
  ${usersFragment}
`;

export const UPDATE_USER = gql`
  mutation ($id: ID!, $user: UserInputUpdate!) {
    updateUser(id: $id, user: $user) {
      ...UsersInfo
    }
  }
  ${usersFragment}
`;

export const LIST_USERS = gql`
  query ListUsers($pagination: Pagination, $filters: UserFilters!) {
    usersAsAdmin(pagination: $pagination, filters: $filters) {
      ...UsersInfo
    }
  }
  ${usersFragment}
`;

export const CREATE_BOUTIQUE = gql`
  mutation ($boutique: BoutiqueInput!) {
    createBoutique(boutique: $boutique) {
      ...BoutiquesInfo
    }
  }
  ${boutiquesFragment}
`;

export const UPDATE_BOUTIQUE = gql`
  mutation ($id: ID!, $boutique: BoutiqueInput!) {
    updateBoutique(id: $id, boutique: $boutique) {
      ...BoutiquesInfo
    }
  }
  ${boutiquesFragment}
`;

export const LIST_BOUTIQUES_AS_ADMIN = gql`
  query listBoutiquesAsAdmin($pagination: Pagination) {
    boutiquesAsAdmin(pagination: $pagination) {
      ...BoutiquesInfo
    }
  }
  ${boutiquesFragment}
`;
