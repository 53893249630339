import {
  all,
  put,
  select,
  takeLatest,
  delay,
  fork,
  take,
} from "redux-saga/effects";
import jwtDecode from "jwt-decode";

import env from "../../env";
import ApolloService from "../../services/ApolloService";
import { actions as AuthActions, selectors as AuthSelectors } from "./redux";
import { actions as SocketActions } from "../socket/redux";
import { actions as MeActions, selectors as MeSelectors } from "../me/redux";
import {
  actions as BoutiquesActions,
  selectors as BoutiquesSelectors,
} from "../boutiques/redux";

function getExpireAt(idToken) {
  const decode = jwtDecode(idToken);

  const tokenDuration = decode.exp - decode.iat;
  const expireAt = Number(new Date()) + tokenDuration * 1000;
  return expireAt;
}

function* refreshToken(refreshToken) {
  let result;
  try {
    result = yield fetch(`https://${env.AUTH0_DOMAIN}/oauth/token`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      body: JSON.stringify({
        client_id: env.AUTH0_CLIENT_ID,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      }),
      headers: {
        "content-type": "application/json",
        "Accept-Language": "fr",
      },
    });
  } catch (error) {
    console.log(error);
    throw new Error("Network Error");
  }
  const resultData = yield result.json();
  if (result.status === 200 && result.ok === true) {
    return resultData;
  } else {
    throw resultData;
  }
}

function* login(email, password) {
  const result = yield fetch(`https://${env.AUTH0_DOMAIN}/oauth/token`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    body: JSON.stringify({
      authParams: {
        ui_locales: "fr-FR",
      },
      username: email,
      password,
      client_id: env.AUTH0_CLIENT_ID,
      connection: env.AUTH0_REALM,
      grant_type: "http://auth0.com/oauth/grant-type/password-realm",
      realm: env.AUTH0_REALM,
      scope: "openid offline_access",
      device: "SOMEDEVICE",
    }),
    headers: {
      "content-type": "application/json",
      "Accept-Language": "fr",
    },
  });
  const resultData = yield result.json();

  if (result.status === 200 && result.ok === true) {
    return resultData;
  } else {
    throw resultData;
  }
}

function* forgetPassword(email) {
  const result = yield fetch(
    `https://${env.AUTH0_DOMAIN}/dbconnections/change_password`,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      body: JSON.stringify({
        authParams: {
          ui_locales: "fr-FR",
        },
        email,
        client_id: env.AUTH0_CLIENT_ID,
        connection: env.AUTH0_REALM,
      }),
      headers: {
        "content-type": "application/json",
        "Accept-Language": "fr",
      },
    }
  );
  const resultData = yield result.text();

  if (result.status === 200 && result.ok === true) {
    return resultData;
  } else {
    throw resultData;
  }
}

export default class AuthSagas {
  static *startSilentRefreshToken() {
    yield fork(AuthSagas.silentRefreshToken);
  }

  static *silentRefreshToken() {
    while (true) {
      const token = yield select(AuthSelectors.token);
      if (token.expireAt) {
        const now = Number(new Date());
        if (now > token.expireAt - 1000 * 10) {
          try {
            const refreshResult = yield refreshToken(token.refreshToken);
            const expireAt = getExpireAt(refreshResult.id_token);
            yield put(
              AuthActions.updateToken(
                refreshResult.access_token,
                refreshResult.refresh_token,
                refreshResult.id_token,
                refreshResult.token_type,
                expireAt
              )
            );

            ApolloService.setToken(
              refreshResult.token_type + " " + refreshResult.id_token
            );
            yield put(AuthActions.refreshTokenSuccess());
          } catch (error) {
            console.log("error", error);
            if (error.message === "Network Error") {
              yield put(AuthActions.noNetworkOnRefreshToken());
            } else {
              yield put(AuthActions.logout());
            }
          }
        }
      }
      yield delay(1000);
    }
  }

  static *requestLogin({ payload }) {
    const { email, password } = payload;
    yield put(AuthActions.clearError());
    yield put(AuthActions.setIsSendingRequest(true));

    try {
      const result = yield login(email, password);
      const expireAt = getExpireAt(result.id_token);
      yield put(
        AuthActions.updateToken(
          result.access_token,
          result.refresh_token,
          result.id_token,
          result.token_type,
          expireAt
        )
      );

      ApolloService.setToken(result.token_type + " " + result.id_token);

      yield put(MeActions.requestLoadMe());
      const me = yield select(MeSelectors.me);
      if (!me.id) yield take(MeActions.loadMeSuccess.getType());

      // We connect when the user is logged
      const socket = yield select(MeSelectors.socket);
      const socketPath = yield select(MeSelectors.socketPath);
      if (!socket) yield put(SocketActions.requestConnect(false, env.SOCKET, env.SOCKET_PATH));
      else yield put(SocketActions.requestConnect(false, socket, socketPath));

      yield put(AuthActions.authWillSuccess());
      yield take(SocketActions.authSuccess.getType());
      yield put(AuthActions.authSuccess());
    } catch (e) {
      console.log(e);
      if (e.error === "invalid_grant")
        yield put(AuthActions.authFail("loginInvalid"));
      else yield put(AuthActions.authFail(e.error_description));
      return;
    }
  }

  static *requestForgetPassword({ payload }) {
    try {
      const { email } = payload;
      yield put(AuthActions.clearError());

      yield forgetPassword(email);

      yield put(AuthActions.setForgetPasswordMessage("forgetPasswordSuccess"));
    } catch (e) {
      console.log(e);
      yield put(AuthActions.setForgetPasswordMessage("forgetPasswordFailed"));
      return;
    }
  }

  static *logout() {
    yield put(AuthActions.logoutSuccess());
    window.location.href = "/";
  }

  static *loop() {
    yield all([
      yield takeLatest(
        AuthActions.startSilentRefreshToken.getType(),
        AuthSagas.startSilentRefreshToken
      ),

      yield takeLatest(
        AuthActions.requestLogin.getType(),
        AuthSagas.requestLogin
      ),
      yield takeLatest(
        AuthActions.requestForgetPassword.getType(),
        AuthSagas.requestForgetPassword
      ),
      yield takeLatest(AuthActions.logout.getType(), AuthSagas.logout),
    ]);
  }
}
