import { createAction, createReducer } from "redux-act";
import makeImmutable from "../makeImmutable";

const initialState = {
  networkSpeedMbps: 0,
  isLoaded: false,
  isTestingNetwork: true,
};

export const actions = {
  setNetworkSpeedMbps: createAction(
    "set network speed mbps",
    (speed, isLoaded) => ({
      speed,
      isLoaded,
    })
  ),
  setIsTestingNetwork: createAction("set is testing network", (value) => ({
    value,
  })),
};

const reducers = {
  [actions.setNetworkSpeedMbps]: (state, { speed, isLoaded }) => {
    state.networkSpeedMbps = speed;
    state.isLoaded = isLoaded;
  },
  [actions.setIsTestingNetwork]: (state, { value }) => {
    state.isTestingNetwork = value;
  },
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  networkSpeedMbps: (state) => state.networkSpeed.networkSpeedMbps,
  isLoaded: (state) => state.networkSpeed.isLoaded,
  isTestingNetwork: (state) => state.networkSpeed.isTestingNetwork,
};
