import { createAction, createReducer } from "redux-act";
import makeImmutable from "../makeImmutable";

const initialState = {
  token: {
    accessToken: "",
    refreshToken: "",
    idToken: "",
    tokenType: "",
    expireAt: 0,
  },
  logged: false,
  errorMessage: null,
  forgetPasswordMessage: null,
  isSendingRequest: false,
};

export const actions = {
  startSilentRefreshToken: createAction("start silent refresh token"),
  refreshTokenSuccess: createAction("refresh token success"),
  cancelLogin: createAction("cancel login"),
  noNetworkOnRefreshToken: createAction("no network on refresh token"),

  authSuccess: createAction("auth success"),
  authWillSuccess: createAction("auth will success"),
  authFail: createAction("auth fail", (errorMessage) => ({ errorMessage })),

  requestLogin: createAction("request login", (email, password) => ({
    email,
    password,
  })),
  requestForgetPassword: createAction("request login", (email) => ({
    email,
  })),
  setForgetPasswordMessage: createAction(
    "set forget password message",
    (forgetPasswordMessage) => ({ forgetPasswordMessage })
  ),
  setIsSendingRequest: createAction(
    "set is sending request",
    (isSendingRequest) => ({
      isSendingRequest,
    })
  ),

  logout: createAction("logout"),
  logoutSuccess: createAction("logout sucess"),
  clearError: createAction("clear error"),
  updateToken: createAction(
    "update token",
    (accessToken, refreshToken, idToken, tokenType, expireAt) => ({
      accessToken,
      refreshToken,
      idToken,
      tokenType,
      expireAt,
    })
  ),

  requetRefreshToken: createAction("request refresh token"),
};

const reducers = {
  [actions.updateToken]: (state, token) => (state.token = token),
  [actions.authFail]: (state, { errorMessage }) => {
    state.errorMessage = errorMessage;
    state.isSendingRequest = false;
  },
  [actions.setForgetPasswordMessage]: (state, { forgetPasswordMessage }) =>
    (state.forgetPasswordMessage = forgetPasswordMessage),
  [actions.clearError]: (state) => {
    state.errorMessage = null;
    state.forgetPasswordMessage = null;
    state.isSendingRequest = false;
  },
  [actions.authSuccess]: (state) => {
    state.logged = true;
    state.isSendingRequest = false;
  },
  [actions.logoutSuccess]: (state) => {
    state.token = initialState.token;
    state.logged = initialState.logged;
    state.errorMessage = initialState.errorMessage;
    state.forgetPasswordMessage = initialState.forgetPasswordMessage;
    state.isSendingRequest = initialState.isSendingRequest;
  },
  [actions.setIsSendingRequest]: (state, { isSendingRequest }) => {
    state.isSendingRequest = isSendingRequest;
  },
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  isSendingRequest: (state) => state.auth.isSendingRequest,
  errorMessage: (state) => state.auth.errorMessage,
  forgetPasswordMessage: (state) => state.auth.forgetPasswordMessage,
  logged: (state) => state.auth.logged,
  token: (state) => state.auth.token,
  tokenIsLive: (state) => Number(new Date()) < state.auth.token.expireAt,
};
