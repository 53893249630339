import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "./ErrorScreen.scss";
import {
  actions as AuthActions,
  selectors as AuthSelectors,
} from "../../redux/auth/redux";
import { actions as BoutiquesActions } from "../../redux/boutiques/redux";
import WarningModal from "../../components/WarningModal/WarningModal";

const ErrorScreen = ({ error }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "routing" });
  const isLogged = useSelector(AuthSelectors.logged);

  const handleCloseError = useCallback(() => {
    dispatch(BoutiquesActions.setError(""));
    dispatch(AuthActions.logout());
    if (isLogged) window.location.reload(false);
  }, [dispatch, isLogged]);

  return (
    <WarningModal
      isModalOpen={true}
      msg={t(error)}
      confirmButtonText="Logout"
      setIsModalOpen={() => ({})}
      onClose={handleCloseError}
    />
  );
};

export default ErrorScreen;
