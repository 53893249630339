import React, { useEffect, useState } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import Transition from "./components/Transition/Transition";

import {
  isDesktop,
  isMobile,
  isMobileOnly,
  isTablet,
  isChrome,
  isFirefox,
  isSafari,
  isEdge,
} from "react-device-detect";

import { store, persistor } from "./redux";
import Routing from "./Routing";
import "./App.css";
import "./styles/fontello.scss";
import "./styles/config/box.scss";
import VideoPreloader from "./components/VideoPreloader/VideoPreloader";

function App() {
  const [isLandscape, setLandscape] = useState(
    window.innerWidth > window.innerHeight
  );
  useEffect(() => {
    const handleResize = () => {
      setLandscape(window.innerWidth > window.innerHeight);
    };
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const classes = [];
    if (isDesktop && !isMobile) classes.push("is-desktop"); //on check !isMobile (cause : bug sur ipad pro)
    if (isMobile) classes.push("is-mobile");
    if (isMobileOnly) classes.push("is-phone");
    if (isTablet) classes.push("is-tablet");
    if (isChrome) classes.push("is-chrome");
    if (isFirefox) classes.push("is-firefox");
    if (isSafari) classes.push("is-safari");
    if (isEdge) classes.push("is-edge");
    if (!isLandscape && isMobile) classes.push("is-mobile-portrait");
    if (isLandscape && isMobile) classes.push("is-mobile-landscape");
    document.querySelector("html").className = classes.join(" ");
  }, [isLandscape]);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <VideoPreloader />
        <Routing />
        <Transition />
      </PersistGate>
    </Provider>
  );
}

export default Sentry.withProfiler(App);
