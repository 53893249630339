import { createAction, createReducer } from "redux-act";
import makeImmutable from "../makeImmutable";

const initialState = {
  appStarted: false,
  lang: 'en',
};

export const actions = {
  startApp: createAction("startApp"),
  acceptCookies: createAction("acceptCookies"),
  declineCookies: createAction("declineCookies"),
  reportError: createAction("report error", (error) => ({ error })),
  reportEvent: createAction("report event", (category, action, value) => ({
    category,
    action,
    value,
  })),
  setLang: createAction("set lang", (lang) => ({ lang })),
};

const reducers = {
  [actions.startApp]: (state) => {
    state.appStarted = true;
  },
  [actions.setLang]: (state, { lang }) => {
    state.lang = lang;
  }
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  appStarted: (state) => state.app.appStarted,
  lang: (state) => state.app.lang,
};
