import { all, takeLeading, put, select, take } from "redux-saga/effects";
import { actions as MeActions } from "./redux";
import {
  actions as BoutiquesActions,
  selectors as BoutiquesSelectors,
} from "../boutiques/redux";
import ApolloService from "../../services/ApolloService";
import { GET_ME } from "./graph";
import * as Sentry from "@sentry/react";
import AppSagas from "../app/sagas";
import { checkIsGuest } from "../../Routing";
import { ROLES } from "../../utils/const";
export default class MeSagas {
  static *requestLoadMe() {
    try {
      const result = yield ApolloService.query(GET_ME);

      if (result.ok) {
        const me = result.data.me;
        const isGuest = checkIsGuest();
        if (!isGuest) {
          if (
            (me.roles.includes(ROLES.BA) ||
              me.roles.includes(ROLES.BA_MOBILE) ||
              me.roles.includes(ROLES.BA_MANAGER)) &&
            (!me.boutique || !me.boutique.id)
          )
            yield put(BoutiquesActions.setError("errorBoutiqueNotFound"));
          if (me.boutique) {
            yield put(BoutiquesActions.setSelectedBoutiqueId(me.boutique.id));
            yield put(
              MeActions.setSocketData(
                me.boutique.socket,
                me.boutique.socketPath
              )
            );
          }
          yield put(BoutiquesActions.requestLoadBoutiques());
          if (me.roles.includes(ROLES.HQ_GLOBAL)) {
            yield take(BoutiquesActions.loadBoutiquesSuccess.getType());
            const boutiqueIds = yield select(BoutiquesSelectors.boutiqueIds);
            if (!me.boutique)
              yield put(BoutiquesActions.setSelectedBoutiqueId(boutiqueIds[0]));
          }
        }
        yield put(MeActions.loadMeSuccess(me));
        Sentry.setUser(result.data.me);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *loop() {
    yield all([
      yield takeLeading(
        MeActions.requestLoadMe.getType(),
        MeSagas.requestLoadMe
      ),
    ]);
  }
}
