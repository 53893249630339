import env from "../env"

export async function uploadFile(selectedFile, token) {
  const formData = new FormData();
  let file = await fetch(selectedFile.imagePath).then((r) => r.blob());
  const nonAlphaNumericRegex = /[^a-zA-Z0-9_.]/g;
  const fileName = selectedFile.name.replace(nonAlphaNumericRegex, "_");
  formData.append("file", file, fileName);
  const bearer = `${token.tokenType} ${token.idToken}`;
  const response = await fetch(`${env.IMAGE_API}/image`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: bearer,
    },
  });
  const data = await response.json();
  return data;
}

export async function uploadVideo(selectedFile, token) {
  const formData = new FormData();
  let file = await fetch(selectedFile.videoPath).then((r) => r.blob());
  const nonAlphaNumericRegex = /[^a-zA-Z0-9_.]/g;
  const fileName = selectedFile.name.replace(nonAlphaNumericRegex, "_");
  formData.append("file", file, fileName);
  const bearer = `${token.tokenType} ${token.idToken}`;
  const response = await fetch(`${env.IMAGE_API}/video`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: bearer,
    },
  });
  const data = await response.json();
  return data;
}