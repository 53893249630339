const pEnv = window.env;

const env = {
  ENV: pEnv.REACT_APP_ENV || process.env.REACT_APP_ENV,
  GRAPH: pEnv.REACT_APP_GRAPH || process.env.REACT_APP_GRAPH,
  IMAGE_API: pEnv.REACT_APP_IMAGE_API || process.env.REACT_APP_IMAGE_API,
  SOCKET: pEnv.REACT_APP_SOCKET || process.env.REACT_APP_SOCKET,
  SOCKET_PATH: selectSocketPath(
    pEnv.REACT_APP_SOCKET_PATH || process.env.REACT_APP_SOCKET_PATH
  ),
  AUTH0_DOMAIN:
    pEnv.REACT_APP_AUTH0_DOMAIN || process.env.REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID:
    pEnv.REACT_APP_AUTH0_CLIENT_ID || process.env.REACT_APP_AUTH0_CLIENT_ID,
  AUTH0_REALM: pEnv.REACT_APP_AUTH0_REALM || process.env.REACT_APP_AUTH0_REALM,
  SENTRY_TRACE_RATE:
    pEnv.REACT_APP_SENTRY_TRACE_RATE || process.env.REACT_APP_SENTRY_TRACE_RATE,
  SENTRY_ENVIRONMENT:
    pEnv.REACT_APP_SENTRY_ENVIRONMENT ||
    process.env.REACT_APP_SENTRY_ENVIRONMENT,
  ALLOW_EVENT_OVERLAP: Boolean(
    pEnv.REACT_APP_ALLOW_EVENT_OVERLAP ||
      process.env.REACT_APP_ALLOW_EVENT_OVERLAP
  ),
  GA_TRACKING_ID:
    pEnv.REACT_APP_GA_TRACKING_ID || process.env.REACT_APP_GA_TRACKING_ID,
  MAX_PARTICIPANT_NUMBER: Number(
    pEnv.REACT_APP_MAX_PARTICIPANT_NUMBER ||
      process.env.REACT_APP_MAX_PARTICIPANT_NUMBER
  ),
  ROOM_TYPES: pEnv.REACT_APP_ROOM_TYPES || process.env.REACT_APP_ROOM_TYPES,
  REACT_APP_ONETRUST_ID:
    pEnv.REACT_APP_ONETRUST_ID || process.env.REACT_APP_ONETRUST_ID,
  REACT_APP_GA_ZONE: pEnv.REACT_APP_GA_ZONE || process.env.REACT_APP_GA_ZONE,
  REACT_APP_CDN: pEnv.REACT_APP_CDN || process.env.REACT_APP_CDN,
  REACT_APP_AZURE_STORAGE:
    pEnv.REACT_APP_AZURE_STORAGE || process.env.REACT_APP_AZURE_STORAGE,
  BOTSWANA_CAM_ORDER:
    pEnv.REACT_APP_BOTSWANA_CAM_ORDER ||
    process.env.REACT_APP_BOTSWANA_CAM_ORDER,
  BOTSWANA_CAM_ACTIVATED:
    (pEnv.REACT_APP_BOTSWANA_CAM_ACTIVATED ||
      process.env.REACT_APP_BOTSWANA_CAM_ACTIVATED) === "true",
};

function selectSocketPath(value) {
  if (value) {
    if (value === "NO_PATH") {
      console.log('REACT_APP_SOCKET_PATH === "NO_PATH" using empty string');
      return "";
    }
    return value;
  }
  console.info("REACT_APP_SOCKET_PATH not found, using default");
  return "/ws/socket.io";
}

export default env;
