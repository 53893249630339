import { createAction, createReducer } from "redux-act";
import makeImmutable from "../makeImmutable";
import { clientActions } from "./JanusWatcher";

/*
  liste des status : 
  - loading : on a pas encore Load l'event
  - countdown: on attend le début de l'event
  - prepare : event terminé, mais pas de device id
  - pre-waiting: waiting screen (room pas encore créé par le BA)
  - waiting : waiting screen activée par le BA
  - live: dans la visio
*/

const initialState = {
  status: "loading",
  userName: "",
  error: "",
  camOn: true,
  micOn: true,
  publisherId: null,
  waitingVideoIndex: null,
  firstVideoId: '',
  videoIds: [],
  videoByIds: [],
  serviceFailed: false,
  device: {
    videoDeviceId: null, //sur mobile utilisé en tant que facingMode: 'environment' ou 'user'
    audioDeviceId: null,
    useAudio: true,
    useVideo: true,
  },
  event: {
    id: null,
    scheduleDate: null,
    owner: {},
    isLoaded: false,
    client: {}
  },
  room: {
    roomId: null,
    janusUrl: "",
    videoRoomId: null,
    startDate: "",
    owner: {},
    isLoaded: false,
    ownerId: null,
  },
  publishers: {},
  clientPublisherIds: [],
  sellerFacePublisherId: null,
  sellerProductPublisherId: null,
  screenSharePublisherId: null,
  mePublisherId: null,
};

export const actions = {
  setStatus: createAction("set status", (status, waitingVideoIndex) => ({
    status,
    waitingVideoIndex,
  })),
  setPublisherId: createAction("set publisherId", (publisherId) => ({
    publisherId,
  })),
  requestGoToLive: createAction(
    "request go to live",
    (videoDeviceId, audioDeviceId, useAudio, useVideo, displayName) => ({
      videoDeviceId,
      audioDeviceId,
      useAudio,
      useVideo,
      displayName,
    })
  ),
  requestStartSession: createAction("request start session", (alias) => ({
    alias,
  })),
  requestLoadEvent: createAction("request load event", (alias) => ({
    alias,
  })),
  loadEventSuccess: createAction(
    "load event success",
    (eventId, scheduleDate, owner, alias, roomType, client) => ({
      eventId,
      scheduleDate,
      owner,
      alias,
      roomType,
      client
    })
  ),
  loadRoomSuccess: createAction(
    "load room success",
    (roomId, janusUrl, videoRoomId, startDate, owner, ownerId) => ({
      roomId,
      janusUrl,
      videoRoomId,
      startDate,
      owner,
      ownerId,
    })
  ),
  requestJoinRoom: createAction("request join room"),
  requestLeaveRoom: createAction("request leave room"),
  setError: createAction("set error", (error) => ({ error })),
  joinRoomSuccess: createAction(
    "join room success",
    (roomId, janusUrl, videoRoomId) => ({ roomId, janusUrl, videoRoomId })
  ),
  requestToggleCam: createAction("request toggle cam"),
  clear: createAction("clear"),
  setCamOn: createAction("setCamOn", (isOn) => ({ isOn })),
  requestToggleMic: createAction("request toggle mic"),
  setMicOn: createAction("set mic on", (isOn) => ({ isOn })),
  goLiveStatus: createAction("got live status", (status) => ({ status })),
  requestSwapMobileCamera: createAction("request swap mobile camera"),
  setMePublisherId: createAction("set me publisher Id", (publisherId) => ({
    publisherId,
  })),
  setServiceFailed: createAction("set service failed", (serviceFailed) => ({
    serviceFailed,
  })),
  requestReconnect: createAction("request reconnect"),
  requestStartVisio: createAction("request start visio"),
  clearStream: createAction("clear stream"),
  requestLoadVideos: createAction("request load videos"),
  loadVideosSuccess: createAction("load all videos success", (videos) => ({
    videos,
  })),
};

const addNewPublisher = (state, { publisher }) => {
  state.publishers[publisher.publisherId] = {
    socketId: publisher.socketId,
    publisherId: publisher.publisherId,
    displayName: publisher.displayName,
    userName: publisher.userName,
    isTalking: false,
    badNetwork: false,
    isLocal: publisher.isLocal,
    useAudio: publisher.useAudio,
    useVideo: publisher.useVideo,
  };

  if (publisher.displayName === "sellerFace") {
    state.sellerFacePublisherId = publisher.publisherId;
  } else if (publisher.displayName === "sellerProduct") {
    state.sellerProductPublisherId = publisher.publisherId;
  } else if (publisher.displayName == "screenshare") {
    state.screenSharePublisherId = publisher.publisherId;
  } else if (!state.clientPublisherIds.includes(publisher.publisherId)) {
    state.clientPublisherIds.push(publisher.publisherId);
  }
};

const updatePublisher = (state, { publisher }) => {
  state.publishers[publisher.publisherId] = {
    socketId: publisher.socketId,
    publisherId: publisher.publisherId,
    displayName: publisher.displayName,
    userName: publisher.userName,
    isTalking: publisher.isTalking,
    badNetwork: publisher.badNetwork,
    isLocal: publisher.isLocal,
    useAudio: publisher.useAudio,
    useVideo: publisher.useVideo,
  };
};

const setPublisherTalking = (state, { publisherId, isTalking }) => {
  state.publishers[publisherId].isTalking = isTalking;
};

const setPublisherBadNetwork = (state, { publisherId, badNetwork }) => {
  state.publishers[publisherId].badNetwork = badNetwork;
};

const removePublisher = (state, { publisherId }) => {
  delete state.publishers[publisherId];
  if (state.sellerFacePublisherId === publisherId)
    state.sellerFacePublisherId = null;
  if (state.sellerProductPublisherId === publisherId)
    state.sellerProductPublisherId = null;
  if (state.screenSharePublisherId === publisherId)
    state.screenSharePublisherId = null;

  state.clientPublisherIds = state.clientPublisherIds.filter(
    (id) => id !== publisherId
  );
};

const clearStream = (state) => {
  state.publisherId = "";
  state.publishers = {};
  state.clientPublisherIds = [];
  state.sellerProductPublisherId = null;
  state.sellerFacePublisherId = null;
  state.screenSharePublisherId = null;
  state.mePublisherId = null;
  state.error = "";
  state.joining = false;
};

const reducers = {
  [clientActions.newPublisher]: addNewPublisher,
  [clientActions.removePublisher]: removePublisher,
  [clientActions.updatePublisher]: updatePublisher,
  [clientActions.setPublisherTalking]: setPublisherTalking,
  [clientActions.setPublisherBadNetwork]: setPublisherBadNetwork,
  [actions.setStatus]: (state, { status, waitingVideoIndex }) => {
    state.status = status;
    if (waitingVideoIndex !== undefined && waitingVideoIndex !== null) {
      state.waitingVideoIndex = waitingVideoIndex;
    }
  },
  [actions.setError]: (state, { error }) => {
    state.status = "error";
    state.error = error;
  },
  [actions.setPublisherId]: (state, { publisherId }) => {
    state.publisherId = publisherId;
  },
  [actions.loadEventSuccess]: (
    state,
    { eventId, scheduleDate, owner, alias, roomType, client }
  ) => {
    state.event.id = eventId;
    state.event.scheduleDate = scheduleDate;
    state.event.owner = owner;
    state.event.alias = alias;
    state.event.isLoaded = true;
    state.event.roomType = roomType;
    state.event.client = client
  },
  [actions.loadRoomSuccess]: (
    state,
    { roomId, janusUrl, videoRoomId, startDate, owner, ownerId }
  ) => {
    state.room.roomId = roomId;
    state.room.janusUrl = janusUrl;
    state.room.videoRoomId = videoRoomId;
    state.room.startDate = startDate;
    state.room.owner = owner;
    state.room.ownerId = ownerId;
    state.room.isLoaded = true;
  },
  [actions.requestGoToLive]: (
    state,
    { videoDeviceId, audioDeviceId, useAudio, useVideo, displayName }
  ) => {
    state.device.videoDeviceId = videoDeviceId;
    state.device.audioDeviceId = audioDeviceId;
    state.device.useAudio = useAudio;
    state.device.useVideo = useVideo;
    state.displayName = displayName;
  },
  [actions.setCamOn]: (state, { isOn }) => {
    state.camOn = isOn;
  },

  [actions.setMicOn]: (state, { isOn }) => {
    state.micOn = isOn;
  },
  [actions.clear]: (state) => {
    const keys = Object.keys(state);
    keys.forEach((key) => {
      state[key] = initialState[key];
    });
  },
  [actions.requestSwapMobileCamera]: (state) => {
    state.device.videoDeviceId =
      state.device.videoDeviceId === "user" ? "environment" : "user";
  },
  [actions.setMePublisherId]: (state, { publisherId }) => {
    state.mePublisherId = publisherId;
  },
  [actions.setServiceFailed]: (state, { serviceFailed }) => {
    state.serviceFailed = serviceFailed;
  },
  [actions.loadVideosSuccess]: (state, { videos }) => {
    state.videoIds = [];
    videos.sort((a, b) => a.videoGroup.order - b.videoGroup.order).forEach((video) => {
      state.videoByIds[video.id] = video;
      state.videoIds.push(video.id);
    });
    const firstVideoId = videos.filter((video) => video.order === 1)[0]?.id
    if (state.waitingVideoIndex === null) state.waitingVideoIndex = firstVideoId
    state.firstVideoId = firstVideoId
  },
  [actions.clearStream]: clearStream,
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  status: (state) => state.liveClient.status,
  displayName: (state) => state.liveClient.displayName,
  room: (state) => state.liveClient.room,
  event: (state) => state.liveClient.event,
  device: (state) => state.liveClient.device,
  sellerFacePublisher: (state) =>
    state.liveClient.publishers?.[state.liveClient.sellerFacePublisherId] ||
    null,
  sellerProductPublisher: (state) =>
    state.liveClient.publishers?.[state.liveClient.sellerProductPublisherId] ||
    null,
  sellerFacePublisherId: (state) => state.liveClient.sellerFacePublisherId,
  sellerProductPublisherId: (state) =>
    state.liveClient.sellerProductPublisherId,
  publishers: (state) => state.liveClient.publishers,
  clientPublisherIds: (state) => state.liveClient.clientPublisherIds,
  error: (state) => state.liveClient.error,
  camOn: (state) => state.liveClient.camOn,
  micOn: (state) => state.liveClient.micOn,
  waitingVideoIndex: (state) => state.liveClient.waitingVideoIndex,
  firstVideoId: (state) => state.liveClient.firstVideoId,
  publisherId: (state) => state.liveClient.publisherId,
  mePublisherId: (state) => state.liveClient.mePublisherId,
  serviceFailed: (state) => state.liveClient.serviceFailed,
  screenSharePublisherId: (state) => state.liveClient.screenSharePublisherId,
  videoByIds: (state) => state.liveClient.videoByIds,
  videoIds: (state) => state.liveClient.videoIds,
};
