import gql from "graphql-tag";

export const LIST_BOUTIQUES = gql`
  query listBoutiques($pagination: Pagination) {
    boutiques(pagination: $pagination) {
      id
      name
      city
      country
      slotDays {
        dayOfWeek
        slots {
          hours
          minutes
          duration
        }
      }
    }
  }
`;
