import { createAction, createReducer } from "redux-act";
import { v4 as uuidv4 } from "uuid";

import makeImmutable from "../makeImmutable";

const initialState = {
  messageByIds: {
    // $id: {...message}
  },
  messageIds: [],
  readMessageIds: [],
  lastMessage: {},
  userByIds: {},
  userIds: [],
  usersInRoom: [],
  totalUsers: 0,
  isChatOpen: false,
  isEditingChat: false,
};

const addMessage = (state, { message }) => {
  const id = uuidv4();
  state.messageByIds[id] = message;
  state.messageIds.push(id);
  state.lastMessage = message;
};

const addUser = (state, { user }) => {
  if (!state.userIds.includes(user.id)) {
    state.userByIds[user.id] = user;
    state.userIds.push(user.id);
  }
};

const addUsers = (state, { users }) => {
  users.forEach((user) => {
    if (!state.userIds.includes(user.id)) {
      state.userByIds[user.id] = user;
      state.userIds.push(user.id);
    }
  });
};

export const actions = {
  addMessage: createAction("add message", (message) => ({ message })),
  setReadMessageIds: createAction("set read message ids", (readMessageIds) => ({
    readMessageIds,
  })),
  addUser: createAction("add user", (user) => ({ user })),
  addUsers: createAction("add user", (users) => ({ users })),
  setUsersInRoom: createAction("add user", (users) => ({ users })),
  requestSendChatMessage: createAction("send chat message", (msg, roomId) => ({
    msg,
    roomId,
  })),
  requestLoadUser: createAction("load user", (userId) => ({
    userId,
  })),
  requestLoadUsers: createAction("load users", (userIds) => ({
    userIds,
  })),
  setChatOpen: createAction("set chat open", (isChatOpen) => ({ isChatOpen })),
  setEditingChat: createAction("set editing chat", (isEditingChat) => ({
    isEditingChat,
  })),
  clearLastMessage: createAction("clear last message"),
};

const reducers = {
  [actions.addMessage]: addMessage,
  [actions.addUser]: addUser,
  [actions.addUsers]: addUsers,
  [actions.setUsersInRoom]: (state, { users }) => (state.usersInRoom = users),
  [actions.setChatOpen]: (state, { isChatOpen }) =>
    (state.isChatOpen = isChatOpen),
  [actions.setEditingChat]: (state, { isEditingChat }) =>
    (state.isEditingChat = isEditingChat),
  [actions.setReadMessageIds]: (state, { readMessageIds }) =>
    (state.readMessageIds = readMessageIds),
  [actions.clearLastMessage]: (state) =>
    (state.lastMessage = {}),
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  allMessageByIds: (state) => state.chat.messageByIds,
  messageByIds: (state, id) => state.chat.messageByIds[id],
  messageIds: (state) => state.chat.messageIds,
  lastMessage: (state) => state.chat.lastMessage,
  readMessageIds: (state) => state.chat.readMessageIds,
  userIds: (state) => state.chat.userIds,
  userByIds: (state, id) => state.chat.userByIds[id],
  usersInRoom: (state) => state.chat.usersInRoom,
  isChatOpen: (state) => state.chat.isChatOpen,
  isEditingChat: (state) => state.chat.isEditingChat,
};
