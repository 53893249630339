import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { actions as AppActions } from "../redux/app/redux";

const useAppCookies = () => {
  const dispatch = useDispatch();
  const onAccept = useCallback(() => {
    dispatch(AppActions.acceptCookies());
  }, [dispatch]);

  const onDecline = useCallback(() => {
    dispatch(AppActions.declineCookies());
  }, [dispatch]);

  return { onAccept, onDecline };
};

export default useAppCookies;
