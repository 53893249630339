import { put, take } from "redux-saga/effects";

import { eventChannel } from "redux-saga";
import { actions as ChatActions } from "./redux";

export default class ChatWatcher {
  static *listenChat(socketRoom, chatRoom) {
    const channel = eventChannel((emmiter) => {
      chatRoom.on("newChater", (chater) => {
        console.log("new chater", chater);
        const userData = chatRoom.getUserData(chater.socketId);
        const now = new Date();
        const messageTime = now.getHours() + ":" + now.getMinutes();
        emmiter(
          ChatActions.addMessage({
            msg: "clientEnterRoom",
            userName:
              userData.private.displayName ||
              userData.private.firstName + " " + userData.private.lastName,
            type: "INFO",
            socketId: chater.socketId,
            date: messageTime,
          })
        );
      });

      chatRoom.on("newMessage", ({ payload, chaterId }) => {
        const chater = chatRoom.chaters.get(chaterId);

        const userData = chatRoom.getUserData(chater.socketId);
        const now = new Date();
        const messageTime =
          now.getHours() +
          ":" +
          ((now.getMinutes() < 10 ? "0" : "") + now.getMinutes());

        const messageData = {
          msg: payload,
          userName:
            userData.private.displayName ||
            userData.private.firstName + " " + userData.private.lastName,
          socketId: chater.socketId,
        };
        emmiter(
          ChatActions.addMessage({
            ...messageData,
            type: "CHAT",
            date: messageTime,
          })
        );
      });

      return () => {
        console.log("unsubscribe here");
      };
    });

    try {
      while (true) {
        let action = yield take(channel);
        yield put(action);
      }
    } catch (e) {
      console.log(e);
    }
  }
}
