import gql from "graphql-tag";

export const LOAD_EVENT_BY_ALIAS = gql`
  query LoadEventByAlias($alias: String!) {
    eventByAlias(alias: $alias) {
      id
      alias
      scheduleDate
      roomType
      client {
        fullName
      }
      owner {
        userName
        firstName
        lastName
        imageUrl
      }
    }
  }
`;

export const LOAD_EVENT_ROOM = gql`
  query LoadEventRoom($eventId: ID!) {
    event(id: $eventId) {
      room {
        id
        videoRoomId
        socketUrl
        socketPath
        janusUrl
        startDate
        endDate
        participantsLiveNumber
        ownerId
        owner {
          firstName
          lastName
        }
      }
    }
  }
`;

export const LIST_VIDEOS = gql`
  query ListVideos($lang: String!) {
    videos {
      name(lang: $lang)
      description(lang: $lang)
      order
      clientOrder
      videoUrl(lang: $lang)
      coverUrl
      duration
      isOnClientHome
      videoGroup{
        order
      }
      id
    }
  }
`;

export const REGISTER_AS_GUEST = gql`
  mutation RegisterAsGuest($eventId: ID!, $displayName: String!) {
    registerAsGuest(eventId: $eventId, displayName: $displayName) {
      jwt
    }
  }
`;
