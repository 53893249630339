import { createAction, createReducer } from "redux-act";
import makeImmutable from "../makeImmutable";

const initialState = {
  userIds: [],
  userByIds: {},
};

export const actions = {
  requestLoadUsers: createAction("request load users"),
  requestLoadUsersSuccess: createAction(
    "load next events success",
    (users) => ({
      users,
    })
  ),
  clearAll: createAction("clear all"),
};

const requestLoadUsersSuccess = (state, { users }) => {
  state.userIds = [];
  users.map((user) => {
    state.userByIds[user.id] = user;
    state.userIds.push(user.id);
  });
};

const reducers = {
  [actions.requestLoadUsersSuccess]: requestLoadUsersSuccess,
  [actions.clearAll]: (state) => {
    state.userIds = [];
    state.userByIds = {};
  },
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  userIds: (state) => state.users.userIds,
  userByIds: (state) => state.users.userByIds,
};
