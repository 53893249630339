import gql from "graphql-tag";

export const GET_ME = gql`
  query getMe {
    me {
      id
      firstName
      lastName
      roles
      boutique {
        id
        socket
        socketPath
      }
    }
  }
`;
