const zhHKSpecific = {
  translation: {
    common: {
      ok: "確定",
      yes: "是",
      no: "否",
      confirmMsg: "你確定嗎？",
      FACE: "面容",
      WIDE: "廣角",
      MACRO: "微距",
      copied: "複製了",
    },
    appointmentCreation: {
      baSelection: "品牌大使",
      clientEmailField: "電郵地址",
      clientFullNameField: "姓名",
      dateSelection: "選擇日期",
      largeDurationHoursAndMinutes:
        "請注意，此活動的持續時間為{{hours}}{{hour_word}}{{minutes}}{{minute_word}}。",
      largeDurationHours: "請注意，此活動的持續時間為{{hours}}{{hour_word}}。",
      eventCloseWarning:
        "請注意，此活動與{{clientName}}安排在同一日{{startTime}}的預約時段接近。",
      eventCreationOverlaps:
        "請注意，此新活動與{{clientName}}安排在同一日{{startTime}}的預約時段重疊。",
      hourEndSelection: "結束",
      hourStartSelection: "開始",
      roomTypeSelection: "房間類型",
      saveButton: "儲存",
      error_500: "出了些問題",
      hour_one: "小時",
      hour_other: "小時",
      minute_one: "分鐘",
      minute_other: "分鐘",
    },
    appointmentEdit: {
      baAccessButton: "進入房間",
      baSelection: "品牌大使",
      clientEmailField: "電郵地址",
      clientFullNameField: "姓名",
      clientURL: "給客戶的網址",
      dateSelection: "日期",
      deleteButton: "刪除",
      editButton: "儲存",
      largeDurationHoursAndMinutes:
        "請注意，此活動的持續時間為{{hours}}{{hour_word}}{{minutes}}{{minute_word}}。",
      largeDurationHours: "請注意，此活動的持續時間為{{hours}}{{hour_word}}。",
      eventCloseWarning:
        "請注意，此活動與{{clientName}}安排在同一日{{startTime}}的預約時段接近。",
      eventEditOverlaps:
        "請注意，此活動與{{clientName}}安排在同一日{{startTime}}的預約時段重疊。",
      hourEndSelection: "結束",
      hourStartSelection: "開始",
      roomTypeSelection: "房間類型",
      error_500: "出了些問題",
      hour_one: "小時",
      hour_other: "小時",
      minute_one: "分鐘",
      minute_other: "分鐘",
    },
    datePicker: {
      dateFormat: "dd/MM/yyyy",
    },
    selectCustom: {
      CONFIDENTIAL_ROOM: "虛擬鑽石專家",
      CONFERENCE_ROOM: "實況活動",
    },
    baEnd: {
      baRoomEndMsg: "感謝分享你的鑽石專業知識！",
      baRoomEndTitle: "虛擬鑽石專家",
      backToCalendarButton: "返回日曆",
    },
    baPrepare: {
      appointmentCountDown: "HH:mm:ss",
      audioInput: "音訊輸入",
      baAccessRoomButton: "正式開始",
      baAppointmentTitle: "您的預約",
      baPrepareChecklist: [
        "1.您的鏡頭已開啟",
        "2.您的麥克風已開啟",
        "3.已開燈",
        "4.精選鑽飾和道具已準備好",
        "5.已打開Debeers.com",
        "6.熟悉等候畫面的De Beers影片",
      ],
      baPrepareChecklistTitle: "檢查清單",
      baPrepareTitle: "虛擬鑽石專家",
      clientName: "{{clientName}}",
      errorAuthorization:
        "請確保您的鏡頭和麥克風已打開，然後重新整理頁面以進入房間。",
      errorListDevice: "請確保您的相機並無被其他網頁或應用程序使用中。",
      errorNotFound: "找不到預約事件",
      errorOwner: "您沒有開始這預約的權限",
      errorRoomClosed: "此房間已關閉",
      liveIn: "直播倒數",
      liveSince: "實況開始時間",
      meetingDate: "{{date}} 的 {{startTime}}-{{endTime}}",
      error_500: "出了些問題",
    },
    baRoom: {
      baNextAppointmentTitle: "下一個預約",
      backToLiveButton: "返回直播",
      clientName: "{{clientName}}",
      confirmCloseRoom:
        "請注意：確定要關閉此房間嗎？關閉後此房間將無法再次打開。",
      meetingDate: "{{date}} 的 {{startTime}}-{{endTime}}",
      waitingScreenActiveMsgPart1: "客戶正在等候室觀看以下所選的品牌影片。",
      waitingScreenActiveMsgPart2: "你的客戶無法看到或聽到你。",
      waitingScreenActiveTitle: "等候畫面已啟用",
      waitingScreenVideoChoiceMsg: "你的客戶會在等候室觀看所選的品牌影片。",
      waitingScreenVideoChoicePlayButton: "播放影片",
      waitingScreenVideoChoiceTitle: "選擇宣傳影片",
      errorOwnerInside: "你已經在這個房間裡連接了",
      error_500: "出了些問題",
      errorRecreateRoom: "对不起，出了些問題\n為了繼續進行請您創建一個新房間",
    },
    calendar: {
      baAccessButton: "進入房間",
      calendarWeekDays: ["M", "T", "W", "T", "F", "S", "S"],
      meetingTime: "{{startTime}} - {{endTime}}",
      monthTitle: "MMMM YYYY",
      selectedDaysMeetingTitle: "Do MMMM YYYY",
      todaysMeetingsTitle: "今日",
    },
    cameraControl: {
      cameraDisplayOffButton: "PREVIEW",
      cameraDisplayOnButton: "LIVE",
      faceCameraName: "Camera",
      productCameraName: "Product",
    },
    chat: {
      inputPlaceholder: "發送訊息",
      clientEnterRoom: "{{displayName}} 已進入房間",
    },
    clientEnd: {
      clientRoomEndMsg: "謝謝您的寶貴時間，希望您喜歡我們的專屬線上鑒賞體驗。",
      clientRoomEndTitle: "虛擬鑽石專家",
      discoverButton: "探索",
      discoverButtonLink: "https://www.debeers.hk/zh-hk/home",
      discoverMsg: "前往網站探索更多",
    },
    clientPrepare: {
      appointmentDate: "dddd Do MMMM YYYY [at] h:mm A",
      appointmentCountDown: "HH:mm:ss",
      daysTimeDiff: "{{days_number}} {{days_unit}}",
      day_one: "日",
      day_other: "日",
      cameraSelection: "選擇鏡頭",
      audioSelection: "選擇麥克風",
      clientAccessRoomButton: "正式開始",
      clientAppointmentMsg: "為您服務的品牌大使",
      clientAppointmentTitle: "您的預約",
      clientPrepareTitle: "虛擬鑽石專家",
      clientViewerNameField: "請輸入你的名字",
      meetingStarting: "你的預約會面即將開始",
      errorMissingFields: "未填妥所有欄位",
      errorAuthorization:
        "請確保您的鏡頭和麥克風已打開，然後重新整理頁面以進入房間。",
      errorListDevice:
        "請確保您的相機並無被其他網頁或應用程序使用中。請重新加載頁面以進入房間。",
      errorNotFound: "找不到預約事件",
      errorFullRoom:
        "房間已滿，已有 {{numberOfClientsLimit}} 人在參與， 請聯繫您的品牌大使尋求協助。",
      error_500: "出了些問題， 請聯繫您的品牌大使尋求協助。",
      liveIn: "直播倒數：{{timer}}",
      liveSince: "實況開始時間",
      warningModalButtonText: "重新加載",
      noCameraFound: "找不到相機",
      noMicrophoneFound: "找不到麥克風",
      liveCamTitle: "De Beers鑽石之路直播",
    },
    clientRoom: {
      baCameraWithName: "{{baFirstName}} {{baLastName}}",
      lowBandWidthMsg: "連接訊號不良",
      confirmLeaveRoom: "你確定要離開房間嗎？",
    },
    clientWaiting: {
      clientWaitingMsg: "請稍候，我們的品牌大使即將回來繼續與你會面",
      clientWaitingMsgTitle: "你的預約已暫停",
      confirmLeaveRoom: "你確定要離開房間嗎？",
    },
    clientNotSupported: {
      browserErrorTitle: "出了些問題",
      browserError:
        "您的瀏覽器不支援此程序。請確保使用中的瀏覽器為最新版本或切換到其他瀏覽器。",
      discoverButton: "探索",
      discoverButtonLink: "https://www.debeers.hk/zh-hk/home",
      discoverMsg: "前往網站探索更多",
      downloadListedBrowsersMsg:
        "您可透過以下鏈接以下載其中一個最新版本的推薦瀏覽器",
      updateCurrentBrowser: "請更新您的瀏覽器",
      IOS_CHROME: "https://apps.apple.com/app/google-chrome/id535886823?l=en",
      IOS_FIREFOX:
        "https://apps.apple.com/app/firefox-private-safe-browser/id989804926?l=en",
      ANDROID_CHROME:
        "https://play.google.com/store/apps/details?id=com.android.chrome&hl=en&gl=US",
      ANDROID_FIREFOX:
        "https://play.google.com/store/apps/details?id=org.mozilla.firefox&hl=en&gl=US",
      DESKTOP_CHROME: "https://www.google.com/chrome/",
      DESKTOP_FIREFOX: "https://www.mozilla.org/firefox/new/",
      DESKTOP_EDGE: "https://www.microsoft.com/edge",
    },
    errorEditCreateEvent: {
      errorCreateStartBeforeNow: "開始時間無效",
      errorInvalidEmail: "電郵無效",
      errorMissingFields: "未填妥所有欄位",
    },
    header: {
      adminButton: "管理員",
      baName: "{{baFirstName}} {{baLastName}}",
    },
    login: {
      emailField: "電郵",
      loginButton: "登入",
      loginInvalid: "電郵或密碼不正確。",
      loginTitle: "虛擬鑽石專家",
      pageTitle: "登入",
      passwordField: "密碼",
      forgottenPasswordButton: "忘記密碼？",
    },
    forgetPassword: {
      emailField: "電郵",
      forgetPasswordButton: "發送",
      forgetPasswordTitle: "虛擬鑽石專家",
      pageTitle: "忘記密碼",
      forgetPasswordSuccess: "我們剛剛向您發送了一封電子郵件以重置您的密碼",
      forgetPasswordFailed: "發送重置密碼電子郵件時，出了些問題",
      forgetPasswordDescription:
        "請在下面輸入您的電子郵件地址，我們會立即向您發送一封電子郵件以重置您的密碼",
      returnLoginButton: "返回登錄",
    },
    videoCallBar: {
      durationTime: "HH:mm:ss",
      waitingScreenButton: "等候畫面",
      chatButtonTitle: "聊天",
    },
    cookieConsentComponent: {
      cookieConsentMsg:
        "我們使用 cookies 來確保您能享受最佳的網站體驗。如果您「接受」，我們將假定您樂於從本網站接收 cookie。否則，如果您不想保存 cookie，您可以單擊「拒絕」。欲了解更多信息，請參閱我們的",
      cookiePolicyMsg: "Cookie政策",
      cookiePolicyLink: "https://www.debeers.hk/zh-hk/privacy-policy.html",
      acceptButton: "接受",
      declineButton: "拒絕",
    },
    footerClient: {
      privacyCookie: "私隱和Cookie政策",
      privacyCookieLink: "https://www.debeers.hk/zh-hk/privacy-policy.html",
      termsConditions: "條款與細則",
      termsConditionsLink:
        "https://www.debeers.hk/zh-hk/terms-and-conditions.html",
    },
    sharing: {
      title: "您在共享您的屏幕",
      description: "您的屏幕目前對房間裡的所有用戶都是可見",
      stop: "停止屏幕共享",
    },
    routing: {
      errorBoutiqueNotFound: "您的賬號不屬於任何精品店, 請聯繫您的管理員",
      pageNotAvailable: "此頁面無法被使用",
      pageNotAvailableForMobile: "此頁面無法在移動設備上被使用",
    },
    admin: {
      boutiqueUser: "Boutique/User",
      category: "Category",
      videoGroupVideo: "Video Group/Video",
      videoGroupCreationTitle: "Video Group Creation",
      videoGroupName: "Video Group Name",
      videoGroupOrder: "Video Group Order",
      videoGroupPanelTitle: "Video Groups",
      videoPanelTitle: "Videos",
      videoCreationTitle: "Video Creation",
      videoName: "Video Name",
      videoDescription: "Video Description",
      videoOrder: "Video Order",
      videoClientOrder: "Video Client Order",
      videoDuration: "Video Duration",
      video: "Video File",
      videoCover: "Video Cover",
      videoGroup: "Video Group",
      videoGroupSelect: "Select Video Group",
      noVideoGroupOption: "None",
      isOnClientHomeLabel: "Is On Client Page",
      deleteButton: "刪除",
      videoEditTitle: "Edit Video",
      videoGroupEditTitle: "Edit Video Group",
      boutiquesPanelTitle: "Boutiques",
      userCreationTitle: "User Creation",
      userEditTitle: "User Edition",
      boutiqueCreationTitle: "Boutique Creation",
      boutiqueEditTitle: "Boutique Edition",
      boutiqueName: "Name",
      boutiqueCity: "City",
      boutiqueCountry: "Country",
      boutiqueSocket: "Socket",
      boutiqueSocketPath: "Socket Path",
      isVisibleLabel: "Is visible",
      usersPanelTitle: "Users",
      userEmail: "電郵",
      coverImage: "Cover Image",
      userPassword: "密碼",
      userFirstName: "First name",
      userLastName: "Last name",
      boutiqueSelect: "Select Boutique",
      boutique: "Boutique",
      rolesSelect: "Select roles",
      roles: "Roles",
      saveButton: "儲存",
      editButton: "儲存",
      error_500: "出了些問題",
      error_missing_field: "未填妥所有欄位",
      rolesInfo: [
        "BA: can create/edit/delete events for himself only in his boutique",
        "BA_MANAGER: can create/edit/delete events for BA in his boutique (himself included)",
        "BA_MOBILE: give the right to start rooms from a mobile device",
        "HQ_GLOBAL: can switch between boutiques and can create/edit/delete events of BA of each boutiques but not himself",
        "ADMIN: can access the admin page",
        "TEST: GA not activated on the user and the user can see test boutiques",
      ],
      boutiqueInfo: ["An user that doesn't have a boutique cannot log-in"],
      filtersButton: "Filters",
      searchFilterTitle: "Search (first name, last name, email)",
      noBoutiqueOption: "None",
    },
    errors: {
      networkLost:
        "你的互聯網連線似乎不穩定。當有網絡連線，你將會自動重新連接。",
    },
    languageSelector: {
      english: "English",
      chineseHK: "繁體中文（香港）",
    },
  },
};

export default zhHKSpecific;
