import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";

import { actions as AuthActions } from "./../../redux/auth/redux";
import { selectors as MeSelectors } from "../../redux/me/redux";
import { useMeRole } from "../../redux/me/hooks";

import "./BAContainer.scss";
import Bloc from "../../atoms/Bloc/Bloc";
import useShortcutMultipleKeys from "../../hooks/useShortcutMultipleKeys";
import Icon from "../../atoms/Icon/Icon";
import IconButton from "../../atoms/IconButton/IconButton";
import BoutiqueSelect from "../../components/BoutiqueSelect/BoutiqueSelect";
import ClientLogo from "../../atoms/ClientLogo/ClientLogo";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";
import {
  ReportButtonWeekly,
  ReportButtonGlobal,
} from "../../components/ReportButton";

const BAContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const me = useSelector(MeSelectors.me);
  const { isHqGlobal, isAdmin } = useMeRole();

  const handleLogout = useCallback(() => {
    dispatch(AuthActions.logout());
  }, [dispatch]);

  useShortcutMultipleKeys(["ctrl", "shift", "Q"], handleLogout);

  useEffect(() => {
    const handlePersistAuthLoggout = ({ key, newValue }) => {
      if (key === "persist:root") {
        const auth = JSON.parse(newValue)?.auth;
        if (auth && JSON.parse(auth)?.logged === false) {
          handleLogout();
        }
      }
    };
    window.addEventListener("storage", handlePersistAuthLoggout);
    return () => {
      window.removeEventListener("storage", handlePersistAuthLoggout);
    };
  }, [handleLogout]);

  return (
    <div className="ba-container">
      <Bloc className="header" bg-1 pl-m pr-m>
        <Bloc flex1>
          <LanguageSelector />
          {isAdmin && (
            <Link to="/admin">
              <Bloc text-title3 ti>
                {t("header.adminButton")}
              </Bloc>
            </Link>
          )}
        </Bloc>
        <Bloc className="logo-container" flex1>
          <Link to={"/"} className="logo-link">
            <ClientLogo className="logo" />
          </Link>
        </Bloc>
        <Bloc className="end" flex1>
          {isHqGlobal && <ReportButtonWeekly />}
          {isHqGlobal && <ReportButtonGlobal />}
          {isHqGlobal && <BoutiqueSelect />}
          <Bloc className="header-name-container" mr-s ti text1>
            {me && me.firstName && me.lastName && (
              <p>
                {t("header.baName", {
                  baFirstName: me.firstName,
                  baLastName: me.lastName,
                })}
              </p>
            )}
          </Bloc>

          <IconButton
            className="log-out-icon"
            color="invert"
            size="large"
            icon={Icon.types.LOG_OUT}
            onClick={handleLogout}
          />
        </Bloc>
      </Bloc>
      <div className="content-container">
        <Sentry.ErrorBoundary
          fallback={<Bloc text-title1>An error has occurred</Bloc>}
        >
          {children}
        </Sentry.ErrorBoundary>
      </div>
    </div>
  );
};

export default BAContainer;
