import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import "./WarningModal.scss";

import Bloc from "../../atoms/Bloc/Bloc";
import PrimaryButton from "../../atoms/PrimaryButton";

const WarningModal = React.memo(
  ({
    isModalOpen = false,
    setIsModalOpen,
    confirmButtonText,
    msg,
    onClose = () => ({}),
  }) => {
    const { t: t_common } = useTranslation("translation", { keyPrefix: "common" });
    const handleCancel = useCallback(() => {
      setIsModalOpen(false);
      onClose();
    }, [setIsModalOpen, onClose]);
    return (
      <>
        {isModalOpen && (
          <div className="warning-modal">
            <div className="warning-popup-bg" onClick={handleCancel} />
            <Bloc className="warning-popup" p-xl>
              <Bloc className="warning-msg" mb-m ts text1>
                {!msg ? t_common('confirmMsg') : msg}
              </Bloc>
              <Bloc>
                <PrimaryButton className="button" onClick={handleCancel}>
                  {!confirmButtonText ? t_common('yes') : confirmButtonText}
                </PrimaryButton>
              </Bloc>
            </Bloc>
          </div>
        )}
      </>
    );
  }
);

WarningModal.displayName = "WarningModal";

WarningModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string,
  msg: PropTypes.string,
};

export default WarningModal;
