import React from "react";
import "./LoadingScreen.scss";

const LoadingScreen = React.memo(() => {
  return (
    <div className="loading-screen-container">
      <div className="loader" />
    </div>
  );
});

LoadingScreen.displayName = "LoadingScreen";

LoadingScreen.propTypes = {};

export default LoadingScreen;
