const frSpecific = {
  translation: {
    appointmentCreation: {
      baSelection: "Nom du brand ambassador",
    },
    appointmentEdit: {
      baSelection: "Nom du brand ambassador",
    },
    baEnd: {
      baRoomEndMsg:
        "Merci d’avoir partagé votre savoir en matière de diamants !\n",
      baRoomEndTitle: "Virtual Home of Diamonds",
    },
    baPrepare: {
      baPrepareChecklist: [
        "1. Votre caméra est allumée",
        "2. Votre microphone est allumé",
        "3. Les lumières sont allumées",
        "4. Les bijoux et accessoires sélectionnés sont prêts",
        "5. DeBeers.fr est ouvert",
        "6. Familiarisez-vous avec nos clips vidéos De Beers pendant que vous êtes sur l’écran d’attente",
      ],
      baPrepareTitle: "Virtual Home of Diamonds",
    },
    clientEnd: {
      clientRoomEndMsg:
        "Merci pour votre temps, nous espérons que vous avez apprécié cette expérience.",
      clientRoomEndTitle: "Virtual Home of Diamonds",
      discoverButtonLink: "https://www.debeers.fr/fr-fr/home",
    },
    clientPrepare: {
      clientPrepareTitle: "Virtual Home of Diamonds",
      clientAppointmentMsg: "sera votre conseiller/ère",
      errorFullRoom:
        "La salle est pleine, il y a déjà {{numberOfClientsLimit}} personnes présentes, veuillez contacter votre conseiller/ère pour obtenir de l'aide.",
      error_500:
        "Un problème est survenu. Veuillez contacter votre conseiller/ère pour obtenir de l'aide.",
      liveCamTitle: "« Live » depuis la Route du diamant De Beers",
    },
    clientWaiting: {
      clientWaitingMsg:
        "Veuillez patienter, votre conseiller/ère va reprendre la réunion",
    },
    clientNotSupported: {
      discoverButtonLink: "https://www.debeers.fr/fr-fr/home",
    },
    login: {
      loginTitle: "Virtual Home of Diamonds",
    },
    selectCustom: {
      CONFIDENTIAL_ROOM: "Virtual Home of Diamonds",
      CONFERENCE_ROOM: "Événement interactif",
    },
    report: {
      GET_REPORT_APPOINTMENTS: "Weekly report",
      GET_REPORT_GLOBAL: "Global report",
    },
    forgetPassword: {
      forgetPasswordTitle: "Virtual Home of Diamonds",
    },
    cookieConsentComponent: {
      cookiePolicyLink: "https://www.debeers.fr/fr-fr/privacy-policy.html",
    },
    footerClient: {
      privacyCookieLink: "https://www.debeers.fr/fr-fr/privacy-policy.html",
      termsConditionsLink:
        "https://www.debeers.fr/fr-fr/terms-and-conditions.html",
    },
  },
};

export default frSpecific;
