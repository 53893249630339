import { put, select, take, race, fork } from "redux-saga/effects";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ReactGA from "react-ga";
import moment from "moment";

import {
  actions as AuthActions,
  selectors as AuthSelectors,
} from "./auth/redux";
import { actions as AppActions } from "./app/redux";
import { actions as SocketActions } from "./socket/redux";
import { actions as MeActions, selectors as MeSelectors } from "./me/redux";
import ApolloService from "../services/ApolloService";
import locales from "../assets/lang";
import conf from "../conf";
import { checkIsGuest } from "../Routing";
import AppSaga from "./app/sagas";
import env from "../env";

export default function* init() {
  if (typeof window !== "undefined") {
    if (env.SENTRY_ENVIRONMENT === "prod") {
      console.log = () => null;
      console.trace = () => null;
      console.debug = () => null;
      console.info = () => null;
    }
    const isGuest = checkIsGuest();
    const state = yield select();
    if (!state._persist?.rehydrated) {
      yield take("persist/REHYDRATE");
    }
    let lang = navigator.language ? navigator.language.substr(0, 2) : "en";
    if (!conf.SUPPORTED_LANGUAGES.includes(lang)) lang = "en";

    if (lang === "zh" && navigator.language === "zh-HK")
      lang = navigator.language;

    yield put(AppActions.setLang(lang));
    yield i18n.use(initReactI18next).init({
      lng: lang,
      resources: locales,
      interpolation: { escapeValue: false },
    });
    moment.locale("fr", locales.fr.translation.moment);
    moment.locale("zh", locales.zh.translation.moment);
    moment.locale(lang);
    yield put(AuthActions.clearError());
    if (!isGuest) {
      yield put(AuthActions.startSilentRefreshToken());
    }
    const logged = yield select(AuthSelectors.logged);

    if (logged && !isGuest) {
      let updateSucceed;

      const tokenIsLive = yield select(AuthSelectors.tokenIsLive);
      if (!tokenIsLive) {
        try {
          [updateSucceed] = yield race([
            take(AuthActions.refreshTokenSuccess.getType()),
            take(AuthActions.logout.getType()),
          ]);
        } catch (e) {
          console.log("race fail", e);
        }
      }

      const token = yield select(AuthSelectors.token);
      ApolloService.setToken(token.tokenType + " " + token.idToken);
      yield put(MeActions.requestLoadMe());
      const me = yield select(MeSelectors.me);
      if (!me.id) yield take(MeActions.loadMeSuccess.getType());
      const socket = yield select(MeSelectors.socket);
      const socketPath = yield select(MeSelectors.socketPath);
      if (!socket) yield put(SocketActions.requestConnect(false, env.SOCKET, env.SOCKET_PATH));
      else yield put(SocketActions.requestConnect(false, socket, socketPath));
    } 

    if (isGuest) yield fork(AppSaga.initReportingWithCookieConsent);
    else yield fork(AppSaga.initReporting);
    yield put(AppActions.startApp());
  }
}
