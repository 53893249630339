import { createMigrate } from "redux-persist";

const migrate = {
  // eslint-disable-next-line
  1: (state) => ({}),
  // eslint-disable-next-line
  2: (state) => ({}),
  // eslint-disable-next-line
  3: (state) => ({}),
  // eslint-disable-next-line
  4: (state) => ({}),
};

const getConfig = (storage, whitelistAddon = []) => {
  const persistConfig = {
    enabled: true,
    options: {
      key: "root",
      storage,
      //   stateReconciler: seamlessImmutableReconciler,
      //   transforms: [seamlessImmutableTransformCreator({})],
      version: 1,
      migrate: createMigrate(migrate, { debug: false }),
      whitelist: ["auth"].concat(whitelistAddon),
    },
  };

  return persistConfig;
};

export default getConfig;
