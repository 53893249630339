import React, { lazy, useCallback } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { isMobile } from "react-device-detect";
import { createBrowserHistory } from "history";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { selectors as AuthSelectors } from "./redux/auth/redux";
import { selectors as AppSelectors } from "./redux/app/redux";
import { selectors as LiveSellerSelectors } from "./redux/liveSeller/redux";
import { selectors as BoutiquesSelectors } from "./redux/boutiques/redux";
import { useMeRole } from "./redux/me/hooks";

import BAContainer from "./containers/BAContainer/BAContainer";
import MobileBAContainer from "./containers/MobileBAContainer/MobileBAContainer";

// import ClientHome from "./pages/ClientHome/ClientHome";
// import BALogin from "./pages/BALogin/BALogin";
// import MobileBALogin from "./pages/MobileBALogin/MobileBALogin";
// import BAForgetPassword from "./pages/BAForgetPassword/BAForgetPassword";
// import MobileBAForgetPassword from "./pages/MobileBAForgetPassword/MobileBAForgetPassword";
// import BACalendar from "./pages/BACalendar/BACalendar";
// import MobileBACalendar from "./pages/MobileBACalendar/MobileBACalendar";
// import BAPrepare from "./pages/BAPrepare/BAPrepare";
// import BAEnded from "./pages/BAEnded/BAEnded";

import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import ErrorScreen from "./pages/ErrorScreen/ErrorScreen";
import Logout from "./pages/BALogout";
import CookieConsent from "./CookieConsent";
// import BroadcastRoom from "./pages/BroadcastRoom";
import BAPrepareMobile from "./pages/BAPrepareMobile/BAPrepareMobile";

const RoomSwitch = React.lazy(() =>
  import("./containers/RoomSwitch/RoomSwitch")
);

const ClientHome = React.lazy(() => import("./pages/ClientHome/ClientHome"));
const BALogin = React.lazy(() => import("./pages/BALogin/BALogin"));
const MobileBALogin = React.lazy(() =>
  import("./pages/MobileBALogin/MobileBALogin")
);
const BAForgetPassword = React.lazy(() =>
  import("./pages/BAForgetPassword/BAForgetPassword")
);
const MobileBAForgetPassword = React.lazy(() =>
  import("./pages/MobileBAForgetPassword/MobileBAForgetPassword")
);
const BACalendar = React.lazy(() => import("./pages/BACalendar/BACalendar"));
const MobileBACalendar = React.lazy(() =>
  import("./pages/MobileBACalendar/MobileBACalendar")
);
const BAPrepare = React.lazy(() => import("./pages/BAPrepare/BAPrepare"));
const BAEnded = React.lazy(() => import("./pages/BAEnded/BAEnded"));
const Admin = React.lazy(() => import("./pages/Admin/Admin"));

export const history = createBrowserHistory();

// required for sentry
export const routes = [
  { path: "/:alias" },
  { path: "/prepare/:alias" },
  { path: "/ba-room/:alias" },
  // { path: "/broadcast/:alias" },
  { path: "/logout" },
  { path: "/end" },
  { path: "/" },
];

export const checkIsGuest = () => {
  return !!window.location.pathname.match(/^\/([0-9A-Z]{2}-){4}[0-9A-Z]{2}$/g);
};

const Routing = () => {
  const isLogged = useSelector(AuthSelectors.logged);
  const appStarted = useSelector(AppSelectors.appStarted);

  const LoginPage = useCallback(() => {
    if (isMobile) return <MobileBALogin />;
    return <BALogin />;
  }, []);

  const ForgetPasswordPage = useCallback(() => {
    if (isMobile) return <MobileBAForgetPassword />;
    return <BAForgetPassword />;
  }, []);

  const CalendarPage = useCallback(() => {
    if (isMobile)
      return (
        <MobileBAContainer>
          <MobileBACalendar />
        </MobileBAContainer>
      );
    return (
      <BAContainer>
        <BACalendar />
      </BAContainer>
    );
  }, []);

  const boutiqueError = useSelector(BoutiquesSelectors.error);
  if (boutiqueError) return <ErrorScreen error={boutiqueError} />;

  if (!appStarted) return <LoadingScreen />;

  return (
    <Router>
      <Switch>
        <Route exact path="/prepare/:alias">
          {isLogged ? (
            <BAContainer>
              {isMobile ? <BAPrepareMobile /> : <BAPrepare />}
            </BAContainer>
          ) : (
            <LoginPage />
          )}
        </Route>
        <Route exact path="/ba-room/:alias">
          {!isLogged ? <LoginPage /> : <BARoomPrepared />}
        </Route>
        <Route exact path="/logout">
          {!isLogged ? <LoginPage /> : <Logout />}
        </Route>
        <Route exact path="/ba-end">
          {!isLogged ? (
            <LoginPage />
          ) : (
            <BAContainer>
              <BAEnded />
            </BAContainer>
          )}
        </Route>
        <Route exact path="/">
          {isLogged ? <CalendarPage /> : <LoginPage />}
        </Route>
        <Route exact path="/forget-password">
          {isLogged ? (
            <>
              <CookieConsent />
              <RoomSwitch store="liveSeller" />
            </>
          ) : (
            <ForgetPasswordPage />
          )}
        </Route>
        {isLogged && <AdminPage />}
        <Route exact path="/:alias">
          <CookieConsent />
          <ClientHome />
        </Route>
      </Switch>
    </Router>
  );
};

const AdminPage = () => {
  const { isAdmin } = useMeRole();

  if (isAdmin)
    return (
      <Route exact path="/admin">
        <BAContainer>
          <Admin />
        </BAContainer>
      </Route>
    );
  return (
    <Route exact path="/:alias">
      <CookieConsent />
      <ClientHome />
    </Route>
  );
};

const BARoomPrepared = () => {
  const isBAPrepared = useSelector(LiveSellerSelectors.prepared);
  const { alias } = useParams();
  if (isBAPrepared) {
    return (
      <BAContainer>
        <RoomSwitch store="liveSeller" />
      </BAContainer>
    );
  }

  return (
    <Redirect
      to={{
        pathname: "/prepare/" + alias,
      }}
    />
  );
};

export default Routing;
