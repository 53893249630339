import { all, takeEvery, put, select } from "redux-saga/effects";
import { actions as UsersActions } from "./redux";
import { selectors as BoutiquesSelectors } from "../boutiques/redux";
import ApolloService from "../../services/ApolloService";
import { LIST_USERS } from "./graph";
import AppSagas from "../app/sagas";

export default class UsersSagas {
  static *requestLoadUsers() {
    try {
      const selectedBoutiqueId = yield select(
        BoutiquesSelectors.selectedBoutiqueId
      );
      const result = yield ApolloService.mutate(LIST_USERS, {
        pagination: { sort: { field: "firstName", order: "ASC" } },
        filters: {
          isSeller: true,
          boutiqueId: selectedBoutiqueId,
        },
      });

      if (result.ok) {
        yield put(UsersActions.requestLoadUsersSuccess(result.data.users));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *loop() {
    yield all([
      yield takeEvery(
        UsersActions.requestLoadUsers.getType(),
        UsersSagas.requestLoadUsers
      ),
    ]);
  }
}
