import React, { useCallback } from "react";
import Icon from "../Icon/Icon";
import "./IconButton.scss";
import classNames from "classnames";

const IconButton = ({
  className,
  icon,
  size,
  color,
  onClick,
  enabled = true,
}) => {
  const handleClick = useCallback(
    (e) => {
      if (enabled && onClick) onClick(e);
    },
    [onClick, enabled]
  );
  return (
    <div
      className={classNames("icon-button", className, {
        "icon-button-disabled": !enabled,
      })}
      onClick={handleClick}
    >
      <Icon icon={icon} size={size} color={color} />
    </div>
  );
};

export default IconButton;
