import gql from "graphql-tag";

export const usersFragment = gql`
  fragment UsersInfo on User {
    id
    firstName
    lastName
    displayName
  }
`;

export const LIST_USERS = gql`
  query ListUsers($pagination: Pagination, $filters: UserFilters!) {
    users(pagination: $pagination, filters: $filters) {
      ...UsersInfo
    }
  }
  ${usersFragment}
`;
