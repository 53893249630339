import { race, take, select, all, takeEvery } from "redux-saga/effects";
import { getCookieConsentValue } from "react-cookie-consent";
import { matchPath } from "react-router-dom";
import ReactGA from "react-ga";
import { Logger } from "janus-front-sdk";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { actions as AppActions } from "./redux";
import { selectors as MeSelectors } from "../me/redux";
import { routes, history, checkIsGuest } from "../../Routing";

import env from "../../env";
import { ROLES } from "../../utils/const";
import { concatenateStringWithGaZoneAndObs } from "../../utils/string";

export default class AppSagas {
  static reportError(error) {
    console.error("error has been reported to sentry");
    console.error(error);
    Sentry.captureException(error);
  }

  /**
   *
   * @param {Object} event
   * @param {String} event.category
   * @param {String} event.action
   * @param {String} event.label
   * @param {Int} event.value
   */
  static *reportEvent({ payload: event }) {
    try {
      const isGuest = checkIsGuest();
      event.category = concatenateStringWithGaZoneAndObs(event.category);
      event.action = concatenateStringWithGaZoneAndObs(event.action);

      const cookieConsent = env.REACT_APP_ONETRUST_ID
        ? window?.OnetrustActiveGroups?.includes("C0002")
        : getCookieConsentValue() === "true";

      // const cookieConsent = window?.OnetrustActiveGroups?.includes("C0002"); //getCookieConsentValue();
      const me = yield select(MeSelectors.me);
      if (
        (!isGuest && me && !me.roles.includes(ROLES.TEST)) ||
        (isGuest && cookieConsent === true)
      ) {
        console.log("reporting event", event);
        ReactGA.event(event);
      }
    } catch (error) {
      console.error(error);
    }
  }

  static *initReportingWithCookieConsent() {
    AppSagas.initSentry();
    if (env.REACT_APP_ONETRUST_ID) {
      AppSagas.initGA();
    } else {
      let cookieConsent = getCookieConsentValue();
      if (cookieConsent === undefined) {
        const [accept, decline] = yield race([
          take(AppActions.acceptCookies.getType()),
          take(AppActions.declineCookies.getType()),
        ]);
        cookieConsent = getCookieConsentValue();
      }
      if (cookieConsent === "true") {
        console.log("initialising report services");
        AppSagas.initGA();
      }
    }
  }

  static initReporting() {
    AppSagas.initSentry();
    console.log("initialising report services");
    AppSagas.initGA();
  }

  static initSentry() {
    const sentryConfig = {
      dsn: "https://58d30d9883c44e39957d447fab868160@o992047.ingest.sentry.io/5949331",
      environment: env.SENTRY_ENVIRONMENT,
      release: process.env.REACT_APP_VERSION,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(
            history,
            routes,
            matchPath
          ),
        }),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: Number(env.SENTRY_TRACE_RATE),
      // tracesSampleRate: env.ENV === 'dev' ? 0.0 : 1.0,
    };

    Sentry.init(env.SENTRY_ENVIRONMENT === "local" ? {} : sentryConfig);

    Logger.setLogCallback((prefix, message, data) => {
      console.log("DATA TO BREADSCRUP", data);
      Sentry.addBreadcrumb({
        category: prefix,
        message: message,
        level: Sentry.Severity.Log,
        data,
      });
    });

    Logger.setInfoCallback((prefix, message, data) => {
      Sentry.addBreadcrumb({
        category: prefix,
        message: message,
        level: Sentry.Severity.Info,
        data: JSON.stringify(data),
      });
    });

    Logger.setWarnCallback((prefix, message, data) => {
      Sentry.addBreadcrumb({
        category: prefix,
        message: message,
        level: Sentry.Severity.Warning,
        data,
      });
    });

    Logger.setErrorCallback((prefix, message, data) => {
      Sentry.addBreadcrumb({
        category: prefix,
        message: message,
        level: Sentry.Severity.Error,
        data,
      });
    });
  }

  static initGA() {
    if (!env.REACT_APP_ONETRUST_ID) {
      ReactGA.initialize(env.GA_TRACKING_ID);
      return;
    }
    if (window?.OnetrustActiveGroups?.includes("C0002")) {
      console.log("starting GA");
      ReactGA.initialize(env.GA_TRACKING_ID);
    } else {
      console.log("blocking GA");
    }

    window.OptanonWrapper = () => {
      if (window?.OnetrustActiveGroups?.includes("C0002")) {
        ReactGA.initialize(env.GA_TRACKING_ID);
      } else {
      }
    };
  }

  static *loop() {
    yield all([
      yield takeEvery(AppActions.reportEvent.getType(), AppSagas.reportEvent),
    ]);
  }
}
