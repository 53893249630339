import {
  all,
  takeLeading,
  takeEvery,
  takeLatest,
  put,
  select,
  call,
} from "redux-saga/effects";
import { actions as EventsActions } from "./redux";
import { selectors as BoutiquesSelectors } from "../boutiques/redux";
import ApolloService from "../../services/ApolloService";
import {
  CREATE_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  LIST_EVENTS,
  LOAD_EVENT,
} from "./graph";
import { actions as AppActions } from "../app/redux";
import AppSagas from "../app/sagas";
import { Event } from "../socket/messageTypes";

export default class EventsSagas {
  static *requestCreateEvent({ payload }) {
    try {
      const { event } = payload;
      const selectedBoutiqueId = yield select(
        BoutiquesSelectors.selectedBoutiqueId
      );
      yield put(EventsActions.setIsSendingRequest());
      const result = yield ApolloService.mutate(CREATE_EVENT, {
        event: {
          client: {
            mail: event.clientEmail,
            fullName: event.clientFullName,
          },
          ownerId: event.baId,
          scheduleDate: event.scheduleDate,
          scheduleEndDate: event.scheduleEndDate,
          boutiqueId: selectedBoutiqueId,
          roomType: event.roomType,
        },
      });

      if (result.ok) {
        yield put(EventsActions.closeCreationPopup());
        yield put(EventsActions.createEventSuccess(result.data.createEvent));
        yield put(AppActions.reportEvent("obsevent", "create"));
      } else {
        yield put(EventsActions.requestFailed(500));
        yield AppSagas.reportError(result);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestEditEvent({ payload }) {
    try {
      const { eventId, event } = payload;
      const selectedBoutiqueId = yield select(
        BoutiquesSelectors.selectedBoutiqueId
      );
      yield put(EventsActions.setIsSendingRequest());
      const result = yield ApolloService.mutate(UPDATE_EVENT, {
        id: eventId,
        event: {
          client: {
            mail: event.clientEmail,
            fullName: event.clientFullName,
          },
          ownerId: event.baId,
          scheduleDate: event.scheduleDate,
          scheduleEndDate: event.scheduleEndDate,
          boutiqueId: selectedBoutiqueId,
          roomType: event.roomType,
        },
      });

      if (result.ok) {
        yield put(EventsActions.removeEditFocusedEventId());
        yield put(
          EventsActions.editEventSuccess(eventId, result.data.updateEvent)
        );
        yield put(AppActions.reportEvent("obsevent", "update"));
      } else {
        yield put(EventsActions.requestFailed(500));
        yield AppSagas.reportError(result);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestDeleteEvent({ payload }) {
    try {
      const { eventId } = payload;
      yield put(EventsActions.setIsSendingRequest());
      const result = yield ApolloService.mutate(DELETE_EVENT, {
        id: eventId,
      });
      if (result.ok && result.data?.removeEvent === true) {
        yield put(EventsActions.removeEditFocusedEventId());
        yield put(EventsActions.deleteEventSuccess(eventId));
        yield put(AppActions.reportEvent("obsevent", "delete"));
      } else {
        yield put(EventsActions.requestFailed(500));
        yield AppSagas.reportError(result);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestLoadEvent({ payload }) {
    try {
      const { type, eventId } = payload;
      const result = yield ApolloService.query(LOAD_EVENT, {
        id: eventId,
      });
      if (result.ok) {
        if (type === Event.EVENT_CREATED)
          yield put(EventsActions.loadEventCreatedSuccess(result.data.event));
        else if (type === Event.EVENT_EDITED)
          yield put(
            EventsActions.loadEventEditedSuccess(eventId, result.data.event)
          );
      } else {
        yield AppSagas.reportError(result);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *loadEvents({ skip, limit, startDate, endDate }) {
    try {
      const selectedBoutiqueId = yield select(
        BoutiquesSelectors.selectedBoutiqueId
      );
      const result = yield ApolloService.query(LIST_EVENTS, {
        pagination: {
          skip: skip,
          limit: limit,
          sort: { field: "scheduleDate", order: "ASC" },
        },
        filters: {
          and: [
            { scheduleDate: { gte: startDate } },
            { scheduleDate: { lt: endDate } },
          ],
          boutiqueId: selectedBoutiqueId,
        },
      });
      if (result.ok) {
        return result.data.events;
      } else {
        yield AppSagas.reportError(result);
      }
      return [];
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *loadConcurrentEvent({ startDate, endDate, excludeEventIds = [] }) {
    try {
      const selectedBoutiqueId = yield select(
        BoutiquesSelectors.selectedBoutiqueId
      );
      const result = yield ApolloService.query(LIST_EVENTS, {
        pagination: {
          skip: 0,
          limit: 1,
          sort: { field: "scheduleDate", order: "ASC" },
        },
        filters: {
          _id: { nin: excludeEventIds },
          and: [
            { scheduleDate: { lt: endDate } },
            {
              scheduleEndDate: { gt: startDate },
            },
          ],
          boutiqueId: selectedBoutiqueId,
        },
      });
      if (result.ok) {
        return result.data.events;
      } else {
        yield AppSagas.reportError(result);
      }
      return [];
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestLoadEvents({ payload }) {
    try {
      const { startDate, endDate } = payload;
      const limit = 100;
      let skip = 0;
      let events = [];
      let loadedEvents = [];
      loadedEvents = yield call(EventsSagas.loadEvents, {
        skip: skip,
        limit: limit,
        startDate,
        endDate,
      });
      if (loadedEvents) {
        events.push(...loadedEvents);
        skip += loadedEvents.length;
        while (loadedEvents.length === limit) {
          loadedEvents = yield call(EventsSagas.loadEvents, {
            skip: skip,
            limit: limit,
            startDate,
            endDate,
          });
          if (loadedEvents) {
            events.push(...loadedEvents);
            skip += loadedEvents.length;
          }
        }
        if (events.length) yield put(EventsActions.loadEventsSuccess(events));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestCheckConcurrentEvents({ payload }) {
    try {
      const { startDate, endDate, eventId } = payload;
      yield put(EventsActions.setIsCheckingConcurrentEvents(true));
      const startDateLimit = new Date(
        new Date(startDate).getTime() - 30 * 60 * 1000
      ).toJSON();
      const endDateLimit = new Date(
        new Date(endDate).getTime() + 30 * 60 * 1000
      ).toJSON();
      let loadedEvents = [];
      loadedEvents = yield call(EventsSagas.loadConcurrentEvent, {
        startDate,
        endDate,
        excludeEventIds: eventId ? [eventId] : [],
      });
      if (loadedEvents?.length) {
        yield put(
          EventsActions.loadConcurrentBlockingEventsSuccess(loadedEvents)
        );
        return;
      }
      loadedEvents = yield call(EventsSagas.loadConcurrentEvent, {
        startDate: startDateLimit,
        endDate: endDateLimit,
        excludeEventIds: eventId ? [eventId] : [],
      });
      if (loadedEvents?.length) {
        yield put(EventsActions.loadConcurrentEventsSuccess(loadedEvents));
        return;
      }
      yield put(EventsActions.setIsCheckingConcurrentEvents(false));
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *loop() {
    yield all([
      yield takeLeading(
        EventsActions.requestCreateEvent.getType(),
        EventsSagas.requestCreateEvent
      ),
      yield takeLeading(
        EventsActions.requestEditEvent.getType(),
        EventsSagas.requestEditEvent
      ),
      yield takeLeading(
        EventsActions.requestDeleteEvent.getType(),
        EventsSagas.requestDeleteEvent
      ),
      yield takeEvery(
        EventsActions.requestLoadEvent.getType(),
        EventsSagas.requestLoadEvent
      ),
      yield takeEvery(
        EventsActions.requestLoadEvents.getType(),
        EventsSagas.requestLoadEvents
      ),
      yield takeLatest(
        EventsActions.requestCheckConcurrentEvents.getType(),
        EventsSagas.requestCheckConcurrentEvents
      ),
    ]);
  }
}
