import { all, takeLeading, select, put } from "redux-saga/effects";
import {
  actions as DevicesActions,
  selectors as DevicesSelectors,
} from "./redux";
import { listDevices } from "janus-front-sdk";
import AppSagas from "../app/sagas";

export default class DevicesSagas {
  static *requestLoadDevices() {
    try {
      const loaded = yield select(DevicesSelectors.devicesLoaded);
      if (loaded) return;
      let devices = yield listDevices();

      devices = devices.map((device) => ({
        kind: device.kind,
        deviceId: device.deviceId,
        label: device.label,
      }));
      yield put(DevicesActions.setDevices(devices));
    } catch (e) {
      console.log(e.code);
      if (e?.message === "NotAllowedError") {
        yield put(DevicesActions.setIsAllowed(false));
      } else {
        yield put(DevicesActions.setError(true));
        AppSagas.reportError(e);
      }
    }
  }

  static *loop() {
    yield all([
      yield takeLeading(
        DevicesActions.requestLoadDevices.getType(),
        DevicesSagas.requestLoadDevices
      ),
    ]);
  }
}
