import { useCallback, useEffect, useState } from "react";

const KEYS_CODE = {
  ctrl: 17,
  shift: 16,
  Q: 81,
};

const useShortcutMultipleKeys = (keys = [], callback, isEnabled = true) => {
  const [keysState, setKeysState] = useState({});

  const onKeyDown = useCallback(
    (e) => {
      setKeysState({ ...keysState, [e.keyCode]: "keydown" });
    },
    [keysState]
  );
  const onKeyUp = useCallback(
    (e) => {
      setKeysState({ ...keysState, [e.keyCode]: "keyup" });
    },
    [keysState]
  );

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    };
  }, [onKeyDown, onKeyUp]);

  useEffect(() => {
    if (isEnabled) {
      let keysFoundCounter = 0;
      for (const key of keys) {
        if (keysState[KEYS_CODE[key]] === "keydown") {
          keysFoundCounter++;
        } else return;
      }
      if (keysFoundCounter === keys.length) callback();
    }
  }, [keys, keysState, callback, isEnabled]);
};

export default useShortcutMultipleKeys;
