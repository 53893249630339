import { put, take } from "redux-saga/effects";
import { createAction } from "redux-act";
import { eventChannel, END } from "redux-saga";
import { actions as LiveSellerActions } from "./redux";

import peerMap from "../../services/PeerMap";
import createPublisherObject from "../../utils/createPublisherObject";
import { getSocket } from "../../services/Socket";

export const faceActions = {
  newPublisher: createAction("face/new publisher", (publisher, remotePeer) => ({
    publisher,
    remotePeer,
  })),
  removePublisher: createAction("face/remove publisher", (publisherId) => ({
    publisherId,
  })),
  onJoined: createAction("janus visio on joined"),
  setPublisherTalking: createAction(
    "face/on talking change",
    (publisherId, isTalking) => ({ publisherId, isTalking })
  ),
  updatePublisher: createAction("face/update publisher", (publisher) => ({
    publisher,
  })),
  setPublisherBadNetwork: createAction(
    "face/on bad network",
    (publisherId, badNetwork) => ({ publisherId, badNetwork })
  ),
};

export const productActions = {
  onJoined: createAction(
    "product/on joined",
    (publisherId, publisherCount) => ({
      publisherId,
      publisherCount,
    })
  ),
};

export default class JanusWatcher {
  static *listenScreenShare(stream) {
    const channel = eventChannel((emmiter) => {
      const videoTrack = stream.getVideoTracks()[0];

      const handleTrackEnded = () => {
        emmiter(LiveSellerActions.requestStopShareScreen());
      };
      videoTrack.addEventListener("ended", handleTrackEnded);

      return () => {
        videoTrack.removeEventListener("ended", handleTrackEnded);
      };
    });

    try {
      while (true) {
        let action = yield take(channel);
        yield put(action);
      }
    } catch (e) {
      console.log(e);
    } finally {
      channel.close();
    }
  }

  /**
   *
   * @param {SocketRoom} socketRoom
   * @param {JanusRoom} janusRoom
   */
  static *listenRooms(socketRoom, janusRoom, socketClient) {
    const channel = eventChannel((emmiter) => {
      let timeout;

      const handleDisconnected = () => {
        emmiter(LiveSellerActions.setServiceFailed(true));
      };

      const handleConnected = () => {
        emmiter(LiveSellerActions.setServiceFailed(false));
      };

      socketClient.on("disconnect", handleDisconnected);
      socketClient.on("connect", handleConnected);

      janusRoom.on("joined", () => {});

      janusRoom.on("janusClosed", () => {
        emmiter(LiveSellerActions.setServiceFailed(true));
      });

      janusRoom.on("janusOpened", () => {
        emmiter(LiveSellerActions.setServiceFailed(false));
      });

      janusRoom.on("newRemotePeer", async (remotePeer) => {
        peerMap.addPeer(remotePeer);
        await remotePeer.receive();

        remotePeer.on("destroyed", () => {
          emmiter(
            faceActions.removePublisher(remotePeer.publisher.publisherId)
          );
        });

        remotePeer.on("talking", (isTalking) => {
          emmiter(
            faceActions.setPublisherTalking(
              remotePeer.publisher.publisherId,
              isTalking
            )
          );
        });

        remotePeer.on("slowlink.emitter", () => {
          clearTimeout(timeout);
          setTimeout(() => {
            emmiter(
              faceActions.setPublisherBadNetwork(
                remotePeer.publisher.publisherId,
                false
              )
            );
          }, 2000);
          emmiter(
            faceActions.setPublisherBadNetwork(
              remotePeer.publisher.publisherId,
              true
            )
          );
        });

        remotePeer.on("updated", () => {
          emmiter(
            faceActions.updatePublisher(
              createPublisherObject(socketRoom, remotePeer)
            )
          );
        });

        emmiter(
          faceActions.newPublisher(
            createPublisherObject(socketRoom, remotePeer)
          )
        );
      });

      janusRoom.on("error", (e) => {
        console.error("error in janus room", e);
      });

      return () => {
        socketClient.off("disconnect", handleDisconnected);
        socketClient.off("connect", handleConnected);
        clearTimeout(timeout);
      };
    });

    try {
      while (true) {
        let action = yield take(channel);
        yield put(action);
      }
    } catch (e) {
      console.log(e);
    } finally {
      channel.close();
      console.log("seller janus watcher terminated");
    }
  }
}
