import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import {
  actions as LiveSellerActions,
  selectors as LiveSellerSelectors,
} from "../../redux/liveSeller/redux";
import CameraPreview from "../../components/CameraPreview/CameraPreview";
import "./BAPrepareMobile.scss";
import PrimaryButton from "../../atoms/PrimaryButton";

const BAPrepareMobile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation("translation", { keyPrefix: "baPrepare" });
  const { alias } = useParams();
  const error = useSelector(LiveSellerSelectors.error);
  const fullscreenContainer = useRef(null);

  useEffect(() => {
    dispatch(LiveSellerActions.requestLoadEvent(alias));
    return () => dispatch(LiveSellerActions.clearError());
  }, [dispatch, alias]);

  const currentEvent = useSelector(LiveSellerSelectors.currentEvent);

  const handleGoRoom = useCallback(() => {
    dispatch(LiveSellerActions.setPrepared());
    history.push("/ba-room/" + currentEvent.alias);
  }, [history, currentEvent, dispatch]);

  return (
    <div ref={fullscreenContainer} className="ba-prepare-page-mobile">
      <CameraPreview
        className="env-preview"
        deviceId="user"
        facingMode="user"
        mirror={true}
      />
      <div className="over-content">
        <PrimaryButton className="btn-go" onClick={handleGoRoom}>
          {t("baAccessRoomButton")}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default BAPrepareMobile;
