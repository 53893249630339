import en from "./en.json";
import enSpecific from "./specific/en";
import fr from "./fr.json";
import frSpecific from "./specific/fr";
import zh from "./zh.json";
import zhSpecific from "./specific/zh";
import zhHK from "./zh-HK.json";
import zhHKSpecific from "./specific/zh-HK";

const multiLangSpecific = {
  en: { specific: enSpecific, merged: en },
  fr: { specific: frSpecific, merged: fr },
  zh: { specific: zhSpecific, merged: zh },
  zhHK: { specific: zhHKSpecific, merged: zhHK },
};

for (const langKey in multiLangSpecific) {
  if (multiLangSpecific[langKey].specific?.translation) {
    const translationSpecific = multiLangSpecific[langKey].specific.translation;
    for (const objectKey in translationSpecific) {
      for (const wordKey in translationSpecific[objectKey]) {
        multiLangSpecific[langKey].merged = {
          ...multiLangSpecific[langKey].merged,
          translation: {
            ...multiLangSpecific[langKey].merged.translation,
            [objectKey]: {
              ...multiLangSpecific[langKey].merged.translation[objectKey],
              [wordKey]: translationSpecific[objectKey][wordKey],
            },
          },
        };
      }
    }
  }
}

export default {
  en: multiLangSpecific.en.merged,
  fr: multiLangSpecific.fr.merged,
  zh: multiLangSpecific.zh.merged,
  "zh-HK": multiLangSpecific.zhHK.merged,
};
