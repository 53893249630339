const zhSpecific = {
  translation: {
    common: {
      ok: "确认",
      yes: "是",
      no: "否",
      confirmMsg: "确认？",
      FACE: "面部",
      WIDE: "宽",
      MACRO: "微距",
      copied: "已复制！",
    },
    appointmentCreation: {
      baSelection: "品牌销售大使",
      clientEmailField: "电子邮箱地址",
      clientFullNameField: "名字与姓氏",
      dateSelection: "选择一个日期",
      largeDurationHoursAndMinutes:
        "请注意，该活动时长为{{hours}} {{hour_word}} {{minutes}} {{minute_word}}。",
      largeDurationHours: "请注意，此活动的持续时间为 {{hours}} {{hour_word}}.",
      eventCloseWarning:
        "请注意，该活动与 {{clientName}} 在同一天 {{startTime}} 的预约非常相近。",
      eventCreationOverlaps:
        "请注意，这项新活动与{{clientName}}在{{startTime}}安排的预约时间重叠。",
      hourEndSelection: "结束",
      hourStartSelection: "开始",
      roomTypeSelection: "房间类型",
      saveButton: "保存",
      error_500: "出错了",
      hour_one: "小时",
      hour_other: "小时",
      minute_one: "分钟",
      minute_other: "分钟",
    },
    appointmentEdit: {
      baAccessButton: "进入房间",
      baSelection: "品牌销售大使",
      clientEmailField: "电子邮箱地址",
      clientFullNameField: "名字与姓氏",
      clientURL: "客户网页",
      dateSelection: "日期",
      deleteButton: "删除",
      editButton: "保存",
      largeDurationHoursAndMinutes:
        "请注意，该活动时长为{{hours}} {{hour_word}} {{minutes}} {{minute_word}}。",
      largeDurationHours: "请注意，此活动的持续时间为 {{hours}} {{hour_word}}.",
      eventCloseWarning:
        "请注意，该活动与 {{clientName}} 在同一天 {{startTime}} 的预约非常相近。",
      eventEditOverlaps:
        "请注意，该活动与 {{clientName}} 在同一天 {{startTime}} 的预约时间有重合。",
      hourEndSelection: "结束",
      hourStartSelection: "开始",
      roomTypeSelection: "房间类型",
      error_500: "出错了",
      hour_one: "小时",
      hour_other: "小时",
      minute_one: "分钟",
      minute_other: "分钟",
    },
    datePicker: {
      dateFormat: "dd/MM/yyyy",
      datePickerDateFormat: ["h:mm aa", "h:mmaa"],
      datePickerTimeFormat: "h:mm aa",
    },
    selectCustom: {
      CONFIDENTIAL_ROOM: "虚拟钻石之家（VHoD）",
      CONFERENCE_ROOM: "Live Event",
    },
    report: {
      GET_REPORT_APPOINTMENTS: "Weekly report",
      GET_REPORT_GLOBAL: "Global report",
    },
    baEnd: {
      baRoomEndMsg: "感谢您分享钻石专业知识！\n",
      baRoomEndTitle: "虚拟钻石之家（VHoD）",
      backToCalendarButton: "返回日历",
    },
    baPrepare: {
      appointmentCountDown: "HH:mm:ss",
      audioInput: "声音输入",
      baAccessRoomButton: "迎接客户",
      baAppointmentTitle: "您的预约",
      baPrepareChecklist: [
        "1.您的摄像头已打开",
        "2.您的麦克风已打开",
        "3.灯已打开",
        "4.需展示珠宝和道具已经准备就绪",
        "5.DeBeers.com已经打开",
        "6.在等待屏幕中，观看戴比尔斯视频片段，熟悉珠宝内容",
      ],
      baPrepareChecklistTitle: "检查列表",
      baPrepareTitle: "虚拟钻石之家（VHoD）",
      clientName: "{{clientName}}",
      errorAuthorization:
        "请确保您的摄像头和麦克风已打开，重新加载页面后进入房间。",
      errorListDevice: "请确保您的相机没有在其他页面或应用程序中使用。",
      errorNotFound: "未找到活动",
      errorOwner: "您无法开始本次活动",
      errorRoomClosed: "房间已关闭",
      liveIn: "Live in",
      liveSince: "Live",
      meetingDate: "{{date}} 的 {{startTime}}-{{endTime}}",
      error_500: "出错了",
    },
    baRoom: {
      baNextAppointmentTitle: "下一个预约",
      backToLiveButton: "回到直播",
      clientName: "{{clientName}}",
      confirmCloseRoom: "请注意：确定要关闭房间？关闭后无法再次打开。",
      meetingDate: "{{date}} 的 {{startTime}}-{{endTime}}",
      waitingScreenActiveMsgPart1: "客户正在等候区观看您选择的品牌视频。",
      waitingScreenActiveMsgPart2: "客户无法看到您和听到您的声音。",
      waitingScreenActiveTitle: "等待屏幕启动",
      waitingScreenVideoChoiceMsg: "在等候区，客户将看到您选择的宣传视频。",
      waitingScreenVideoChoicePlayButton: "播放视频",
      waitingScreenVideoChoiceTitle: "选择一段宣传视频",
      errorOwnerInside: "您已连接这个房间",
      error_500: "出错了",
      errorRecreateRoom: "糟糕，出错了，\n请创建新房间并继续。",
    },
    calendar: {
      baAccessButton: "进入房间",
      calendarWeekDays: ["M", "T", "W", "T", "F", "S", "S"],
      meetingTime: "{{startTime}} - {{endTime}}",
      monthTitle: "MMMM YYYY",
      selectedDaysMeetingTitle: "Do MMMM YYYY",
      todaysMeetingsTitle: "今天",
    },
    cameraControl: {
      cameraDisplayOffButton: "PREVIEW",
      cameraDisplayOnButton: "LIVE",
      faceCameraName: "Camera",
      productCameraName: "Product",
    },
    chat: {
      inputPlaceholder: "发送信息",
      clientEnterRoom: "{{displayName}} 已进入房间",
    },
    clientEnd: {
      clientRoomEndMsg:
        "感谢您的参与。希望您对此次虚拟钻石之家的体验感到满意。",
      clientRoomEndTitle: "虚拟钻石之家（VHoD）",
      discoverButton: "探索",
      discoverButtonLink: "https://www.debeers.com.cn/zh-cn/home",
      discoverMsg: "欢迎通过我们的网站了解更多信息",
    },
    clientPrepare: {
      appointmentDate: "dddd Do MMMM YYYY [at] h:mm A",
      appointmentCountDown: "HH:mm:ss",
      daysTimeDiff: "{{days_number}} {{days_unit}}",
      day_one: "天",
      day_other: "天",
      cameraSelection: "选择摄像头",
      audioSelection: "选择麦克风",
      clientAccessRoomButton: "迎接客户",
      clientAppointmentMsg: "将是您的品牌销售大使",
      clientAppointmentTitle: "您的预约",
      clientPrepareTitle: "虚拟钻石之家（VHoD）",
      clientViewerNameField: "请输入您的姓名",
      meetingStarting: "您的预约即将开始",
      errorMissingFields: "未填写",
      errorAuthorization:
        "请确保您的摄像头和麦克风已打开，重新加载页面后进入房间。",
      errorListDevice:
        "请确保您的相机没有在其他页面或应用程序中使用。请重新载入页面，进入房间。",
      errorNotFound: "未找到活动",
      errorFullRoom:
        "房间已满员，已有{{numberOfClientsLimit}}人加入，请联系您的品牌销售大使以获得进一步协助。",
      error_500: "出错了。请联系您的品牌销售大使，获得进一步协助。",
      liveIn: "Live将于{{timer}}后开始",
      liveSince: "Live",
      warningModalButtonText: "重新加载",
      noCameraFound: "未找到摄像头",
      noMicrophoneFound: "未找到麦克风",
      liveCamTitle: "戴比尔斯钻石源路Live",
    },
    clientRoom: {
      baCameraWithName: "{{baFirstName}} {{baLastName}}",
      lowBandWidthMsg: "信号不佳",
      confirmLeaveRoom: "确定要离开房间",
    },
    clientWaiting: {
      clientWaitingMsg: "请稍候，我们的品牌销售大使正在恢复您的预约",
      clientWaitingMsgTitle: "您的预约正在等待",
      confirmLeaveRoom: "确定要离开房间",
    },
    clientNotSupported: {
      browserErrorTitle: "出错了",
      browserError:
        "您的浏览器不支持。请确保浏览器升级到最新版本，或尝试使用其他浏览器。",
      discoverButton: "探索",
      discoverButtonLink: "https://www.debeers.com.cn/zh-cn/home",
      discoverMsg: "欢迎通过我们的网站了解更多信息",
      downloadListedBrowsersMsg: "可通过以下链接，下载推荐浏览器的最新版本",
      updateCurrentBrowser: "请升级您的浏览器",
      IOS_CHROME: "https://apps.apple.com/app/google-chrome/id535886823?l=en",
      IOS_FIREFOX:
        "https://apps.apple.com/app/firefox-private-safe-browser/id989804926?l=en",
      ANDROID_CHROME:
        "https://play.google.com/store/apps/details?id=com.android.chrome&hl=en&gl=US",
      ANDROID_FIREFOX:
        "https://play.google.com/store/apps/details?id=org.mozilla.firefox&hl=en&gl=US",
      DESKTOP_CHROME: "https://www.google.com/chrome/",
      DESKTOP_FIREFOX: "https://www.mozilla.org/firefox/new/",
      DESKTOP_EDGE: "https://www.microsoft.com/edge",
    },
    errorEditCreateEvent: {
      errorCreateStartBeforeNow: "开始时间无效",
      errorInvalidEmail: "电子邮件无效",
      errorMissingFields: "未填写",
    },
    header: {
      adminButton: "管理",
      baName: "{{baFirstName}} {{baLastName}}",
    },
    login: {
      emailField: "电子邮件",
      loginButton: "登录",
      loginInvalid: "电子邮件或密码不正确",
      loginTitle: "虚拟钻石之家（VHoD）",
      pageTitle: "登录",
      passwordField: "密码",
      forgottenPasswordButton: "忘记密码?",
    },
    forgetPassword: {
      emailField: "电子邮件",
      forgetPasswordButton: "发送",
      forgetPasswordTitle: "虚拟钻石之家（VHoD）",
      pageTitle: "忘记密码",
      forgetPasswordSuccess: "我们已向您发送邮件，供您重置密码。",
      forgetPasswordFailed: "发送更改密码的电子邮件时出现错误",
      forgetPasswordDescription:
        "在下面输入您的电子邮件地址，我们会立即向您发送一封电子邮件，供您重置密码",
      returnLoginButton: "返回登录",
    },
    videoCallBar: {
      durationTime: "HH:mm:ss",
      waitingScreenButton: "等待屏幕",
      chatButtonTitle: "交谈",
    },
    cookieConsentComponent: {
      cookieConsentMsg:
        "我们使用cookie确保网站为您提供最佳体验。如果您选择“接受”，即代表您同意从本网站接收cookie。如果您不希望保存cookie，可以点击“拒绝”。如需了解更多信息，请访问我们的",
      cookiePolicyMsg: "Cookie政策",
      cookiePolicyLink: "https://www.debeers.com.cn/zh-cn/privacy-policy.html",
      acceptButton: "接受",
      declineButton: "拒绝",
    },
    footerClient: {
      privacyCookie: "隐私与Cookie政策",
      privacyCookieLink: "https://www.debeers.com.cn/zh-cn/privacy-policy.html",
      termsConditions: "条款与条件",
      termsConditionsLink:
        "https://www.debeers.com.cn/zh-cn/terms-and-conditions.html",
    },
    sharing: {
      title: "您正在共享您的屏幕",
      description: "当前房间内的所有用户都可以看到您的屏幕",
      stop: "停止共享",
    },
    routing: {
      errorBoutiqueNotFound: "您的账户不属于任何精品店，请联系您的品牌销售大使",
      pageNotAvailable: "页面不可用",
      pageNotAvailableForMobile: "页面无法用于移动设备",
    },
    admin: {
      boutiqueUser: "Boutique/User",
      category: "Category",
      videoGroupVideo: "Video Group/Video",
      videoGroupCreationTitle: "Video Group Creation",
      videoGroupName: "Video Group Name",
      videoGroupOrder: "Video Group Order",
      videoGroupPanelTitle: "Video Groups",
      videoPanelTitle: "Videos",
      videoCreationTitle: "Video Creation",
      videoName: "Video Name",
      videoDescription: "Video Description",
      videoOrder: "Video Order",
      videoClientOrder: "Video Client Order",
      videoDuration: "Video Duration",
      video: "Video File",
      videoCover: "Video Cover",
      videoGroup: "Video Group",
      videoGroupSelect: "Select Video Group",
      noVideoGroupOption: "None",
      isOnClientHomeLabel: "Is On Client Page",
      deleteButton: "删除",
      videoEditTitle: "Edit Video",
      videoGroupEditTitle: "Edit Video Group",
      boutiquesPanelTitle: "Boutiques",
      userCreationTitle: "User Creation",
      userEditTitle: "User Edition",
      boutiqueCreationTitle: "Boutique Creation",
      boutiqueEditTitle: "Boutique Edition",
      boutiqueName: "Name",
      boutiqueCity: "City",
      boutiqueCountry: "Country",
      boutiqueSocket: "Socket",
      boutiqueSocketPath: "Socket Path",
      isVisibleLabel: "Is visible",
      usersPanelTitle: "Users",
      userEmail: "电子邮件",
      coverImage: "Cover Image",
      userPassword: "密码",
      userFirstName: "First name",
      userLastName: "Last name",
      boutiqueSelect: "Select Boutique",
      boutique: "Boutique",
      rolesSelect: "Select roles",
      roles: "Roles",
      saveButton: "保存",
      editButton: "保存",
      error_500: "出错了",
      error_missing_field: "未填写",
      rolesInfo: [
        "BA: can create/edit/delete events for himself only in his boutique",
        "BA_MANAGER: can create/edit/delete events for BA in his boutique (himself included)",
        "BA_MOBILE: give the right to start rooms from a mobile device",
        "HQ_GLOBAL: can switch between boutiques and can create/edit/delete events of BA of each boutiques but not himself",
        "ADMIN: can access the admin page",
        "TEST: GA not activated on the user and the user can see test boutiques",
      ],
      boutiqueInfo: ["An user that doesn't have a boutique cannot log-in"],
      filtersButton: "Filters",
      searchFilterTitle: "Search (first name, last name, email)",
      noBoutiqueOption: "None",
      minutes: "分钟",
      addSlot: "add slot",
      deleteSlot: "delete slot",
    },
    errors: {
      networkLost: "您的网络连接状况不佳。网络恢复正常后，系统将自动重新连接。",
    },
    languageSelector: {
      english: "English",
      chineseHK: "繁體中文（香港）",
      chinese: "中文",
      french: "Français",
    },
    moment: {
      months: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
    },
  },
};

export default zhSpecific;
