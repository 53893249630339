import React from "react";
import "./Icon.scss";
import classNames from "classnames";

import IconTypes from "../../types/IconTypes";

const Icon = ({
  icon,
  className = "",
  color = "default",
  size = "default",
}) => {
  const cNames = classNames([
    `fonticon`,
    `fontello-${icon}`,
    `icon-color-${color}`,
    `icon-size-${size}`,
    className,
  ]);

  return <i className={cNames} />;
};

Icon.types = IconTypes;

export default Icon;
