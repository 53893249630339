import { createAction, createReducer } from "redux-act";
import makeImmutable from "../makeImmutable";

const initialState = {
  me: {
    id: "",
  },
  socket: "",
  socketPath: "",
};

export const actions = {
  requestLoadMe: createAction("request load me"),
  loadMeSuccess: createAction("load me success", (me) => ({
    me,
  })),
  setSocketData: createAction("set socket data", (socket, socketPath) => ({
    socket,
    socketPath,
  })),
};

const loadMeSuccess = (state, { me }) => {
  state.me = me;
};

const reducers = {
  [actions.loadMeSuccess]: loadMeSuccess,
  [actions.setSocketData]: (state, { socket, socketPath }) => {
    state.socket = socket;
    state.socketPath = socketPath;
  },
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  me: (state) => state.me.me,
  socket: (state) => state.me.socket,
  socketPath: (state) => state.me.socketPath,
};
