import { createAction, createReducer } from "redux-act";
import makeImmutable from "../makeImmutable";

const initialState = {
  connected: false,
  errorMessage: "",
  socketId: null,
  isGuest: false,
};

export const actions = {
  requestConnect: createAction(
    "request connect",
    (isGuest, socketUrl, socketPath) => ({ isGuest, socketUrl, socketPath })
  ),
  connectSuccess: createAction("connect success"),
  authSuccess: createAction("auth success"),
  connectFail: createAction("connect fail", (errorMessage) => ({
    errorMessage,
  })),
  setError: createAction("set error", (connected, errorMessage) => ({
    connected,
    errorMessage,
  })),
  requestSendAuth: createAction("request send auth", (token) => ({ token })),
  requestSendMessage: createAction(
    "request send message",
    (eventName, data) => ({ eventName, data })
  ),
  onUserJoinLive: createAction("on user join live", (data) => ({
    data,
  })),
  onUserLeftLive: createAction("on user left live", (data) => ({
    data,
  })),
  setSocketId: createAction("set socket id", (socketId) => ({ socketId })),
  gotRoomStatus: createAction("got room status", (status) => status),
  requestSelfMuteAudio: createAction("request self mute audio"),
};

const reducers = {
  [actions.connectSuccess]: (state) => {
    state.connected = true;
    state.errorMessage = "";
  },
  [actions.connectFail]: (state, { errorMessage }) => {
    state.errorMessage = errorMessage;
  },
  [actions.setError]: (state, { connected, errorMessage }) => {
    state.connected = connected;
    state.errorMessage = errorMessage;
  },
  [actions.setSocketId]: (state, { socketId }) => (state.socketId = socketId),
  [actions.requestConnect]: (state, { isGuest }) => (state.isGuest = isGuest),
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  connected: (state) => state.socket.connected,
  errorMessage: (state) => state.socket.errorMessage,
  socketId: (state) => state.socket.socketId,
  isGuest: (state) => state.socket.isGuest,
};
