import React, { useCallback, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./BoutiqueSelect.scss";

import {
  actions as BoutiquesActions,
  selectors as BoutiquesSelectors,
} from "../../redux/boutiques/redux";
import { actions as UsersActions } from "../../redux/users/redux";
import { actions as EventsActions } from "../../redux/events/redux";
import { actions as SocketActions } from "../../redux/socket/redux";
import { selectors as CalendarSelectors } from "../../redux/calendar/redux";
import Bloc from "../../atoms/Bloc/Bloc";
import { Subscribe } from "../../redux/socket/messageTypes";

const BoutiqueSelect = React.memo(() => {
  const dispatch = useDispatch();
  const selectedBoutiqueId = useSelector(BoutiquesSelectors.selectedBoutiqueId);
  const months = useSelector(CalendarSelectors.months);
  const monthIndex = useSelector(CalendarSelectors.monthIndex);
  const panelDate = useSelector(CalendarSelectors.panelDate);
  const panelDateAsDate = useMemo(() => new Date(panelDate), [panelDate]);
  const [prevBoutiqueId, setPrevBoutiqueId] = useState(selectedBoutiqueId);
  const boutiqueIds = useSelector(BoutiquesSelectors.boutiqueIds);
  const boutiqueByIds = useSelector(BoutiquesSelectors.boutiqueByIds);
  const onChange = useCallback(
    (e) => {
      dispatch(BoutiquesActions.setSelectedBoutiqueId(e.target.value));
      dispatch(
        SocketActions.requestSendMessage(Subscribe.SUBSCRIBE_BOUTIQUE_ROOM, {
          prevBoutiqueId,
          boutiqueId: e.target.value,
        })
      );
      setPrevBoutiqueId(e.target.value);
      dispatch(UsersActions.clearAll());
      dispatch(UsersActions.requestLoadUsers());
      dispatch(EventsActions.clearAll());
      const panelDateStartMonth = new Date(
        new Date(panelDateAsDate).getFullYear(),
        new Date(panelDateAsDate).getMonth()
      );
      if (months[monthIndex]) {
        const currentMonthFirstDayShowing = new Date(
          months[monthIndex].firstDayShowing
        ).toJSON();
        const currentMonthEndDayShowing = new Date(
          months[monthIndex].endDayShowing
        ).toJSON();
        dispatch(
          EventsActions.requestLoadEvents(
            currentMonthFirstDayShowing,
            currentMonthEndDayShowing
          )
        );
        if (
          panelDateStartMonth.getTime() !==
          new Date(months[monthIndex].start).getTime()
        ) {
          const panelNextDate = new Date(panelDateAsDate);
          panelNextDate.setDate(panelNextDate.getDate() + 1);
          dispatch(
            EventsActions.requestLoadEvents(
              panelDateAsDate.toJSON(),
              panelNextDate.toJSON()
            )
          );
        }
      }
    },
    [dispatch, months, monthIndex, prevBoutiqueId, panelDateAsDate]
  );

  return (
    <Bloc className="boutique-select-container" mr-s>
      <select
        onChange={onChange}
        defaultValue={selectedBoutiqueId}
        className="boutique-select"
      >
        {boutiqueIds.map((data) => (
          <option key={data} value={data}>
            {`${boutiqueByIds[data].name}`}
          </option>
        ))}
      </select>
    </Bloc>
  );
});

BoutiqueSelect.displayName = "BoutiqueSelect";

export default BoutiqueSelect;
