import { createAction, createReducer } from "redux-act";
import makeImmutable from "../makeImmutable";
import conf from "../../conf";

const initialState = {
  devicesLoaded: false,
  isAllowed: true,
  devicesError: false,
  hasVideoDevice: false,
  hasAudioDevice: false,
  devicesById: {},
  videoDeviceIds: [],
  audioDeviceIds: [],
};

export const actions = {
  requestLoadDevices: createAction("request load devices"),
  setDevices: createAction("set devices", (devices) => ({ devices })),
  setIsAllowed: createAction("set device is allowed", (isAllowed) => ({
    isAllowed,
  })),
  setError: createAction("set error", (error) => ({
    error,
  })),
};

const reducers = {
  [actions.setIsAllowed]: (state, { isAllowed }) => {
    state.devicesLoaded = true;
    state.isAllowed = isAllowed;
  },
  [actions.setError]: (state, { error }) => {
    state.devicesLoaded = true;
    state.devicesError = error;
  },
  [actions.setDevices]: (state, { devices }) => {
    state.devicesLoaded = true;

    const namesCount = {};
    state.hasVideoDevice = devices.some((_) => _.kind === "videoinput");
    state.hasAudioDevice = devices.some((_) => _.kind === "audioinput");
    state.devicesById = devices.reduce((acc, value) => {
      if (value.kind === "audiooutput") return acc;
      let displayName = value.label.replace(/ *\([^)]*\) */g, "");
      if (!namesCount[displayName]) {
        namesCount[displayName] = { firstId: value.deviceId, count: 1 };
      } else {
        acc[namesCount[displayName].firstId].displayName = `${displayName}-1`;
        namesCount[displayName].count++;
        displayName = `${displayName}-${namesCount[displayName].count}`;
      }
      return {
        ...acc,
        [value.deviceId]: {
          ...value,
          displayName,
          key: value.deviceId,
        },
      };
    }, {});

    state.videoDeviceIds = devices
      .filter((device) => device.kind === "videoinput")
      .map((device) => device.deviceId);
    state.audioDeviceIds = devices
      .filter((device) => device.kind === "audioinput")
      .map((device) => device.deviceId);
  },
};

export default createReducer(makeImmutable(reducers), initialState);

const empty = {};

export const selectors = {
  devicesLoaded: (state) => state.devices.devicesLoaded,
  videoDeviceIds: (state) => state.devices.videoDeviceIds,
  audioDeviceIds: (state) => state.devices.audioDeviceIds,
  devices: (state) => state.devices.devicesById,
  device: (state, deviceId) => state.devices.devicesById[deviceId] || empty,
  isAllowed: (state) => state.devices.isAllowed,
  devicesError: (state) => state.devices.devicesError,
  hasVideoDevice: (state) => state.devices.hasVideoDevice,
  hasAudioDevice: (state) => state.devices.hasAudioDevice,
};
