import React, { useMemo } from "react";
import Bloc from "../Bloc/Bloc";
import "./styles.scss";

const PrimaryButton = React.memo(
  ({
    children,
    onClick = () => ({}),
    className = "",
    disabled = false,
    type = "button",
  }) => {
    const finalClassName = useMemo(
      () =>
        `primary-button-container bloc text1-uppercase ti ${
          disabled ? "primary-button-disabled" : "primary-button-actif"
        } ${className}`,
      [disabled, className]
    );
    return (
      <button
        onClick={onClick}
        className={finalClassName}
        disabled={disabled}
        type={type}
      >
        <div className="button-children">{children}</div>
      </button>
    );
  }
);

PrimaryButton.displayName = "PrimaryButton";

export default PrimaryButton;
