import { all, put, takeEvery } from "redux-saga/effects";

import { actions as SocketActions } from "../socket/redux";
import { actions as ChatActions } from "../chat/redux";
import AppSagas from "../app/sagas";
import { Live } from "../socket/messageTypes";
import apolloService from "../../services/ApolloService";
import { GET_USER, GET_USERS } from "./graph";

export default class ChatSagas {
  static chatRoom = null;

  static *requestSendChatMessage({ payload }) {
    const { msg } = payload;
    console.log("requestSendChatMessage", payload);
    ChatSagas.chatRoom.sendMessage(msg);
    // yield put(SocketActions.requestSendMessage(Live.MESSAGE_TO_ALL, payload));
  }

  static *requestLoadUser({ payload }) {
    try {
      const { userId } = payload;
      const result = yield apolloService.query(GET_USER, { userId });
      if (result.ok) {
        yield put(ChatActions.addUser(result.data.user));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestLoadUsers({ payload }) {
    try {
      const { userIds } = payload;
      const result = yield apolloService.query(GET_USERS, {
        filters: { _id: { in: userIds } },
      });
      if (result.ok) {
        yield put(ChatActions.addUsers(result.data.usersAsGuest));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *gotRoomStatus({ payload }) {
    const {} = payload;
    // const users = status.users.filter(_ => _);
    // const userIds = users.map((u) => u._id)
    // yield put(ChatActions.setUsersInRoom(users));
    // yield put(ChatActions.requestLoadUsers(userIds));
  }

  static *loop() {
    yield all([
      takeEvery(
        ChatActions.requestSendChatMessage.getType(),
        ChatSagas.requestSendChatMessage
      ),
      takeEvery(
        ChatActions.requestLoadUser.getType(),
        ChatSagas.requestLoadUser
      ),
      takeEvery(
        ChatActions.requestLoadUsers.getType(),
        ChatSagas.requestLoadUsers
      ),
      takeEvery(SocketActions.gotRoomStatus.getType(), ChatSagas.gotRoomStatus),
    ]);
  }
}
