import React from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import env from "./env";

import "./CookieConsent.scss";
import useAppCookies from "./hooks/useAppCookies";

const CookieConsentComponent = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "cookieConsentComponent",
  });
  const { onAccept, onDecline } = useAppCookies();

  if (env.REACT_APP_ONETRUST_ID) {
    return null;
  }

  return (
    <CookieConsent
      enableDeclineButton={true}
      onAccept={onAccept}
      onDecline={onDecline}
      containerClasses="cookie-consent-container"
      contentClasses="cookie-consent-content"
      buttonWrapperClasses="cookie-button-container"
      buttonClasses="cookie-button"
      declineButtonClasses="cookie-decline-button"
      overlayClasses="cookie-consent-overlay"
      overlay
      disableButtonStyles
      disableStyles
      buttonText={t("acceptButton")}
      declineButtonText={t("declineButton")}
    >
      {t("cookieConsentMsg")}{" "}
      <a
        className="cookie-policy-link"
        href={t("cookiePolicyLink")}
        target="_blank"
        rel="noreferrer"
      >
        {t("cookiePolicyMsg")}
      </a>
    </CookieConsent>
  );
};

export default CookieConsentComponent;
