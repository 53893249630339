import React from "react";
import "./Transition.scss";
import ClientLogo from "../../atoms/ClientLogo/ClientLogo";

export const startTransition = () => {
  const container = document.querySelector(".transition-container");
  container.className = "transition-container transition-in";
};

export const endTransition = () => {
  const container = document.querySelector(".transition-container");
  container.className = "transition-container transition-out";
};

export const hideTransition = () => {
  const container = document.querySelector(".transition-container");
  container.className = "transition-container";
};

const Transition = () => {
  return (
    <div className="transition-container">
      <ClientLogo />
    </div>
  );
};

export default Transition;
