import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectors as AuthSelectors,
  actions as AuthActions,
} from "../../redux/auth/redux";

import { selectors as AppSelectors } from "../../redux/app/redux";
import { useHistory } from "react-router";

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLogged = useSelector(AuthSelectors.logged);
  const appStarted = useSelector(AppSelectors.appStarted);

  useEffect(() => {
    if (appStarted && isLogged) {
      dispatch(AuthActions.logout());
      history.push("/");
    }
  }, []);

  return <></>;
};

export default Logout;
