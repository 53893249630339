import { put, take, cancelled } from "redux-saga/effects";
import { createAction } from "redux-act";
import { eventChannel, END } from "redux-saga";
import peerMap from "../../services/PeerMap";
import createPublisherObject from "../../utils/createPublisherObject";
import { actions as LiveClientActions } from "./redux";
import { isMobile } from "react-device-detect";

export const clientActions = {
  newPublisher: createAction("client/new publisher", (publisher) => ({
    publisher,
  })),
  removePublisher: createAction("client/remove publisher", (publisherId) => ({
    publisherId,
  })),
  updatePublisher: createAction("client/update publisher", (publisher) => ({
    publisher,
  })),
  onJoined: createAction("client/on joined", (publisherId, publisherCount) => ({
    publisherId,
    publisherCount,
  })),
  setPublisherTalking: createAction(
    "client/on talking change",
    (publisherId, isTalking) => ({ publisherId, isTalking })
  ),
  setPublisherBadNetwork: createAction(
    "client/on bad network",
    (publisherId, badNetwork) => ({ publisherId, badNetwork })
  ),
};

export default class JanusWatcher {
  static *listenLocalPeer(socketRoom, localPeer) {
    const channel = eventChannel((emmiter) => {
      localPeer.on("updated", () => {
        emmiter(
          clientActions.updatePublisher(
            createPublisherObject(socketRoom, localPeer)
          )
        );
      });
      return () => {};
    });

    try {
      while (true) {
        let action = yield take(channel);
        yield put(action);
      }
    } catch (e) {
      console.log(e);
    }
  }

  static *listenRooms(socketRoom, janusRoom, socketClient, roomType) {
    const channel = eventChannel((emmiter) => {
      let timeout;
      console.log("_Socket start listening");
      const handleDisconnected = () => {
        console.log("_Socket disconnected");
        emmiter(LiveClientActions.setServiceFailed(true));
      };

      const handleConnected = () => {
        console.log("_Socket connected");
        emmiter(LiveClientActions.setServiceFailed(false));
      };

      socketClient.on("disconnect", handleDisconnected);
      socketClient.on("connect", handleConnected);

      janusRoom.on("janusClosed", () => {
        emmiter(LiveClientActions.setServiceFailed(true));
      });

      janusRoom.on("janusOpened", () => {
        emmiter(LiveClientActions.setServiceFailed(false));
      });

      let lastTalkingPublisherId = null;

      janusRoom.on("newRemotePeer", async (remotePeer) => {
        peerMap.addPeer(remotePeer);

        const localUseVideo =
          roomType === "CONFIDENTIAL_ROOM"
            ? true
            : remotePeer.publisher.displayName == "sellerFace" ||
              remotePeer.publisher.displayName == "sellerProduct" ||
              remotePeer.publisher.displayName == "screenshare" ||
              !isMobile;

        await remotePeer.receive({
          localUseAudio: true,
          localUseVideo,
        });

        remotePeer.on("destroyed", () => {
          emmiter(
            clientActions.removePublisher(remotePeer.publisher.publisherId)
          );
        });

        remotePeer.on("updated", () => {
          emmiter(
            clientActions.updatePublisher(
              createPublisherObject(socketRoom, remotePeer)
            )
          );
        });

        remotePeer.on("talking", (isTalking) => {
          if (
            lastTalkingPublisherId &&
            roomType !== "CONFIDENTIAL_ROOM" &&
            isMobile
          ) {
            emmiter(
              clientActions.setPublisherTalking(lastTalkingPublisherId, false)
            );
          }
          if (isTalking) {
            lastTalkingPublisherId = remotePeer.publisher.publisherId;
          } else if (
            lastTalkingPublisherId === remotePeer.publisher.publisherId
          ) {
            lastTalkingPublisherId = null;
          }

          emmiter(
            clientActions.setPublisherTalking(
              remotePeer.publisher.publisherId,
              isTalking
            )
          );
        });

        remotePeer.on("slowlink.emitter", () => {
          clearTimeout(timeout);
          setTimeout(() => {
            emmiter(
              clientActions.setPublisherBadNetwork(
                remotePeer.publisher.publisherId,
                false
              )
            );
          }, 2000);
          emmiter(
            clientActions.setPublisherBadNetwork(
              remotePeer.publisher.publisherId,
              true
            )
          );
        });
        emmiter(
          clientActions.newPublisher(
            createPublisherObject(socketRoom, remotePeer)
          )
        );
      });

      return () => {
        console.log("_Socket remove listeners");
        socketClient.off("disconnect", handleDisconnected);
        socketClient.off("connect", handleConnected);
        clearTimeout(timeout);
      };
    });

    try {
      while (true) {
        let action = yield take(channel);
        yield put(action);
      }
    } catch (e) {
      console.log(e);
    } finally {
      console.log("_Socket client watcher terminated");
      channel.close();
    }
  }
}
