import { all, call, fork } from "redux-saga/effects";
import AuthSagas from "./auth/sagas";
import AdminSagas from "./admin/sagas";
import MeSagas from "./me/sagas";
import AppSagas from "./app/sagas";
import BoutiquesSagas from "./boutiques/sagas";
import UsersSagas from "./users/sagas";
import EventsSagas from "./events/sagas";
// import LiveBroadcastSagas from "./liveBroadcast/sagas";
import LiveClientSagas from "./liveClient/sagas";
import LiveSellerSagas from "./liveSeller/sagas";
import DevicesSagas from "./devices/sagas";
import ChatSagas from "./chat/sagas";
import SocketSagas from "./socket/sagas";
import initSaga from "./initSaga";

function* loop() {
  yield all([
    AppSagas.loop(),
    AuthSagas.loop(),
    AdminSagas.loop(),
    MeSagas.loop(),
    BoutiquesSagas.loop(),
    UsersSagas.loop(),
    EventsSagas.loop(),
    // LiveBroadcastSagas.loop(),
    LiveClientSagas.loop(),
    LiveSellerSagas.loop(),
    DevicesSagas.loop(),
    ChatSagas.loop(),
    SocketSagas.loop(),
  ]);
  return null;
}

export default function* rootSaga() {
  yield fork(loop);
  yield call(initSaga);
}
