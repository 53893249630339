import { createAction, createReducer } from "redux-act";
import makeImmutable from "../makeImmutable";
import { faceActions } from "./JanusWatcher";

const initialState = {
  prepared: false,
  error: "",
  currentDeviceId: "",
  faceDeviceId: "",
  audioDeviceId: "",
  productCameras: [],
  camOn: true,
  micOn: true,
  status: "UNSET",
  waitingVideoIndex: null,
  joining: false,
  serviceFailed: false,
  productPreviewDeviceId: null,
  videoIds: [],
  videoByIds: [],
  videoGroupByIds: [],
  videoGroupIds: [],
  currentEvent: {
    id: null,
    ownerId: null,
    client: null,
    scheduleDate: null,
    scheduleEndDate: null,
    isLoaded: false,
  },
  nextEvent: {
    id: null,
    client: null,
    scheduleDate: null,
    scheduleEndDate: null,
    isLoaded: false,
  },
  currentRoom: {
    roomId: null,
    janusUrl: "",
    videoRoomId: null,
    scheduleDate: null,
    isLoaded: false,
  },

  publishers: {
    /*
      [publisherId]:{
        publisherId,
        displayName,
        userName
        isTalking
        isLocal
        useAudio
        useVideo
      }
    */
  },
  facePublisherId: null,
  productPublisherId: null,
  screenSharePublisherId: null,
  clientPublisherIds: [],
};

export const actions = {
  setStatus: createAction("set status", (status, waitingVideoIndex) => ({
    status,
    waitingVideoIndex,
  })),
  removeClientPublisher: createAction(
    "remove client publisher",
    (publisherId) => ({ publisherId })
  ),
  clearError: createAction("clear Error"),
  setError: createAction("set error", (error) => ({ error })),
  setJoining: createAction("set joining", (joining) => ({ joining })),
  setCurrentDeviceId: createAction("set current device id", (deviceId) => ({
    deviceId,
  })),
  setFaceDeviceId: createAction("set face device id", (deviceId) => ({
    deviceId,
  })),
  setAudioDeviceId: createAction("set audio device Id", (deviceId) => ({
    deviceId,
  })),
  setCurrentEvent: createAction(
    "set current event",
    (
      eventId,
      ownerId,
      client,
      scheduleDate,
      scheduleEndDate,
      alias,
      roomType
    ) => ({
      eventId,
      ownerId,
      client,
      scheduleDate,
      scheduleEndDate,
      alias,
      roomType,
    })
  ),
  setNextEvent: createAction(
    "set next event",
    (eventId, client, scheduleDate, scheduleEndDate) => ({
      eventId,
      client,
      scheduleDate,
      scheduleEndDate,
    })
  ),
  clearAll: createAction("clear all"),
  requestLoadVideos: createAction("request load videos"),
  loadVideosSuccess: createAction("load all videos success", (videos) => ({
    videos,
  })),
  requestLoadVideoGroups: createAction("load all video groups"),
  loadVideoGroupsSuccess: createAction("load all video groups success", (videoGroups) => ({
    videoGroups,
  })),
  clearStream: createAction("clear stream"),
  clearNextEvent: createAction("clear next event"),
  requestLoadEvent: createAction("request load event", (alias) => ({
    alias,
  })),
  requestLoadNextEvent: createAction("request load next event"),
  requestChangeProductCameraId: createAction(
    "request change product camera id",
    (deviceId, skipStatusChange = false) => ({ deviceId, skipStatusChange })
  ),
  //requestLoadCommingEvents: createAction("request load comming events"),
  requestJoinLive: createAction("request join live"),
  requestKillLive: createAction("request kill live"),
  requestLeaveLive: createAction("request leave live"),
  // requestLoadCurrentRoom: createAction("request load current room"),
  loadCurrentRoomSuccess: createAction(
    "load current room success",
    (roomId, janusUrl, videoRoomId, scheduleDate, startDate) => ({
      roomId,
      janusUrl,
      videoRoomId,
      scheduleDate,
      startDate,
    })
  ),
  setPrepared: createAction("set prepared"),
  requestToggleCam: createAction("request toggle cam"),
  setCamOn: createAction("setCamOn", (isOn) => ({ isOn })),
  requestToggleMic: createAction("request toggle mic"),
  setMicOn: createAction("set mic on", (isOn) => ({ isOn })),
  requestStartWaitingScreen: createAction(
    "request start waiting screen",
    (videoId) => ({ videoId })
  ),

  requestStopWaitingScreen: createAction("request stop waiting screen"),
  setProductCamera: createAction("set product camera", (deviceId, index) => ({
    deviceId,
    index,
  })),
  requestStartClientCamera: createAction(
    "request start client camera",
    (publisherId) => ({
      publisherId,
    })
  ),
  requestToggleFacingMode: createAction(
    "request toggle facing mode",
    (facingMode) => ({ facingMode })
  ),
  setServiceFailed: createAction("set service failed", (serviceFailed) => ({
    serviceFailed,
  })),
  requestReconnect: createAction("request reconnect"),
  requestMutePublisherAudio: createAction(
    "request mute publisher audio",
    (publisherId) => ({ publisherId })
  ),
  requestShareScreen: createAction("request share screen"),
  requestStopShareScreen: createAction("request stop share screen"),
  // updatePublisher:createAction("update publisher", publisher => ({publisher}))
};

const addNewPublisher = (state, { publisher }) => {
  state.publishers[publisher.publisherId] = {
    socketId: publisher.socketId,
    publisherId: publisher.publisherId,
    displayName: publisher.displayName,
    isTalking: false,
    badNetwork: false,
    stream: publisher.stream,
    isLocal: publisher.isLocal,
    userName: publisher.userName,
    useAudio: publisher.useAudio,
    useVideo: publisher.useVideo,
  };

  if (publisher.displayName === "sellerFace") {
    state.facePublisherId = publisher.publisherId;
  } else if (publisher.displayName === "sellerProduct") {
    state.productPublisherId = publisher.publisherId;
  } else if (publisher.displayName == "screenshare") {
    state.screenSharePublisherId = publisher.publisherId;
  } else if (!state.clientPublisherIds.includes(publisher.publisherId)) {
    state.clientPublisherIds.push(publisher.publisherId);
  }
};

const removePublisher = (state, { publisherId }) => {
  delete state.publishers[publisherId];
  if (state.facePublisherId === publisherId) state.facePublisherId = null;
  if (state.productPublisherId === publisherId) state.productPublisherId = null;
  if (state.screenSharePublisherId === publisherId)
    state.screenSharePublisherId = null;
  state.clientPublisherIds = state.clientPublisherIds.filter(
    (id) => id !== publisherId
  );
};

const updatePublisher = (state, { publisher }) => {
  state.publishers[publisher.publisherId] = {
    socketId: publisher.socketId,
    publisherId: publisher.publisherId,
    displayName: publisher.displayName,
    isTalking: publisher.isTalking,
    badNetwork: publisher.badNetwork,
    stream: publisher.stream,
    isLocal: publisher.isLocal,
    userName: publisher.userName,
    useAudio: publisher.useAudio,
    useVideo: publisher.useVideo,
  };
};

const setPublisherTalking = (state, { publisherId, isTalking }) => {
  state.publishers[publisherId].isTalking = isTalking;
};

const setPublisherBadNetwork = (state, { publisherId, badNetwork }) => {
  state.publishers[publisherId].badNetwork = badNetwork;
};

const reducers = {
  [faceActions.newPublisher]: addNewPublisher,
  [faceActions.removePublisher]: removePublisher,
  [faceActions.updatePublisher]: updatePublisher,
  [faceActions.setPublisherTalking]: setPublisherTalking,
  [faceActions.onBadNetwork]: setPublisherBadNetwork,
  [actions.setCurrentDeviceId]: (state, { deviceId }) => {
    state.currentDeviceId = deviceId;
    state.productPreviewDeviceId = deviceId;
  },
  [actions.setFaceDeviceId]: (state, { deviceId }) => {
    state.faceDeviceId = deviceId;
  },
  [actions.setAudioDeviceId]: (state, { deviceId }) => {
    state.audioDeviceId = deviceId;
  },
  [actions.setError]: (state, { error }) => {
    state.error = error;
  },
  [actions.setJoining]: (state, { joining }) => {
    state.joining = joining;
  },
  [actions.loadVideosSuccess]: (state, { videos }) => {
    state.videoIds = [];
    videos.sort((a, b) => a.videoGroup.order - b.videoGroup.order).map((video) => {
      state.videoByIds[video.id] = video;
      state.videoIds.push(video.id);
    });
    if (state.waitingVideoIndex === null)
      state.waitingVideoIndex = videos.filter((video) => video.order === 1)[0]?.id
  },
  [actions.loadVideoGroupsSuccess]: (state, { videoGroups }) => {
    state.videoGroupIds = [];
    videoGroups.sort((a, b) => a.order - b.order).map((videoGroup) => {
      state.videoGroupByIds[videoGroup.id] = videoGroup;
      state.videoGroupIds.push(videoGroup.id);
    });
  },
  [actions.setCurrentEvent]: (
    state,
    { eventId, ownerId, client, scheduleDate, scheduleEndDate, alias, roomType }
  ) => {
    state.currentEvent.id = eventId;
    state.currentEvent.ownerId = ownerId;
    state.currentEvent.client = client;
    state.currentEvent.scheduleDate = scheduleDate;
    state.currentEvent.scheduleEndDate = scheduleEndDate;
    state.currentEvent.alias = alias;
    state.currentEvent.isLoaded = true;
    state.currentEvent.roomType = roomType;
  },
  [actions.setNextEvent]: (
    state,
    { eventId, client, scheduleDate, scheduleEndDate }
  ) => {
    state.nextEvent.id = eventId;
    state.nextEvent.client = client;
    state.nextEvent.scheduleDate = scheduleDate;
    state.nextEvent.scheduleEndDate = scheduleEndDate;
    state.nextEvent.isLoaded = true;
  },
  [actions.clearAll]: (state) => {
    const keys = Object.keys(state);
    keys.forEach((key) => {
      state[key] = initialState[key];
    });
  },
  [actions.clearNextEvent]: (state) => {
    state.nextEvent.id = null;
    state.nextEvent.client = null;
    state.nextEvent.scheduleDate = null;
    state.nextEvent.scheduleEndDate = null;
    state.nextEvent.isLoaded = false;
  },
  [actions.loadCurrentRoomSuccess]: (state, currentRoom) => {
    state.currentRoom = currentRoom;
    state.currentRoom.isLoaded = true;
  },
  [actions.setPrepared]: (state) => {
    state.prepared = true;
  },
  [actions.setCamOn]: (state, { isOn }) => {
    state.camOn = isOn;
  },

  [actions.setMicOn]: (state, { isOn }) => {
    state.micOn = isOn;
  },
  [actions.clearError]: (state) => {
    state.error = "";
  },
  [actions.setStatus]: (state, { status, waitingVideoIndex }) => {
    state.status = status;
    if (waitingVideoIndex !== undefined && waitingVideoIndex !== null) {
      state.waitingVideoIndex = waitingVideoIndex;
    }
  },
  [actions.setProductCamera]: (state, { deviceId, index }) => {
    state.productCameras[index] = deviceId;
  },
  [actions.setServiceFailed]: (state, { serviceFailed }) => {
    state.serviceFailed = serviceFailed;
  },
  [actions.clearStream]: (state) => {
    state.publishers = {};
    state.clientPublisherIds = [];
    state.productPublisherId = null;
    state.facePublisherId = null;
    state.screenSharePublisherId = null;
    state.error = "";
    state.joining = false;
  },
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  currentRoom: (state) => state.liveSeller.currentRoom,
  currentDeviceId: (state) => state.liveSeller.currentDeviceId,
  faceDeviceId: (state) => state.liveSeller.faceDeviceId,
  audioDeviceId: (state) => state.liveSeller.audioDeviceId,
  currentEvent: (state) => state.liveSeller.currentEvent,
  nextEvent: (state) => state.liveSeller.nextEvent,
  facePublisherId: (state) => state.liveSeller.facePublisherId,
  productPublisherId: (state) => state.liveSeller.productPublisherId,
  publisherById: (state, publisherId) =>
    state.liveSeller.publishers?.[publisherId],
  clientPublisherIds: (state) => state.liveSeller.clientPublisherIds,
  prepared: (state) => state.liveSeller.prepared,
  error: (state) => state.liveSeller.error,
  joining: (state) => state.liveSeller.joining,
  camOn: (state) => state.liveSeller.camOn,
  micOn: (state) => state.liveSeller.micOn,
  status: (state) => state.liveSeller.status,
  waitingVideoIndex: (state) => state.liveSeller.waitingVideoIndex,
  productCamera: (state, index) =>
    state.liveSeller.productCameras?.[index] || null,
  productCameras: (state) => state.liveSeller.productCameras,
  serviceFailed: (state) => state.liveSeller.serviceFailed,
  productPreviewDeviceId: (state) => state.liveSeller.productPreviewDeviceId,
  screenSharePublisherId: (state) => state.liveSeller.screenSharePublisherId,
  videoIds: (state) => state.liveSeller.videoIds,
  videoByIds: (state) => state.liveSeller.videoByIds,
  videoGroupIds: (state) => state.liveSeller.videoGroupIds,
  videoGroupByIds: (state) => state.liveSeller.videoGroupByIds,
};
