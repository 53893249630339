import { createAction, createReducer } from "redux-act";
import makeImmutable from "../makeImmutable";

const initialState = {
  months: [],
  monthIndex: 0,
  panelDate: new Date(new Date().setHours(0, 0, 0, 0)).toJSON(),
};

export const actions = {
  setMonths: createAction("set months", (months) => ({
    months,
  })),
  setMonthIndex: createAction("set month index", (monthIndex) => ({
    monthIndex,
  })),
  setPanelDate: createAction("set panel date", (panelDate) => ({
    panelDate,
  })),
};

const reducers = {
  [actions.setMonths]: (state, { months }) => {
    state.months = months;
  },
  [actions.setMonthIndex]: (state, { monthIndex }) => {
    state.monthIndex = monthIndex;
  },
  [actions.setPanelDate]: (state, { panelDate }) => {
    state.panelDate = panelDate;
  },
};

export default createReducer(makeImmutable(reducers), initialState);

export const selectors = {
  months: (state) => state.calendar.months,
  monthIndex: (state) => state.calendar.monthIndex,
  panelDate: (state) => state.calendar.panelDate,
};
