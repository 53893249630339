import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Bloc from "../../atoms/Bloc/Bloc";
import PrimaryButton from "../../atoms/PrimaryButton";
import env from "../../env";
import { selectors as AuthSelectors } from "../../redux/auth/redux";
import Loader from "../Loader/Loader";
import "./styles.scss";

const ReportButton = (type, name) => {
  const token = useSelector(AuthSelectors.token);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation("translation", {
    keyPrefix: "report",
  });
  const getReport = useCallback(async () => {
    try {
      setLoading(true);
      const bearer = `${token.tokenType} ${token.idToken}`;
      const result = await fetch(`${env.IMAGE_API}/report/${type}`, {
        method: "GET",
        headers: {
          Authorization: bearer,
        },
      });
      if (!result.ok) throw new Error("failed to get report");
      const blob = await result.blob();
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = `${type}-report-vhod-${new Date().getDate()}-${new Date().getMonth()}-${new Date().getFullYear()}.xlsx`;
      alink.click();
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }, [token, setLoading, setError, type]);
  if (error) return <Bloc ti>{error}</Bloc>;
  if (loading) return <Loader />;
  return (
    <PrimaryButton onClick={getReport} className="report-button">
      {t(name)}
    </PrimaryButton>
  );
};

const ReportButtonWeekly = () =>
  ReportButton("appointments", "GET_REPORT_APPOINTMENTS");
const ReportButtonGlobal = () => ReportButton("global", "GET_REPORT_GLOBAL");

export { ReportButtonWeekly, ReportButtonGlobal };
