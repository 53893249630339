import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Sentry from "@sentry/react";

import { actions as AuthActions } from "./../../redux/auth/redux";
import { useMeRole } from "../../redux/me/hooks";

import "./MobileBAContainer.scss";
import Bloc from "../../atoms/Bloc/Bloc";
import Icon from "../../atoms/Icon/Icon";
import IconButton from "../../atoms/IconButton/IconButton";
import BoutiqueSelect from "../../components/BoutiqueSelect/BoutiqueSelect";
import ClientLogo from "../../atoms/ClientLogo/ClientLogo";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";

const MobileBAContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { isHqGlobal } = useMeRole();

  const handleLogout = useCallback(() => {
    dispatch(AuthActions.logout());
  }, [dispatch]);

  useEffect(() => {
    const handlePersistAuthLoggout = ({ key, newValue }) => {
      if (key === "persist:root") {
        const auth = JSON.parse(newValue)?.auth;
        if (auth && JSON.parse(auth)?.logged === false) {
          handleLogout();
        }
      }
    };
    window.addEventListener("storage", handlePersistAuthLoggout);
    return () => {
      window.removeEventListener("storage", handlePersistAuthLoggout);
    };
  }, [handleLogout]);

  return (
    <div className="mobile-ba-container">
      <Bloc className="header" bg-1 pl-s pr-s>
        <Bloc className="start-container" flex1>
          <LanguageSelector />
        </Bloc>
        <Bloc className="logo-container">
          <Link to={"/"} className="logo-link">
            <ClientLogo className="logo" />
          </Link>
        </Bloc>
        <Bloc className="end-container" flex1>
          {isHqGlobal && <BoutiqueSelect />}
          <IconButton
            className="log-out-icon"
            color="invert"
            size="large"
            icon={Icon.types.LOG_OUT}
            onClick={handleLogout}
          />
        </Bloc>
      </Bloc>
      <div className="content-container">
        <Sentry.ErrorBoundary
          fallback={<Bloc text-title1>An error has occurred</Bloc>}
        >
          {children}
        </Sentry.ErrorBoundary>
      </div>
    </div>
  );
};

export default MobileBAContainer;
