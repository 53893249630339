import React from "react";
import { useEffect, useRef, useCallback } from "react";
import "./CameraPreview.scss";
import classNames from "classnames";
import { isMobile } from "react-device-detect";
import Loader from "../Loader/Loader";

// eslint-disable-next-line react/prop-types
const CameraPreview = ({
  deviceId,
  onClick,
  className = "",
  mirror = false,
  facingMode = "user",
  hasRedDot = false,
}) => {
  const videoRef = useRef(null);
  const stream = useRef(null);

  useEffect(() => {
    if (deviceId == null) {
      return () => null;
    }

    let deviceSpec = isMobile
      ? {
          facingMode: { exact: facingMode },
          width: undefined,
          height: undefined,
        }
      : { deviceId: { exact: deviceId } };

    if (deviceId === "screen") {
      navigator.mediaDevices.getDisplayMedia({ video: true }).then((s) => {
        stream.current = s;
        videoRef.current.srcObject = stream.current;
        videoRef.current.play().catch((e) => console.warn(e));
      });
    } else {
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            width: { min: 320, ideal: 1920, max: 1920 },
            height: { min: 240, ideal: 1080, max: 1080 },
            ...deviceSpec,
            frameRate: 15,
          },
        })
        .then((s) => {
          stream.current = s;
          videoRef.current.muted = true;
          videoRef.current.defaultMuted = true;
          videoRef.current.srcObject = stream.current;
          videoRef.current.play().catch((e) => {
            console.warn(e);
          });
        })
        .catch((e) => {
          console.log("fail to display preview", deviceId);
        });
    }

    return () => {
      if (stream.current) {
        stream.current.getTracks().forEach((track) => track.stop());
        stream.current = null;
      }
    };
  }, [deviceId]);

  const handleClick = useCallback(() => {
    if (!onClick) return;
    onClick(deviceId);
  }, [deviceId, onClick]);

  if (!deviceId) {
    return (
      <div className={classNames("camera-preview", className)}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={classNames("camera-preview", className)}>
      <video
        className={mirror ? "mirror" : ""}
        onClick={handleClick}
        ref={videoRef}
        muted
        playsInline
      />
      {hasRedDot && (
        <div className="red-dot-container">
          <div className="red-dot" />
        </div>
      )}
    </div>
  );
};

export default CameraPreview;
