import {
  combineReducers,
  // applyMiddleware,
  // createStore,
  configureStore,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as Sentry from "@sentry/react";

import liveSeller from "./liveSeller/redux";
import auth from "./auth/redux";
import app from "./app/redux";
import admin from "./admin/redux";
import me from "./me/redux";
import boutiques from "./boutiques/redux";
import users from "./users/redux";
import calendar from "./calendar/redux";
import events from "./events/redux";
import devices from "./devices/redux";
import rootSagas from "./sagas";
import liveClient from "./liveClient/redux";
// import liveBroadcast from "./liveBroadcast/redux";
import chat from "./chat/redux";
import socket from "./socket/redux";
import networkSpeed from "./networkSpeed/redux";
import getConfig from "../conf/persist";

const rootReducer = combineReducers({
  auth,
  app,
  admin,
  me,
  calendar,
  boutiques,
  liveClient,
  // liveBroadcast,
  liveSeller,
  users,
  events,
  devices,
  chat,
  socket,
  networkSpeed,
});

let sagaMiddleware = createSagaMiddleware();
// const middlewares = [sagaMiddleware];

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const persistedReducer = persistReducer(
  getConfig(storage).options,
  rootReducer
);

//export const store = createStore(reducer, applyMiddleware(...middlewares));

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    sagaMiddleware,
  ],
  enhancers: [sentryReduxEnhancer],
});
export const persistor = persistStore(store);

sagaMiddleware.run(rootSagas);
