export const checkWebRTCSupported = () => {
  try {
    if (window.RTCPeerConnection) {
      const peer = new window.RTCPeerConnection();
      if (!peer.addStream && !peer.addTrack) return false;
    } else return false;

    if (!window.MediaStream) return false;
    if (!window.RTCSessionDescription) return false;
    if (!window.MediaStreamTrack) return false;

    if (navigator.mediaDevices) {
      const mediaDevices = navigator.mediaDevices;
      if (!mediaDevices.enumerateDevices || !mediaDevices.getUserMedia)
        return false;
    } else return false;
    return true;
  } catch {
    return false;
  }
};
