import gql from "graphql-tag";

export const eventsFragment = gql`
  fragment EventsInfo on Event {
    id
    scheduleDate
    scheduleEndDate
    alias
    name
    client {
      mail
      fullName
    }
    ownerId
    room {
      endDate
    }
    roomType
  }
`;

export const CREATE_EVENT = gql`
  mutation ($event: EventInput!) {
    createEvent(event: $event) {
      ...EventsInfo
    }
  }
  ${eventsFragment}
`;

export const UPDATE_EVENT = gql`
  mutation ($id: ID!, $event: EventInput!) {
    updateEvent(id: $id, event: $event) {
      ...EventsInfo
    }
  }
  ${eventsFragment}
`;

export const DELETE_EVENT = gql`
  mutation ($id: ID!) {
    removeEvent(id: $id)
  }
`;

export const CREATE_ROOM_FROM_EVENT = gql`
  mutation CreateRoomFromEvent(
    $eventId: ID!
    $socketUrl: String!
    $socketPath: String!
  ) {
    createRoomFromEvent(
      eventId: $eventId
      socketUrl: $socketUrl
      socketPath: $socketPath
    ) {
      id
    }
  }
`;

export const LIST_EVENTS = gql`
  query ListEvents($pagination: Pagination, $filters: EventFilters!) {
    events(pagination: $pagination, filters: $filters) {
      ...EventsInfo
    }
  }
  ${eventsFragment}
`;

export const LOAD_EVENT = gql`
  query LoadEvent($id: ID!) {
    event(id: $id) {
      ...EventsInfo
    }
  }
  ${eventsFragment}
`;
