import gql from "graphql-tag";

export const LOAD_EVENTS = gql`
  query LoadEvent($pagination: Pagination, $filters: EventFilters!) {
    events(pagination: $pagination, filters: $filters) {
      id
      ownerId
      scheduleDate
      scheduleEndDate
      roomType
      client {
        fullName
      }
    }
  }
`;

export const LOAD_EVENT_BY_ALIAS = gql`
  query LoadEventByAlias($alias: String!) {
    eventByAlias(alias: $alias) {
      id
      ownerId
      alias
      scheduleDate
      scheduleEndDate
      roomType
      client {
        fullName
      }
      owner {
        userName
      }
      room {
        endDate
      }
    }
  }
`;

export const LOAD_EVENT_ROOM = gql`
  query LoadEventRoom($eventId: ID!) {
    event(id: $eventId) {
      ownerId
      roomType
      room {
        id
        videoRoomId
        janusUrl
        endDate
        ownerId
        ownerIsLive
        socketUrl
        socketPath
      }
    }
  }
`;

export const LIST_VIDEOS = gql`
  query ListVideos($lang: String!, $filters: VideoFilters!) {
    videos(filters: $filters) {
      name(lang: $lang)
      description(lang: $lang)
      order
      videoUrl(lang: $lang)
      coverUrl
      duration
      isOnClientHome
      videoGroup {
        name(lang: $lang)
        order
        id
      }
      id
    }
  }
`;

export const LIST_VIDEO_GROUPS = gql`
  query ListVideoGroups($lang: String!, $filters: VideoGroupFilters!) {
    videoGroups(filters: $filters) {
      name(lang: $lang)
      order
      id
      isVisible
    }
  }
`;

export const CREATE_ROOM_FROM_EVENT = gql`
  mutation CreateRoomFromEvent(
    $eventId: ID!
    $socketUrl: String!
    $socketPath: String!
  ) {
    createRoomFromEvent(
      eventId: $eventId
      socketUrl: $socketUrl
      socketPath: $socketPath
    ) {
      id
      videoRoomId
      scheduleDate
      janusUrl
      startDate
      ownerIsLive
      socketUrl
      socketPath
    }
  }
`;

export const CLOSE_ROOM = gql`
  mutation CloseRoom($id: ID!) {
    closeRoom(id: $id) {
      id
    }
  }
`;
