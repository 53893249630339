const enSpecific = {
  translation: {
    baEnd: {
      baRoomEndMsg: "Thank you for sharing your diamond expertise!\n",
      baRoomEndTitle: "Virtual Home of Diamonds",
    },
    baPrepare: {
      baPrepareChecklist: [
        "1. Your camera is on",
        "2. Your microphone is on ",
        "3. The lights are on",
        "4. The jewellery selection & props are ready ",
        "5. Debeers.com is open",
        "6. Familiarise yourself with our De Beers video clips while in the waiting screen",
      ],
      baPrepareTitle: "Virtual Home of Diamonds",
    },
    clientEnd: {
      clientRoomEndMsg:
        "Thank you for your time. We hope that you enjoyed our Virtual Home of Diamonds experience.",
      clientRoomEndTitle: "Virtual Home of Diamonds",
      discoverButtonLink: "https://www.debeers.co.uk/en-gb/home",
    },
    clientPrepare: {
      clientPrepareTitle: "Virtual Home of Diamonds",
      liveCamTitle: "Live from the De Beers Diamond Route",
    },
    clientNotSupported: {
      discoverButtonLink: "https://www.debeers.co.uk/en-gb/home",
    },
    login: {
      loginTitle: "Virtual Home of Diamonds",
    },
    selectCustom: {
      CONFIDENTIAL_ROOM: "Virtual Home of Diamonds",
      CONFERENCE_ROOM: "Live Event",
    },
    report: {
      GET_REPORT_APPOINTMENTS: "Weekly report",
      GET_REPORT_GLOBAL: "Global report",
    },
    forgetPassword: {
      forgetPasswordTitle: "Virtual Home of Diamonds",
    },
    cookieConsentComponent: {
      cookiePolicyLink: "https://www.debeers.co.uk/en-gb/privacy-policy.html",
    },
    footerClient: {
      privacyCookieLink: "https://www.debeers.co.uk/en-gb/privacy-policy.html",
      termsConditionsLink:
        "https://www.debeers.co.uk/en-gb/terms-and-conditions.html",
    },
  },
};

export default enSpecific;
