import produce from "immer";

const mutate = (mutator) => {
  return (state, payload) => {
    return produce(state, (draftState) => {
      mutator(draftState, payload);
    });
  };
};

const makeImmutable = (reducers) => {
  const keys = Object.keys(reducers);
  const result = {};
  keys.forEach((key) => {
    result[key] = mutate(reducers[key]);
  });
  return result;
};

export default makeImmutable;
