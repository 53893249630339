/* eslint-disable no-unused-vars */
import {
  all,
  takeLatest,
  put,
  takeLeading,
  takeEvery,
  select,
  take,
  spawn,
  fork,
  cancel,
  cancelled,
  call,
  race,
} from "redux-saga/effects";
import { isMobile, isMobileOnly } from "react-device-detect";

import {
  actions as LiveSellerActions,
  selectors as LiveSellerSelectors,
} from "./redux";
import { selectors as AppSelectors } from "../../redux/app/redux";
import { selectors as NetworkSpeedSelectors } from "../networkSpeed/redux";
import { selectors as DevicesSelectors } from "../devices/redux";
import ChatWatcher from "../chat/ChatWatcher";

import {
  actions as SocketActions,
  selectors as SocketSelectors,
} from "../socket/redux";
import { actions as MeActions, selectors as MeSelectors } from "../me/redux";
import { selectors as AuthSelectors } from "../auth/redux";
import ApolloService from "../../services/ApolloService";
import {
  LOAD_EVENTS,
  LOAD_EVENT_BY_ALIAS,
  LOAD_EVENT_ROOM,
  CREATE_ROOM_FROM_EVENT,
  CLOSE_ROOM,
  LIST_VIDEOS,
  LIST_VIDEO_GROUPS,
} from "./graph";
import { getSocket, getManager } from "../../services/Socket";

import JanusWatcher, { faceActions, productActions } from "./JanusWatcher";
import { Live } from "../socket/messageTypes";
import AppSagas from "../app/sagas";
import { TIME } from "../../utils/const";
import env from "../../env";
import ChatSagas from "../chat/sagas";
import createPublisherObject from "../../utils/createPublisherObject";
import { actions as AppActions } from "../app/redux";

import {
  JanusRoom,
  Room,
  ChatRoom,
  UserMediaController,
  ScreenShareController,
} from "janus-front-sdk";
import peerMap from "../../services/PeerMap";

const { NEXT_EVENT_INTERVAL_MIN } = TIME;

let createJanus = null;
// let janusFace = null;
let roomFace = null;
// let janusProduct = null;
let roomProduct = null;

let socketRoom = null;
let janusRoom = null;
let productPeer = null;
let facePeer = null;
let screenSharePeer = null;

let faceMediaController = null;
let productMediaController = null;
let screenShareController = null;

const audioSettings = {
  echoCancellation: true,
  noiseSuppression: true,
  autoGainControl: true,
  channelCount: { exact: 1 },
  sampleRate: { exact: 48000 },
  sampleSize: { exact: 16 },
  advanced: [
    { googEchoCancellation: { exact: true } },
    { googExperimentalEchoCancellation: { exact: true } },
    { autoGainControl: { exact: true } },
    { noiseSuppression: { exact: true } },
    { googHighpassFilter: { exact: true } },
    { googAudioMirroring: { exact: true } },
  ],
};

const delay = (d) => {
  return new Promise((resolve) => {
    setTimeout(resolve, d);
  });
};

export function* getIceServer(bearer) {
  const result = yield fetch(`${env.IMAGE_API}/ice-server`, {
    method: "GET",
    cache: "no-cache",
    headers: {
      Authorization: bearer,
      "content-type": "application/json",
    },
  });

  if (result.status === 200 && result.ok === true) {
    const resultData = yield result.json();
    return resultData;
  } else {
    const resultData = yield result.text();
    throw resultData;
  }
}

export default class LiveSellerSagas {
  static *requestLoadEvent({ payload }) {
    try {
      const { alias } = payload;
      const eventResult = yield ApolloService.query(LOAD_EVENT_BY_ALIAS, {
        alias,
      });
      if (eventResult.ok && eventResult.data.eventByAlias) {
        const me = yield select(MeSelectors.me);
        if (me.id !== eventResult.data.eventByAlias.ownerId) {
          yield put(LiveSellerActions.setError("errorOwner"));
        } else if (eventResult.data.eventByAlias.room?.endDate) {
          yield put(LiveSellerActions.setError("errorRoomClosed"));
        } else {
          yield put(
            LiveSellerActions.setCurrentEvent(
              eventResult.data.eventByAlias.id,
              eventResult.data.eventByAlias.ownerId,
              eventResult.data.eventByAlias.client,
              eventResult.data.eventByAlias.scheduleDate,
              eventResult.data.eventByAlias.scheduleEndDate,
              eventResult.data.eventByAlias.alias,
              eventResult.data.eventByAlias.roomType
            )
          );
        }
      } else {
        yield put(LiveSellerActions.setError("errorNotFound"));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *setServiceFailed({ payload }) {
    const { serviceFailed } = payload;
    if (serviceFailed) {
      console.log("x leave room");
      try {
        yield call(LiveSellerSagas.onHardLeave);
        yield socketRoom.leave();
        console.log("x leave done");
      } catch (e) {
        console.log("x leave errror", e);
      }
      yield put(LiveSellerActions.clearStream());

      socketRoom = null;
    }
  }

  static *requestReconnect() {
    window.removeEventListener("beforeunload", LiveSellerSagas.onHardLeave);
    yield put(SocketActions.requestSendAuth(ApolloService.getToken()));
    yield take(SocketActions.authSuccess.getType());
    yield put(LiveSellerActions.requestJoinLive());
  }

  static *requestLoadNextEvent() {
    try {
      const { id } = yield select(LiveSellerSelectors.currentEvent);
      yield put(LiveSellerActions.clearNextEvent());
      const now = new Date();
      const eventResult = yield ApolloService.query(LOAD_EVENTS, {
        pagination: { limit: 1, sort: { field: "scheduleDate", order: "ASC" } },
        filters: {
          _id: { nin: [id] },
          and: [
            { scheduleDate: { gt: now.toJSON() } },
            {
              scheduleDate: {
                lte: new Date(
                  now.getTime() + NEXT_EVENT_INTERVAL_MIN * 60 * 1000
                ).toJSON(),
              },
            },
          ],
        },
      });
      if (eventResult.ok && eventResult.data.events.length) {
        yield put(
          LiveSellerActions.setNextEvent(
            eventResult.data.events[0].id,
            eventResult.data.events[0].client,
            eventResult.data.events[0].scheduleDate,
            eventResult.data.events[0].scheduleEndDate
          )
        );
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestJoinLive() {
    console.log("CALL REQUEST JOIN LIVE");
    const joiningTask = yield fork(LiveSellerSagas.doRequestJoinLive);
    yield race([
      take(LiveSellerActions.requestReconnect.getType()),
      take(LiveSellerActions.requestLeaveLive.getType()),
    ]);
    // yield take(LiveSellerActions.requestLeaveLive.getType());
    yield cancel(joiningTask);
  }

  static *doRequestJoinLive() {
    try {
      console.log("FORK DO JOIN LIVE");
      yield put(LiveSellerActions.setJoining(true));
      const { id: eventId, alias } = yield select(
        LiveSellerSelectors.currentEvent
      );
      const eventRoomResult = yield ApolloService.query(LOAD_EVENT_ROOM, {
        eventId,
      });

      if (eventRoomResult.ok) {
        const me = yield select(MeSelectors.me);
        if (me.id !== eventRoomResult.data.event.ownerId) {
          return yield put(LiveSellerActions.setError("errorOwner"));
        }
      } else {
        return yield put(LiveSellerActions.setError("errorNotFound"));
      }

      let room = eventRoomResult.data.event.room;
      if (room === null) {
        const socket = yield select(MeSelectors.socket);
        const socketPath = yield select(MeSelectors.socketPath);
        const createRoomResult = yield ApolloService.mutate(
          CREATE_ROOM_FROM_EVENT,
          !socket
            ? { eventId, socketUrl: env.SOCKET, socketPath: env.SOCKET_PATH }
            : { eventId, socketUrl: socket, socketPath: socketPath }
        );

        if (!createRoomResult.ok) return;
        yield put(AppActions.reportEvent("obsba", "firstJoinRoom"));
        room = createRoomResult.data.createRoomFromEvent;
      } else {
        yield put(AppActions.reportEvent("obsba", "joinRoom"));
      }

      if (room === null) return;
      if (room.endDate)
        return yield put(LiveSellerActions.setError("errorRoomClosed"));

      const connected = yield select(SocketSelectors.connected);
      if (!connected) yield take(SocketActions.connectSuccess.getType());

      const devices = yield select(DevicesSelectors.devices);
      let deviceList = [];
      for (let deviceId in devices) {
        deviceList.push(devices[deviceId]?.displayName);
      }

      yield put(
        LiveSellerActions.loadCurrentRoomSuccess(
          room.id,
          room.janusUrl,
          room.videoRoomId,
          room.scheduleDate,
          room.startDate
        )
      );

      socketRoom = new Room(getSocket(), alias);
      try {
        yield socketRoom.join();
      } catch (error) {
        console.log(error);
        if (error?.message === "BA inside")
          return yield put(LiveSellerActions.setError("errorOwnerInside"));
        throw error;
      }
      ChatSagas.chatRoom = new ChatRoom(socketRoom);
      yield fork(ChatWatcher.listenChat, socketRoom, ChatSagas.chatRoom);
      yield ChatSagas.chatRoom.join();

      const token = yield select(AuthSelectors.token);
      const iceServer = yield getIceServer(
        `${token.tokenType} ${token.idToken}`
      );
      janusRoom = new JanusRoom(socketRoom, {
        iceServers: [iceServer.iceServers],
      });
      yield fork(JanusWatcher.listenRooms, socketRoom, janusRoom, getSocket());
      yield janusRoom.join();

      facePeer = janusRoom.createLocalPeer();
      productPeer = janusRoom.createLocalPeer();

      const faceDeviceId = yield select(LiveSellerSelectors.faceDeviceId);
      const currentDeviceId = yield select(LiveSellerSelectors.currentDeviceId);

      const audioDeviceId = yield select(LiveSellerSelectors.audioDeviceId);

      const faceConstraints = {
        video: {
          width: { min: 320, ideal: 854, max: 1920 },
          height: { min: 240, ideal: 480, max: 1080 },
          deviceId: { exact: faceDeviceId },
        },
        audio: { ...audioSettings, deviceId: { exact: audioDeviceId } },
      };
      // const faceStream = yield navigator.mediaDevices.getUserMedia(
      //   faceConstraints
      // );

      if (!isMobile) {
        faceMediaController = new UserMediaController(facePeer);

        yield faceMediaController.publish(
          faceConstraints,
          "sellerFace",
          true,
          true,
          "MEDIUM"
        );
        peerMap.addPeer(facePeer);

        yield put(
          faceActions.newPublisher(createPublisherObject(socketRoom, facePeer))
        );
      }

      const productContraints = isMobile
        ? { video: { facingMode: { exact: "user" } }, audio: true }
        : {
            video: {
              width: { min: 320, ideal: 1920, max: 1920 },
              height: { min: 240, ideal: 1080, max: 1080 },
              deviceId: { exact: currentDeviceId },
              frameRate: 15,
            },
            audio: false,
          };

      productMediaController = new UserMediaController(productPeer);
      yield productMediaController.publish(
        productContraints,
        "sellerProduct",
        isMobile,
        true,
        "HIGH"
      );

      yield put(
        faceActions.newPublisher(createPublisherObject(socketRoom, productPeer))
      );

      peerMap.addPeer(productPeer);

      const hasMicOn = yield select(LiveSellerSelectors.micOn);
      if (!hasMicOn) {
        const mediaController = isMobile
          ? productMediaController
          : faceMediaController;
        yield mediaController.handleUseAudio(hasMicOn);
      }

      yield put(LiveSellerActions.setJoining(false));
      window.addEventListener("beforeunload", LiveSellerSagas.onHardLeave);
    } catch (e) {
      yield put(LiveSellerActions.setError("error_500"));
      AppSagas.reportError(e);
    } finally {
      console.log("joining ended");
      if (yield cancelled()) {
        console.log("joining canceled");
      }
    }
  }

  static *requestShareScreen() {
    const constraints = {
      video: {
        cursor: "always",
      },
      audio: false,
    };

    try {
      screenSharePeer = janusRoom.createLocalPeer();

      screenShareController = new ScreenShareController(screenSharePeer);
      yield screenShareController.publish(constraints, "screenshare");
      peerMap.addPeer(screenSharePeer);
      yield put(
        faceActions.newPublisher(
          createPublisherObject(socketRoom, screenSharePeer)
        )
      );

      const shareTask = yield fork(
        JanusWatcher.listenScreenShare,
        screenShareController.stream
      );

      yield race([
        take(LiveSellerActions.requestStopShareScreen.getType()),
        take(LiveSellerActions.requestLeaveLive.getType()),
      ]);

      yield cancel(shareTask);
    } catch (e) {}
  }

  static *requestStopShareScreen() {
    const screenSharePublisherId = yield select(
      LiveSellerSelectors.screenSharePublisherId
    );

    yield screenShareController.unpublish();
    screenSharePeer = null;
    screenShareController = null;
    yield put(faceActions.removePublisher(screenSharePublisherId));
  }

  static *requestChangeProductCameraId({ payload }) {
    const { deviceId, skipStatusChange } = payload;
    try {
      const currentDeviceId = yield select(LiveSellerSelectors.currentDeviceId);
      if (currentDeviceId === deviceId) return;
      yield put(LiveSellerActions.setCurrentDeviceId(deviceId));

      const newContraints = {
        video: {
          width: { min: 320, ideal: 1920, max: 1920 },
          height: { min: 240, ideal: 1080, max: 1080 },
          frameRate: 15,
          deviceId: { exact: deviceId },
        },
        audio: false,
      };
      yield productMediaController.updateConstraints(newContraints);
    } catch (error) {
      yield AppSagas.reportError(error);
      return;
    }

    if (skipStatusChange) return;

    try {
      const faceDeviceId = yield select(LiveSellerSelectors.faceDeviceId);
      const videoStatus = deviceId === faceDeviceId ? "FACE" : "PRODUCT";

      const event = yield select(LiveSellerSelectors.currentEvent);

      yield put(
        SocketActions.requestSendMessage(Live.SET_VIDEO_ROOM_STATUS, {
          roomUrl: event.alias,
          videoStatus: videoStatus,
          waitingVideoIndex: 0,
        })
      );
    } catch (error) {
      yield AppSagas.reportError(error);
      return;
    }
  }

  static *requestToggleFacingMode({ payload }) {
    const { facingMode } = payload;
    const constraints = {
      video: {
        facingMode: { exact: facingMode },
      },
    };

    yield productMediaController.updateConstraints(constraints);
  }

  static *requestToggleCam() {
    const camOn = yield select(LiveSellerSelectors.camOn);
    try {
      yield put(LiveSellerActions.setCamOn(!camOn));
      yield faceMediaController.toggleVideo();
    } catch (error) {
      yield put(LiveSellerActions.setCamOn(camOn));
      yield AppSagas.reportError(error);
    }
  }

  static *requestToggleMic() {
    const micOn = yield select(LiveSellerSelectors.micOn);
    try {
      yield put(LiveSellerActions.setMicOn(!micOn));
      const mediaController = isMobile
        ? productMediaController
        : faceMediaController;
      yield mediaController.toggleAudio();
    } catch (error) {
      yield put(LiveSellerActions.setMicOn(micOn));
      yield AppSagas.reportError(error);
    }
  }

  static *requestStartWaitingScreen({ payload }) {
    const { videoId } = payload;
    const event = yield select(LiveSellerSelectors.currentEvent);
    if (!event?.alias) {
      yield AppSagas.reportError(
        "run requestStartWaitingScreen without a alias"
      );
      return;
    }
    yield put(AppActions.reportEvent("obswaiting", "start", videoId));
    yield put(
      SocketActions.requestSendMessage(Live.SET_VIDEO_ROOM_STATUS, {
        roomUrl: event.alias,
        videoStatus: "WAITING",
        waitingVideoIndex: videoId,
      })
    );
  }

  static *requestStopWaitingScreen({ payload }) {
    // const { videoIndex } = payload;
    const event = yield select(LiveSellerSelectors.currentEvent);
    if (!event?.alias) {
      yield AppSagas.reportError(
        "run requestStopWaitingScreen without a roomId"
      );
      return;
    }

    const currentDeviceId = yield select(LiveSellerSelectors.currentDeviceId);

    const faceDeviceId = yield select(LiveSellerSelectors.faceDeviceId);
    const videoStatus = currentDeviceId === faceDeviceId ? "FACE" : "PRODUCT";
    yield put(
      SocketActions.requestSendMessage(Live.SET_VIDEO_ROOM_STATUS, {
        roomUrl: event.alias,
        videoStatus: videoStatus,
        waitingVideoIndex: 0,
      })
    );
  }

  static *gotRoomStatus({ payload }) {
    const roomStatus = payload.roomStatus;
    const waitingVideoIndex = payload.waitingVideoIndex;

    yield put(LiveSellerActions.setStatus(roomStatus, waitingVideoIndex));
  }

  static *onJoined({ payload }) {
    // console.log("----- on face joined------");
    // yield LiveSellerSagas.requestSetPublisher(payload.publisherId);
  }

  static *requestSetPublisher(publisherId) {
    const micOn = yield select(LiveSellerSelectors.micOn);
    const camOn = yield select(LiveSellerSelectors.camOn);
    yield put(
      SocketActions.requestSendMessage("live:set_publisher", {
        id: publisherId,
        isAudioMuted: !micOn,
        isVideoMuted: !camOn,
      })
    );
  }

  static *requestLeaveLive() {
    try {
      yield call(LiveSellerSagas.onHardLeave);

      window.removeEventListener("beforeunload", LiveSellerSagas.onHardLeave);

      if (socketRoom) {
        yield socketRoom.leave();
        socketRoom = null;
      }
      yield put(LiveSellerActions.clearAll());
      console.log("leave success");
    } catch (error) {
      console.log("fail to leave", error);
      AppSagas.reportError(error);
    }
  }

  static *onHardLeave() {
    if (faceMediaController) {
      yield faceMediaController.unpublish();
      faceMediaController = null;
    }
    if (productMediaController) {
      yield productMediaController.unpublish();
      productMediaController = null;
    }

    if (facePeer) {
      yield facePeer.destroy();
      facePeer = null;
    }

    if (productPeer) {
      yield productPeer.destroy();
      productPeer = null;
    }
    if (janusRoom) {
      yield janusRoom.leave();
      yield janusRoom.destroy();
      janusRoom = null;
    }

    if (ChatSagas.chatRoom) {
      yield ChatSagas.chatRoom.destroy();
      ChatSagas.chatRoom = null;
    }
  }

  static *requestKillLive() {
    try {
      const room = yield select(LiveSellerSelectors.currentRoom);
      const event = yield select(LiveSellerSelectors.currentEvent);
      if (room) {
        yield put(
          SocketActions.requestSendMessage(Live.SET_VIDEO_ROOM_STATUS, {
            roomUrl: event.alias,
            videoStatus: "END",
          })
        );
        const closeRoomResult = yield ApolloService.mutate(CLOSE_ROOM, {
          id: room.roomId,
        });
        if (!closeRoomResult.ok) {
          AppSagas.reportError(closeRoomResult.error);
        } else {
          yield put(AppActions.reportEvent("obsba", "closeRoom"));
        }
      }
    } catch (error) {
      AppSagas.reportError(error);
    }
  }

  static *requestStartClientCamera({ payload }) {
    const { publisherId } = payload;
    const event = yield select(LiveSellerSelectors.currentEvent);

    if (!event?.alias) {
      yield AppSagas.reportError(
        "run requestStartClientCamera without a alias"
      );
      return;
    }
    yield put(
      SocketActions.requestSendMessage(Live.SET_VIDEO_ROOM_STATUS, {
        roomUrl: event.alias,
        videoStatus: "PARTICIPANT",
        waitingVideoIndex: publisherId,
      })
    );
  }

  static *resetStatusOnRemovePublisher({ payload }) {
    const { publisherId } = payload;
    const status = yield select(LiveSellerSelectors.status);
    const waitingVideoIndex = yield select(
      LiveSellerSelectors.waitingVideoIndex
    );

    if (status === "PARTICIPANT" && publisherId === waitingVideoIndex) {
      const event = yield select(LiveSellerSelectors.currentEvent);

      if (!event?.alias) {
        yield AppSagas.reportError(
          "run requestStartClientCamera without a alias"
        );
        return;
      }
      yield put(
        SocketActions.requestSendMessage(Live.SET_VIDEO_ROOM_STATUS, {
          roomUrl: event.alias,
          videoStatus: "FACE",
          waitingVideoIndex: 0,
        })
      );
    }
  }

  static *requestMutePublisherAudio({ payload }) {
    const { publisherId } = payload;
    yield put(
      SocketActions.requestSendMessage(Live.MUTE_PUBLISHER_AUDIO, {
        publisherId: publisherId.toString(),
      })
    );
  }

  static *requestLoadVideos() {
    try {
      const lang = yield select(AppSelectors.lang);
      const result = yield ApolloService.query(LIST_VIDEOS, {
        lang,
        filters: {},
      });

      if (result.ok) {
        yield put(LiveSellerActions.loadVideosSuccess(result.data.videos));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestLoadVideoGroups() {
    try {
      const lang = yield select(AppSelectors.lang);
      const result = yield ApolloService.query(LIST_VIDEO_GROUPS, {
        lang,
        filters: { isVisible: true },
      });

      if (result.ok) {
        yield put(
          LiveSellerActions.loadVideoGroupsSuccess(result.data.videoGroups)
        );
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *loop() {
    yield all([
      yield takeLatest(
        LiveSellerActions.requestLoadEvent.getType(),
        LiveSellerSagas.requestLoadEvent
      ),
      yield takeLatest(
        LiveSellerActions.requestLoadNextEvent.getType(),
        LiveSellerSagas.requestLoadNextEvent
      ),
      yield takeLatest(
        LiveSellerActions.requestLoadVideos.getType(),
        LiveSellerSagas.requestLoadVideos
      ),
      yield takeLatest(
        LiveSellerActions.requestLoadVideoGroups.getType(),
        LiveSellerSagas.requestLoadVideoGroups
      ),
      yield takeLeading(
        LiveSellerActions.requestJoinLive.getType(),
        LiveSellerSagas.requestJoinLive
      ),
      yield takeLatest(
        LiveSellerActions.requestChangeProductCameraId.getType(),
        LiveSellerSagas.requestChangeProductCameraId
      ),
      yield takeLeading(
        LiveSellerActions.requestToggleCam.getType(),
        LiveSellerSagas.requestToggleCam
      ),
      yield takeLeading(
        LiveSellerActions.requestToggleMic.getType(),
        LiveSellerSagas.requestToggleMic
      ),
      yield takeLatest(
        LiveSellerActions.requestStartWaitingScreen.getType(),
        LiveSellerSagas.requestStartWaitingScreen
      ),
      yield takeLatest(
        LiveSellerActions.requestStopWaitingScreen.getType(),
        LiveSellerSagas.requestStopWaitingScreen
      ),
      yield takeLatest(
        SocketActions.gotRoomStatus.getType(),
        LiveSellerSagas.gotRoomStatus
      ),
      yield takeLatest(
        faceActions.onJoined.getType(),
        LiveSellerSagas.onJoined
      ),
      yield takeLeading(
        LiveSellerActions.requestLeaveLive.getType(),
        LiveSellerSagas.requestLeaveLive
      ),
      yield takeLeading(
        LiveSellerActions.requestKillLive.getType(),
        LiveSellerSagas.requestKillLive
      ),
      yield takeLeading(
        LiveSellerActions.requestToggleFacingMode.getType(),
        LiveSellerSagas.requestToggleFacingMode
      ),
      yield takeLatest(
        LiveSellerActions.requestStartClientCamera.getType(),
        LiveSellerSagas.requestStartClientCamera
      ),
      yield takeLatest(
        faceActions.removePublisher.getType(),
        LiveSellerSagas.resetStatusOnRemovePublisher
      ),
      yield takeLeading(
        LiveSellerActions.requestReconnect.getType(),
        LiveSellerSagas.requestReconnect
      ),
      yield takeLatest(
        LiveSellerActions.requestMutePublisherAudio.getType(),
        LiveSellerSagas.requestMutePublisherAudio
      ),
      yield takeEvery(
        LiveSellerActions.setServiceFailed.getType(),
        LiveSellerSagas.setServiceFailed
      ),
      yield takeLeading(
        LiveSellerActions.requestShareScreen.getType(),
        LiveSellerSagas.requestShareScreen
      ),
      yield takeLeading(
        LiveSellerActions.requestStopShareScreen.getType(),
        LiveSellerSagas.requestStopShareScreen
      ),
    ]);
  }
}
