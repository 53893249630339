const IconTypes = {
	ADD: 'add',
	CALENDAR: 'calendar',
	CAMERA_MUTE: 'camera-mute',
	CHEVRON_DOWN: 'chevron-down',
	CHEVRON_LEFT: 'chevron-left',
	CHEVRON_RIGHT: 'chevron-right',
	CLOCK: 'clock',
	COPY: 'copy',
	FULL_SCREEN: 'full-screen',
	HANG_UP: 'hang-up',
	HIGH_SIGNAL: 'high-signal',
	ICON_CAMERA: 'icon-camera',
	ICON_CHAT: 'icon-chat',
	ICON_MICROPHONE: 'icon-microphone',
	ICON_SHARE_SCREEN: 'icon-share-screen',
	ICON_SWAP_CAMERA: 'icon-swap-camera',
	LINK: 'link',
	LOG_OUT: 'log-out',
	MICROPHONE_MUTE: 'microphone-mute',
	MOBILE: 'mobile',
	MUTE: 'mute',
	PLANNING_ADD: 'planning-add',
	REMOVE: 'remove',
	SEND: 'send',
	SMALL_SCREEN: 'small-screen',
	UNMUTE: 'unmute',
	WIFI: 'wifi',
	ZOOM: 'zoom',
};

export default IconTypes;
