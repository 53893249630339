class PeerMap {
  constructor() {
    this.peers = new Map();
  }

  addPeer(peer) {
    if (!peer.publisher.publisherId) {
      console.log("adding peer in peermap without publisherId");
    }
    this.peers.set(peer.publisher.publisherId, peer);
  }

  getPeer(publisherId) {
    return this.peers.get(publisherId);
  }

  removePeer(publisherId) {
    this.peers.delete(publisherId);
  }
}

const peerMap = new PeerMap();

export default peerMap;
