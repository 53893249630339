import {
  all,
  put,
  call,
  take,
  takeEvery,
  takeLatest,
  fork,
  select,
} from "redux-saga/effects";
import { eventChannel } from "redux-saga";

import {
  actions as SocketActions,
  selectors as SocketSelectors,
} from "./redux";
import {
  actions as AuthActions,
  selectors as AuthSelectors,
} from "../auth/redux";
import { actions as EventsActions } from "../events/redux";
import { Live, Event } from "./messageTypes";

import { getManager, getSocket } from "../../services/Socket";
import AppSagas from "../app/sagas";

let socket;
let manager;

const startSocket = ({ payload }) => {
  const { socketUrl, socketPath } = payload;
  return eventChannel((emmiter) => {
    socket = getSocket(socketUrl, socketPath);
    manager = getManager(socketUrl, socketPath);
    socket.on("connect", () => {
      console.info("CONNNECTEEEEEED", socket.id);
      emmiter(SocketActions.setSocketId(socket.id));
      emmiter(SocketActions.connectSuccess());
    });
    manager.on("reconnect", (e) => {});
    manager.on("reconnect_attempt", (e) =>
      console.info("reconnect_attempt", e)
    );
    manager.on("reconnect_error", (e) => {
      console.error("reconnect_error", e);
      // emmiter(AppActions.setHasNetwork(false));
    });
    manager.on("reconnect_failed", (e) => console.error("reconnect_failed", e));
    // socket.on(Live.MESSAGE_TO_ALL, (messageData) => {
    //   emmiter(
    //     ChatActions.addMessage({ ...convertMsg(messageData), type: "CHAT" })
    //   );
    // });
    socket.on(Live.AUTHENTICATE_SUCCESS, () => {
      emmiter(SocketActions.authSuccess());
    });
    socket.on("error", (data) => {
      AppSagas.reportError(data);
      emmiter(SocketActions.connectFail(data.msg));
    });
    // socket.on(Live.USER_JOIN_LIVE, (data) =>
    //   emmiter(SocketActions.onUserJoinLive(data))
    // );
    // socket.on(Live.USER_LEFT_LIVE, (data) => {
    //   emmiter(SocketActions.onUserLeftLive(data));

    // });
    socket.on(Live.ROOM_STATUS, (status) => {
      emmiter(SocketActions.gotRoomStatus(status));
    });
    // socket.on(
    //   Live.TOTAL_UPDATE_LIVE,
    //   (messageData) => console.log(Live.TOTAL_UPDATE_LIVE, messageData)
    // );
    socket.on(Event.EVENT_CREATED, (eventId) => {
      emmiter(EventsActions.requestLoadEvent(Event.EVENT_CREATED, eventId));
    });
    socket.on(Event.EVENT_EDITED, (eventId) => {
      emmiter(EventsActions.requestLoadEvent(Event.EVENT_EDITED, eventId));
    });
    socket.on(Event.EVENT_DELETED, (eventId) => {
      emmiter(EventsActions.eventDeleted(eventId));
    });
    socket.on(Event.MUTE_SELF_AUDIO, () => {
      emmiter(SocketActions.requestSelfMuteAudio());
    });
    return () => null;
  });
};

function sendMessage(eventName, data) {
  if (!socket) {
    return;
  }
  socket.emit(eventName, data);
}

export default class SocketSagas {
  static *watchConnect({ payload }) {
    const socketChannel = yield call(startSocket, { payload });

    try {
      while (true) {
        // take(END) will cause the saga to terminate by jumping to the finally block
        let socketAction = yield take(socketChannel);
        yield put(socketAction);
      }
    } finally {
    }
  }

  static *requestConnect({ payload }) {
    if (socket) {
      return;
    }
    yield fork(SocketSagas.watchConnect, { payload });
  }

  static *requestSendAuth({ payload }) {
    let { token } = payload;
    const tokenIsLive = yield select(AuthSelectors.tokenIsLive);
    const isGuest = yield select(SocketSelectors.isGuest);
    if (!isGuest && !tokenIsLive) {
      yield take(AuthActions.refreshTokenSuccess.getType());
      const newToken = yield select(AuthSelectors.token);
      token = newToken.idToken;
    }
    socket.emit("authenticate", {
      token,
    });
  }

  static *connectSuccess() {
    try {
      const isGuest = yield select(SocketSelectors.isGuest);
      if (isGuest) return;
      const connected = yield select(SocketSelectors.connected);
      if (connected) {
        const token = yield select(AuthSelectors.token);
        if (token.idToken)
          yield put(SocketActions.requestSendAuth(token.idToken));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static requestSendMessage({ payload }) {
    const { eventName, data } = payload;
    sendMessage(eventName, data);
  }

  static requestSendEventCreated({ payload }) {
    const { event } = payload;
    sendMessage(Event.EVENT_CREATED, event.id);
  }

  static requestSendEventEdited({ payload }) {
    const { eventId } = payload;
    sendMessage(Event.EVENT_EDITED, eventId);
  }

  static requestSendEventDeleted({ payload }) {
    const { eventId } = payload;
    sendMessage(Event.EVENT_DELETED, eventId);
  }

  // static *onUserJoinLive({ payload }) {
  //   try {
  //     const { data } = payload;
  //     yield put(ChatActions.requestLoadUser(data.user._id));
  //     const isGuest = yield select(SocketSelectors.isGuest);
  //     if (isGuest) return;
  //     yield put(
  //       ChatActions.addMessage({
  //         msg: "clientEnterRoom",
  //         userId: data.user._id,
  //         type: "INFO",
  //       })
  //     );
  //   } catch (error) {
  //     AppSagas.reportError(error);
  //   }
  // }

  // static *onUserLeftLive({ payload }) {
  //   try {
  //     const { data } = payload;
  //     const isGuest = yield select(SocketSelectors.isGuest);
  //     if (isGuest) return;
  //     yield put(
  //       ChatActions.addMessage({
  //         msg: "clientLeftRoom",//no more existing mock
  //         userId: data.userId,
  //         type: "INFO",
  //       })
  //     );
  //   } catch (error) {
  //     AppSagas.reportError(error);
  //   }
  // }

  static connectFail({ payload }) {
    console.error("connect socekt fail", payload);
  }

  static *loop() {
    yield all([
      takeEvery(
        SocketActions.requestConnect.getType(),
        SocketSagas.requestConnect
      ),
      takeEvery(
        SocketActions.connectSuccess.getType(),
        SocketSagas.connectSuccess
      ),
      takeEvery(
        SocketActions.requestSendMessage.getType(),
        SocketSagas.requestSendMessage
      ),
      takeEvery(SocketActions.connectFail.getType(), SocketSagas.connectFail),
      takeLatest(
        AuthActions.authWillSuccess.getType(),
        SocketSagas.connectSuccess
      ),
      takeLatest(
        SocketActions.requestSendAuth.getType(),
        SocketSagas.requestSendAuth
      ),
      // takeEvery(
      //   SocketActions.onUserJoinLive.getType(),
      //   SocketSagas.onUserJoinLive
      // ),
      // takeEvery(
      //   SocketActions.onUserLeftLive.getType(),
      //   SocketSagas.onUserLeftLive
      // ),
      takeEvery(
        EventsActions.createEventSuccess.getType(),
        SocketSagas.requestSendEventCreated
      ),
      takeEvery(
        EventsActions.editEventSuccess.getType(),
        SocketSagas.requestSendEventEdited
      ),
      takeEvery(
        EventsActions.deleteEventSuccess.getType(),
        SocketSagas.requestSendEventDeleted
      ),
    ]);
  }
}
