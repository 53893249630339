import img_src from "../assets/images/speed-test-file.jpg";
import env from "../env";

const DEFAULT_ROOM_TYPE = "CONFIDENTIAL_ROOM";

export default {
  // Nom de la cam qui montre le visage du BA
  // Use the first camera if BA_FACE_CAMERA_NAME not found
  // TODO This config is temporary and will have to be parameterized by the BA
  // only on Mac
  // BA_FACE_CAMERA_NAME: "FaceTime",
  // with Logitech webcam (check the name of your camera in google meet parameters)
  // BA_FACE_CAMERA_NAME: "UVC Camera",
  BA_FACE_CAMERA_NAME: "BRIO 4K",
  BA_PRODUCT_CAMERAS: ["WIDE", "MACRO"],

  BOTSWANA_CAM: {
    name: "liveCamTitle", //use t with this key
    description: "",
    videoUrl: "botswanaCam",
    coverUrl: "https://debeersstorage.blob.core.windows.net/upload/savane.png",
  },

  NETWORK_TEST_IMG_SRC: img_src,

  SUPPORTED_LANGUAGES: ["en", "zh", "zh-HK", "fr"],

  LANGUAGE_SELECTOR_DATA: [
    { lang: "en", label: "english" },
    { lang: "zh", label: "chinese" },
    { lang: "zh-HK", label: "chineseHK" },
    { lang: "fr", label: "french" },
  ],

  DEFAULT_LANG: "en",

  MEETING_DURATION: 50 * 60 * 1000, //durée prévue du meeting, au dela de temps, on ne peut plus y avoir accès
  WAITING_VIDEO_PRE_BA: "https://cdn-db.azureedge.net/debeers-assets/WIB.mp4",
  WAITING_VIDEOS: [
    {
      title: "New Campaign",
      duration: "00:30",
      description: "De Beers: Where It Begins",
      video: "https://cdn-db.azureedge.net/debeers-assets/WIB.mp4",
      cover: "https://cdn-db.azureedge.net/debeers-assets/wib.jpg",
      order: 1,
    },
    {
      title: "Natural Works of Art",
      duration: "00:45",
      description: "Curated selection of exceptional diamonds",
      video: "https://cdn-db.azureedge.net/debeers-assets/nwoa.mp4",
      cover: "https://cdn-db.azureedge.net/debeers-assets/nwoa_new.png",
      order: 3,
    },
    {
      title: "Portraits of Nature Collection",
      duration: "00:55",
      description: "Butterfly",
      video: "https://cdn-db.azureedge.net/debeers-assets/portraits.mp4",
      cover: "https://cdn-db.azureedge.net/debeers-assets/portraits.jpg",
      order: 2,
    },
    {
      title: "High Jewellery",
      duration: "01:00",
      description: "Reflections of Nature",
      video: "https://cdn-db.azureedge.net/debeers-assets/reflections.mp4",
      cover: "https://cdn-db.azureedge.net/debeers-assets/reflections.jpg",
      order: 4,
    },
    {
      title: "De Beers Fundamentals",
      duration: "01:26",
      description: "A brief history of diamonds",
      video: "https://cdn-db.azureedge.net/debeers-assets/film1.mp4",
      cover:
        "https://cdn-db.azureedge.net/debeers-assets/De Beers Fundamentals - A brief history of diamonds.png",
      order: 5,
    },
    {
      title: "De Beers Fundamentals",
      duration: "01:38",
      description: "Nature’s Talismans",
      video: "https://cdn-db.azureedge.net/debeers-assets/film2.mp4",
      cover:
        "https://cdn-db.azureedge.net/debeers-assets/De Beers Fundamentals - Nature’s Talismans.png",
      order: 6,
    },
    {
      title: "De Beers Fundamentals",
      duration: "01:56",
      description: "Diamond Buying essentials",
      video: "https://cdn-db.azureedge.net/debeers-assets/film3.mp4",
      cover:
        "https://cdn-db.azureedge.net/debeers-assets/De Beers Fundamentals - Diamond buying essentials.png",
      order: 7,
    },
    {
      title: "De Beers Fundamentals",
      duration: "02:08",
      description: "Building Forever",
      video: "https://cdn-db.azureedge.net/debeers-assets/film7.mp4",
      cover:
        "https://cdn-db.azureedge.net/debeers-assets/De beers Fundamentals - Building Forever.png",
      order: 8,
    },
    {
      title: "The Alchemist of Light",
      duration: "02:07",
      description: "Chapter 1",
      video: "https://cdn-db.azureedge.net/debeers-assets/light1.mp4",
      cover: "https://cdn-db.azureedge.net/debeers-assets/light1.jpg",
      order: 9,
    },
    {
      title: "The Alchemist of Light",
      duration: "04:14",
      description: "Chapter 2",
      video:
        "https://cdn-db.azureedge.net/debeers-assets/DeBeers_AOL_FINAL4-nosubs.mp4",
      cover: "https://cdn-db.azureedge.net/debeers-assets/coverchapter2.png",
      order: 10,
    },
  ],

  PREPARE_VIDEOS: [
    {
      title: "New Campaign",
      duration: "00:30",
      description: "De Beers: Where It Begins",
      video: "https://cdn-db.azureedge.net/debeers-assets/WIB.mp4",
      cover: "https://cdn-db.azureedge.net/debeers-assets/wib.jpg",
      order: 1,
    },
    {
      title: "Live from the De Beers Diamond Route",
      description: "",
      video: "botswanaCam",
      cover: "https://cdn-db.azureedge.net/debeers-assets/savane.png",
    },
    {
      title: "Natural Works of Art",
      duration: "00:45",
      description: "Curated selection of exceptional diamonds",
      video: "https://cdn-db.azureedge.net/debeers-assets/nwoa.mp4",
      cover: "https://cdn-db.azureedge.net/debeers-assets/nwoa_new.png",
      order: 3,
    },
    {
      title: "Portraits of Nature Collection",
      duration: "00:55",
      description: "",
      video: "https://cdn-db.azureedge.net/debeers-assets/portraits.mp4",
      cover: "https://cdn-db.azureedge.net/debeers-assets/portraits.jpg",
      order: 2,
    },
    {
      title: "Reflections of Nature Collection",
      duration: "01:00",
      description: "",
      video: "https://cdn-db.azureedge.net/debeers-assets/reflections.mp4",
      cover: "https://cdn-db.azureedge.net/debeers-assets/reflections.jpg",
      order: 4,
    },
    {
      title: "De Beers Fundamentals",
      duration: "01:26",
      description: "A brief history of diamonds",
      video: "https://cdn-db.azureedge.net/debeers-assets/film1.mp4",
      cover:
        "https://cdn-db.azureedge.net/debeers-assets/De Beers Fundamentals - A brief history of diamonds.png",
      order: 5,
    },
    {
      title: "De Beers Fundamentals",
      duration: "01:38",
      description: "Nature’s Talismans",
      video: "https://cdn-db.azureedge.net/debeers-assets/film2.mp4",
      cover:
        "https://cdn-db.azureedge.net/debeers-assets/De Beers Fundamentals - Nature’s Talismans.png",
      order: 6,
    },
    {
      title: "De Beers Fundamentals",
      duration: "01:56",
      description: "Diamond Buying essentials",
      video: "https://cdn-db.azureedge.net/debeers-assets/film3.mp4",
      cover:
        "https://cdn-db.azureedge.net/debeers-assets/De Beers Fundamentals - Diamond buying essentials.png",
      order: 7,
    },
    {
      title: "De Beers Fundamentals",
      duration: "02:08",
      description: "Building Forever",
      video: "https://cdn-db.azureedge.net/debeers-assets/film7.mp4",
      cover:
        "https://cdn-db.azureedge.net/debeers-assets/De beers Fundamentals - Building Forever.png",
      order: 8,
    },
    {
      title: "The Alchemist of Light Collection",
      duration: "02:07",
      description: "Chapter 1",
      video: "https://cdn-db.azureedge.net/debeers-assets/light1.mp4",
      cover: "https://cdn-db.azureedge.net/debeers-assets/aol_ch1.png",
      order: 9,
    },
    {
      title: "The Alchemist of Light Collection",
      duration: "04:14",
      description: "Chapter 2",
      video:
        "https://cdn-db.azureedge.net/debeers-assets/DeBeers_AOL_FINAL4-nosubs.mp4",
      cover: "https://cdn-db.azureedge.net/debeers-assets/coverchapter2.png",
      order: 10,
    },
  ],

  ROOM_TYPE_OPTIONS: env.ROOM_TYPES
    ? env.ROOM_TYPES.split(",").map((value) => ({
        value,
        label: value,
      }))
    : [{ value: DEFAULT_ROOM_TYPE, label: DEFAULT_ROOM_TYPE }],
};
