import Response from "./Response";

import fetch from "cross-fetch";
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { SentryLink } from "apollo-link-sentry";

import env from "../env";
import { put } from "redux-saga/effects";
import { actions as AuthActions } from "../redux/auth/redux";

// import deviceInfo from "./deviceInfo";

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
  mutate: {
    errorPolicy: "all",
  },
};

class ApolloService {
  constructor(apolloServerUri) {
    const authLink = setContext(async (_, { headers, ...context }) => {
      const headersContent = {
        ...headers,
        authorization: this.getToken(),
        "Access-Control-Allow-Origin": "*",
      };
      return {
        headers: headersContent,
        ...context,
      };
    });
    const httpLink = new HttpLink({
      uri: apolloServerUri,
      fetch,
      fetchOptions: {
        mode: "no-cors",
      },
    });

    const sentryLink = new SentryLink({
      setTransaction: false,
      setFingerprint: false,
      attachBreadcrumbs: {
        includeQuery: true,
        includeVariables: true,
        includeContext: ["headers"],
      },
    });
    this.apolloClient = new ApolloClient({
      link: ApolloLink.from([sentryLink, authLink.concat(httpLink)]),
      cache: new InMemoryCache({
        addTypename: false,
      }),
      defaultOptions: defaultOptions,
    });
  }

  __token = "";
  __deviceInfos = {};

  getToken() {
    return this.__token;
  }

  setToken(token) {
    this.__token = token;
  }

  *query(query, variables = {}, noCache = true) {
    // console.log('--------------------------------------- QUERY');
    // console.log(JSON.stringify(variables));
    // console.log(query.loc.source.body);
    const params = {
      query,
      variables,
      options: { fetchPolicy: noCache ? "no-cache" : "cache-first" },
    };
    return yield this.graph("query", params);
  }

  *mutate(mutation, variables = {}, noCache = true) {
    // console.log("--------------------------------------- MUTATE")
    // console.log(JSON.stringify(variables))
    // console.log(mutation.loc.source.body)
    const params = {
      mutation,
      variables,
      options: { fetchPolicy: noCache ? "no-cache" : "cache-first" },
    };
    return yield this.graph("mutate", params);
  }

  *graph(method, params, retry = 0) {
    let result;
    if (retry > 10) {
      return Response.fail("too many atemps");
    }
    try {
      result = yield this.apolloClient[method](params);
      if (result.errors?.length) {
        console.log(JSON.stringify(result.errors));
        return Response.fail(
          result.errors[0]?.extensions?.code || "generic_error",
          result.errors[0]?.message
        );
      }
      return Response.success(result.data);
    } catch (error) {
      console.log(method, params, retry);
      console.log({ error }, result);
      console.log(JSON.stringify(params));
      console.log("error on retry", error.message, "retry : ", retry);
      if (
        error.networkError?.result?.errors?.length > 0 &&
        error.networkError.result.errors[0].message?.indexOf(
          "Fail to authenticate"
        ) > -1
      ) {
        yield put(AuthActions.logout());
        return Response.fail(error.networkError.result.errors[0].message);
      }
      //

      if (error.message.indexOf("jwt expired") > -1) {
        // console.log("wait for refreshing or check token");
        // yield put(AuthActions.requetRefreshToken());
        // const [updateSucceed, updateFailed] = yield race([
        //   take(AuthActions.updateToken.getType()),
        //   take(AuthActions.logout.getType()),
        // ]);
        // if (updateSucceed) {
        //   return yield this.graph(method, params, retry + 1);
        // } else {
        //   return;
        // }
      } else if (
        error.message.indexOf("invalid signature") > -1 ||
        error.message.indexOf("Authentication error") > -1
      ) {
        yield put(AuthActions.logout());
        return Response.fail(error.message);
      } else if (error.message.includes("no Token Found")) {
        return Response.fail(error.message);
      } else if (error.message.includes("Network request failed")) {
        if (method === "query" && retry < 3) {
          return yield this.graph(method, params, retry + 1);
        }
        return Response.fail(error.message);
      } else {
        return Response.fail(error.message);
      }
    }
  }
}

const apolloService = new ApolloService(env.GRAPH);
export default apolloService;
