import React, {useEffect} from "react";
import {
  actions as LiveClientActions,
  selectors as LiveClientSelectors,
} from "../../redux/liveClient/redux";
import {
  selectors as AppSelectors,
} from "../../redux/app/redux";
import { useSelector, useDispatch } from "react-redux";
import "./VideoPreloader.scss";

import { selectors as NetworkSpeedSelectors } from "../../redux/networkSpeed/redux";


const VideoPreloader = () => {
  const dispatch = useDispatch()
  const lang = useSelector(AppSelectors.lang);
  const videoByIds = useSelector(LiveClientSelectors.videoByIds)
  const videoIds = useSelector(LiveClientSelectors.videoIds)
  useEffect(() => {
    dispatch(LiveClientActions.requestLoadVideos());
  }, [lang])

  const isTestingNetwork = useSelector(NetworkSpeedSelectors.isTestingNetwork);
  if (isTestingNetwork) return null;
  return (
    <div className="video-preloader">
      {videoIds.map((id) => {
        return (
          <video key={id} src={videoByIds[id].videoUrl} preload="auto" width="1" height="1" />
        );
      })}
    </div>
  );
};

export default VideoPreloader;
