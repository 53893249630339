import {
  all,
  takeLatest,
  put,
  select,
  delay,
  take,
  takeEvery,
  takeLeading,
  fork,
  cancel,
  call,
  race,
} from "redux-saga/effects";

import { isMobile } from "react-device-detect";
import {
  JanusRoom,
  Room,
  UserMediaController,
  ChatRoom,
  RemotePeer,
} from "janus-front-sdk";
import peerMap from "../../services/PeerMap";
import createPublisherObject from "../../utils/createPublisherObject";
import ChatWatcher from "../chat/ChatWatcher";
import { getSocket } from "../../services/Socket";
import {
  actions as LiveClientActions,
  selectors as LiveClientSelectors,
} from "./redux";
import { selectors as AppSelectors } from "../../redux/app/redux";
import { selectors as NetworkSpeedSelectors } from "../networkSpeed/redux";
import ApolloService from "../../services/ApolloService";
import {
  LIST_VIDEOS,
  LOAD_EVENT_BY_ALIAS,
  LOAD_EVENT_ROOM,
  REGISTER_AS_GUEST,
} from "./graph";
import env from "../../env";
import JanusWatcher, { clientActions } from "./JanusWatcher";
import {
  actions as SocketActions,
  selectors as SocketSelectors,
} from "../socket/redux";
import { selectors as AuthSelectors } from "../auth/redux";
import { selectors as DevicesSelectors } from "../devices/redux";
import { actions as MeActions } from "../me/redux";
import AppSagas from "../app/sagas";
import { actions as AppActions } from "../app/redux";
import { checkWebRTCSupported } from "../../utils/webrtc";
import { checkIsGuest } from "../../Routing";
import ChatSagas from "../chat/sagas";
import { getIceServer } from "../liveSeller/sagas";

let janusRoom = null;
let mediaController = null;
let clientPeer = null;
let socketRoom = null;

export default class LiveClientSagas {
  static *requestStartSession(action) {
    const joiningTask = yield fork(
      LiveClientSagas.doRequestStartSession,
      action
    );
    yield race([
      take(LiveClientActions.requestReconnect.getType()),
      take(LiveClientActions.requestLeaveRoom.getType()),
    ]);
    yield cancel(joiningTask);
  }

  static *setServiceFailed({ payload }) {
    const { serviceFailed } = payload;
    if (serviceFailed) {
      console.log("x leave room");
      try {
        yield call(LiveClientSagas.onHardLeave);
        yield socketRoom.leave();
        console.log("x leave done");
      } catch (e) {
        console.log("x leave errror", e);
      }
      yield put(LiveClientActions.clearStream());

      socketRoom = null;
    }
  }

  static *requestReconnect() {
    yield put(LiveClientActions.requestStartVisio());
  }

  static *doRequestStartSession({ payload }) {
    try {
      const { alias } = payload;
      yield put(LiveClientActions.setStatus("loading"));
      const isWebrtcSupported = checkWebRTCSupported();
      if (!isWebrtcSupported) {
        AppSagas.reportError("This browser is not supported");
        yield put(LiveClientActions.setStatus("notSupported"));
        return;
      }
      yield put(LiveClientActions.requestLoadEvent(alias));
      yield take(LiveClientActions.loadEventSuccess.getType());
      const event = yield select(LiveClientSelectors.event);

      const scheduleDate = new Date(event.scheduleDate);
      const timeLeft = scheduleDate - Date.now();

      if (timeLeft > 0) {
        yield put(LiveClientActions.setStatus("countdown"));
        if (timeLeft > 24 * 60 * 60 * 1000) return;
        yield delay(timeLeft);
      }
      yield put(LiveClientActions.setStatus("prepare"));
      let roomLoadResult = yield ApolloService.query(LOAD_EVENT_ROOM, {
        eventId: event.id,
      });
      if (!roomLoadResult.ok) return;
      let room = roomLoadResult.data.event.room;
      if (room?.endDate) {
        return yield put(LiveClientActions.setStatus("END"));
      }

      yield take(LiveClientActions.requestGoToLive.getType());
      yield put(LiveClientActions.setStatus("loading"));
      room = null;

      while (!room) {
        roomLoadResult = yield ApolloService.query(LOAD_EVENT_ROOM, {
          eventId: event.id,
        });

        if (roomLoadResult.ok) {
          room = roomLoadResult?.data?.event?.room;

          if (!roomLoadResult?.data?.event?.room) {
            yield put(LiveClientActions.setStatus("pre-waiting"));
            yield delay(3000);
          }
        } else {
          throw new Error("fail to load room event");
        }
      }

      yield put(
        LiveClientActions.loadRoomSuccess(
          room.id,
          room.janusUrl,
          room.videoRoomId,
          room.startDate,
          room.owner ? room.owner : {},
          room.ownerId
        )
      );
      const isGuest = checkIsGuest();
      yield put(
        SocketActions.requestConnect(isGuest, room.socketUrl, room.socketPath)
      );
      const connected = yield select(SocketSelectors.connected);
      if (!connected) yield take(SocketActions.connectSuccess.getType());
      const displayName = yield select(LiveClientSelectors.displayName);
      const tokenResult = yield ApolloService.query(REGISTER_AS_GUEST, {
        eventId: event.id,
        displayName,
      });
      if (!tokenResult.ok) {
        yield put(LiveClientActions.setStatus("error"));
        yield put(LiveClientActions.setError("error_500"));
        yield AppSagas.reportError(tokenResult.error);
        return;
      }
      ApolloService.setToken(tokenResult.data.registerAsGuest.jwt);
      yield put(LiveClientActions.requestStartVisio());
      // yield put(
      //   LiveClientActions.setMePublisherId(clientPeer.publisher.publisherId)
      // );
    } catch (error) {
      yield put(LiveClientActions.setStatus("error"));
      yield put(LiveClientActions.setError("error_500"));
      yield AppSagas.reportError(error);
    }
  }

  static *requestStartVisio() {
    const joiningTask = yield fork(LiveClientSagas.doRequestStartVisio);
    yield race([
      take(LiveClientActions.requestReconnect.getType()),
      take(LiveClientActions.requestLeaveRoom.getType()),
    ]);
    yield cancel(joiningTask);
  }

  static *doRequestStartVisio() {
    try {
      const event = yield select(LiveClientSelectors.event);
      yield put(SocketActions.requestSendAuth(ApolloService.getToken()));
      yield take(SocketActions.authSuccess.getType());
      yield put(MeActions.requestLoadMe());

      const devices = yield select(DevicesSelectors.devices);
      let deviceList = [];
      for (let deviceId in devices) {
        deviceList.push(devices[deviceId]?.displayName);
      }
      const userDevice = yield select(LiveClientSelectors.device);

      socketRoom = new Room(getSocket(), event.alias);
      try {
        yield socketRoom.join();
      } catch (error) {
        if (error.message === "Room full")
          return yield put(LiveClientActions.setError("errorFullRoom"));
        throw error;
      }
      const token = ApolloService.getToken();
      const iceServer = yield getIceServer(token);
      janusRoom = new JanusRoom(socketRoom, {
        iceServers: [iceServer.iceServers],
      });
      yield fork(
        JanusWatcher.listenRooms,
        socketRoom,
        janusRoom,
        getSocket(),
        event.roomType
      );
      yield janusRoom.join();

      ChatSagas.chatRoom = new ChatRoom(socketRoom);
      yield fork(ChatWatcher.listenChat, socketRoom, ChatSagas.chatRoom);
      yield ChatSagas.chatRoom.join();

      const hasVideoDevice = !!userDevice.videoDeviceId;
      const hasAudioDevice = !!userDevice.audioDeviceId;

      const hasMicOn = yield select(LiveClientSelectors.micOn);
      const hasCamOn = yield select(LiveClientSelectors.camOn);

      yield put(LiveClientActions.setCamOn(hasVideoDevice && hasCamOn));
      yield put(LiveClientActions.setMicOn(hasAudioDevice && hasMicOn));

      yield put(AppActions.reportEvent("obsclient", "joinRoom"));

      const device = yield select(LiveClientSelectors.device);

      const videoSpec = isMobile
        ? { facingMode: { exact: device.videoDeviceId } }
        : { deviceId: { exact: device.videoDeviceId } };

      const audioSpec = isMobile
        ? {}
        : { deviceId: { exact: device.audioDeviceId } };

      const constraints = {
        video: {
          width: { min: 426, ideal: 854, max: 854 },
          height: { min: 240, ideal: 480, max: 480 },
          ...videoSpec,
        },
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
          ...audioSpec,
        },
      };

      clientPeer = janusRoom.createLocalPeer();
      yield fork(JanusWatcher.listenLocalPeer, socketRoom, clientPeer);
      const janusIdentifier = "client:" + Math.random();
      mediaController = new UserMediaController(clientPeer);

      if (!device.audioDeviceId) {
        constraints.audio = false;
      }
      if (!device.videoDeviceId) {
        constraints.video = false;
      }

      yield mediaController.publish(
        constraints,
        janusIdentifier,
        !!device.audioDeviceId,
        !!device.videoDeviceId
      );
      // yield clientPeer.publish(faceStream, janusIdentifier, true, true, "LOW");
      peerMap.addPeer(clientPeer);

      if (!hasMicOn) {
        yield mediaController.handleUseAudio(false);
      }

      if (!hasCamOn) {
        yield mediaController.handleUseVideo(false);
      }

      yield put(
        clientActions.newPublisher(
          createPublisherObject(socketRoom, clientPeer)
        )
      );
      yield put(
        LiveClientActions.setMePublisherId(clientPeer.publisher.publisherId)
      );
    } catch (e) {
      AppSagas.reportError(e);
      yield put(LiveClientActions.setError("error_500"));
    }
  }

  static *requestLoadEvent({ payload }) {
    try {
      const { alias } = payload;

      const result = yield ApolloService.query(LOAD_EVENT_BY_ALIAS, { alias });
      if (result.ok && result.data.eventByAlias) {
        const { scheduleDate, owner, id, roomType, client } =
          result.data.eventByAlias;
        yield put(
          LiveClientActions.loadEventSuccess(
            id,
            scheduleDate,
            owner ? owner : {},
            alias,
            roomType,
            client ? client : {}
          )
        );
      } else {
        yield put(LiveClientActions.setError("errorNotFound"));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestToggleCam() {
    const camOn = yield select(LiveClientSelectors.camOn);
    try {
      yield put(LiveClientActions.setCamOn(!camOn));
      yield mediaController.toggleVideo();
    } catch (error) {
      yield put(LiveClientActions.setCamOn(camOn));
      yield AppSagas.reportError(error);
    }
  }

  static *requestToggleMic() {
    const micOn = yield select(LiveClientSelectors.micOn);
    try {
      yield put(LiveClientActions.setMicOn(!micOn));
      yield mediaController.toggleAudio();
    } catch (error) {
      yield put(LiveClientActions.setMicOn(micOn));

      yield AppSagas.reportError(error);
    }
  }

  static *makeVisibleOnMobileConference({ roomStatus, waitingVideoIndex }) {
    console.log("makeVisibleOnMobileConference");
    console.log("makeVisibleOnMobileConference isMobile", isMobile);
    //guards
    if (!isMobile) return;
    const event = yield select(LiveClientSelectors.event);
    const roomType = event.roomType;

    console.log("makeVisibleOnMobileConference roomType", roomType);
    if (roomType !== "CONFERENCE_ROOM") return;

    console.log("makeVisibleOnMobileConference past guards");
    //ancien participant mis en avant (si il y en a)
    const actualStatus = yield select(LiveClientSelectors.status);
    if (actualStatus === "PARTICIPANT") {
      const actualPublisherId = yield select(
        (state) => state.liveClient.waitingVideoIndex
      );
      const peer = peerMap.getPeer(actualPublisherId);
      if (peer instanceof RemotePeer) {
        peer.setUseVideo(false);
      }
    }

    console.log("makeVisibleOnMobileConference 1", roomStatus);
    console.log("makeVisibleOnMobileConference 2", waitingVideoIndex);
    //nouveau participant
    if (roomStatus === "PARTICIPANT") {
      const peer = peerMap.getPeer(waitingVideoIndex);
      if (peer instanceof RemotePeer) {
        console.log("makeVisibleOnMobileConference set use video true");
        peer.setUseVideo(true);
      }
    }
  }

  static *gotRoomStatus({ payload }) {
    yield LiveClientSagas.makeVisibleOnMobileConference(payload);
    const videoStatus = payload.roomStatus;
    const waitingVideoIndex = payload.waitingVideoIndex;

    yield put(LiveClientActions.setStatus(videoStatus, waitingVideoIndex));
  }

  static *onJoined({ payload }) {
    yield put(LiveClientActions.setPublisherId(payload.publisherId));
    yield LiveClientSagas.requestSetPublisher();
    window.addEventListener("beforeunload", LiveClientSagas.onHardLeave);
  }

  static *requestSetPublisher() {
    const micOn = yield select(LiveClientSelectors.micOn);
    const camOn = yield select(LiveClientSelectors.camOn);
    const publisherId = yield select(LiveClientSelectors.publisherId);
    yield put(
      SocketActions.requestSendMessage("live:set_publisher", {
        id: publisherId,
        isAudioMuted: !micOn,
        isVideoMuted: !camOn,
      })
    );
  }

  static *requestLeaveRoom() {
    if (mediaController) {
      yield mediaController.unpublish();
      mediaController = null;
    }

    if (clientPeer) {
      yield clientPeer.leave();
      clientPeer = null;
    }

    if (ChatSagas.chatRoom) {
      yield ChatSagas.chatRoom.leave();
      ChatSagas.chatRoom = null;
    }

    if (janusRoom) {
      yield janusRoom.leave();
      janusRoom = null;
    }

    if (socketRoom) {
      socketRoom.leave();
      socketRoom = null;
    }
  }

  static *requestSwapMobileCamera() {
    const device = yield select(LiveClientSelectors.device);
    const facingMode = device.videoDeviceId; //=== "user" ? "environment" : "user";
    console.log("new facing mode : ", facingMode);

    janusRoom.updateContraints({
      video: {
        width: { min: 640, ideal: 800, max: 800 },
        height: { min: 480, ideal: 600, max: 600 },
        facingMode: { exact: facingMode },
      },
      audio: false,
    });
  }

  static *onHardLeave() {
    if (mediaController) {
      yield mediaController.unpublish();
      mediaController = null;
    }

    if (clientPeer) {
      yield clientPeer.destroy();
      clientPeer = null;
    }

    if (janusRoom) {
      yield janusRoom.leave();
      yield janusRoom.destroy();
      janusRoom = null;
    }

    if (ChatSagas.chatRoom) {
      yield ChatSagas.chatRoom.destroy();
      ChatSagas.chatRoom = null;
    }
  }

  static *requestSelfMuteAudio() {
    try {
      const micOn = yield select(LiveClientSelectors.micOn);
      if (micOn) {
        mediaController.toggleAudio();
        yield put(LiveClientActions.setMicOn(false));
      }
    } catch (e) {
      yield AppSagas.reportError(e);
    }
  }

  static *requestLoadVideos() {
    try {
      const lang = yield select(AppSelectors.lang);
      const result = yield ApolloService.query(LIST_VIDEOS, { lang });

      if (result.ok) {
        yield put(LiveClientActions.loadVideosSuccess(result.data.videos));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *loop() {
    yield all([
      yield takeLatest(
        LiveClientActions.requestLoadVideos.getType(),
        LiveClientSagas.requestLoadVideos
      ),
      yield takeLatest(
        LiveClientActions.requestStartSession.getType(),
        LiveClientSagas.requestStartSession
      ),
      yield takeLatest(
        LiveClientActions.requestLoadEvent.getType(),
        LiveClientSagas.requestLoadEvent
      ),
      yield takeLeading(
        LiveClientActions.requestToggleCam.getType(),
        LiveClientSagas.requestToggleCam
      ),
      yield takeLeading(
        LiveClientActions.requestToggleMic.getType(),
        LiveClientSagas.requestToggleMic
      ),
      yield takeLatest(
        SocketActions.gotRoomStatus.getType(),
        LiveClientSagas.gotRoomStatus
      ),
      yield takeEvery(
        clientActions.onJoined.getType(),
        LiveClientSagas.onJoined
      ),
      yield takeLeading(
        LiveClientActions.requestLeaveRoom.getType(),
        LiveClientSagas.requestLeaveRoom
      ),
      yield takeLatest(
        LiveClientActions.requestSwapMobileCamera.getType(),
        LiveClientSagas.requestSwapMobileCamera
      ),
      yield takeLatest(
        SocketActions.requestSelfMuteAudio.getType(),
        LiveClientSagas.requestSelfMuteAudio
      ),
      yield takeLeading(
        LiveClientActions.requestReconnect.getType(),
        LiveClientSagas.requestReconnect
      ),
      yield takeEvery(
        LiveClientActions.setServiceFailed.getType(),
        LiveClientSagas.setServiceFailed
      ),
      yield takeEvery(
        LiveClientActions.requestStartVisio.getType(),
        LiveClientSagas.requestStartVisio
      ),
    ]);
  }
}
