import {
  all,
  takeEvery,
  takeLeading,
  put,
  call,
  select,
} from "redux-saga/effects";
import { actions as AdminActions, selectors as AdminSelectors } from "./redux";
import {
  selectors as AppSelectors,
} from "../../redux/app/redux";
import { selectors as AuthSelectors } from "../../redux/auth/redux";
import ApolloService from "../../services/ApolloService";
import {
  LIST_USERS,
  UPDATE_USER,
  LIST_BOUTIQUES_AS_ADMIN,
  CREATE_USER,
  CREATE_BOUTIQUE,
  UPDATE_BOUTIQUE,
  CREATE_VIDEO_GROUP,
  UPDATE_VIDEO_GROUP,
  LOAD_VIDEO_GROUP,
  LIST_VIDEO_GROUPS,
  DELETE_VIDEO_GROUPS,
  LIST_VIDEOS,
  LOAD_VIDEO,
  CREATE_VIDEO,
  UPDATE_VIDEO,
  DELETE_VIDEO,
} from "./graph";
import AppSagas from "../app/sagas";
import { uploadFile, uploadVideo } from "../../utils/uploadFile";

export default class AdminSaga {
  static *requestCreateVideoGroup({ payload }) {
    try {
      const { lang, videoGroup } = payload;
      yield put(AdminActions.setIsSendingRequest());
      const result = yield ApolloService.mutate(CREATE_VIDEO_GROUP, {
        lang,
        videoGroup,
      });

      if (result.ok) {
        yield put(
          AdminActions.requestCreateVideoGroupSuccess(
            result.data.createVideoGroup
          )
        );
      } else {
        yield put(AdminActions.requestFailed(500));
        yield AppSagas.reportError(result);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestUpdateVideoGroup({ payload }) {
    try {
      const { videoGroupId, lang, videoGroup } = payload;
      yield put(AdminActions.setIsSendingRequest());
      const result = yield ApolloService.mutate(UPDATE_VIDEO_GROUP, {
        lang,
        id: videoGroupId,
        videoGroup,
      });

      if (result.ok) {
        yield put(
          AdminActions.requestUpdateVideoGroupSuccess(
            result.data.updateVideoGroup
          )
        );
      } else {
        yield put(AdminActions.requestFailed(500));
        yield AppSagas.reportError(result);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestLoadVideoGroups() {
    try {
      const lang = yield select(AppSelectors.lang);
      const result = yield ApolloService.query(LIST_VIDEO_GROUPS, { lang });

      if (result.ok) {
        yield put(AdminActions.loadVideoGroupsSuccess(result.data.videoGroups));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestDeleteVideoGroup({ payload }) {
    try {
      const { videoGroupId } = payload;
      const result = yield ApolloService.query(DELETE_VIDEO_GROUPS, {
        id: videoGroupId,
      });

      if (result.ok) {
        yield put(AdminActions.deleteVideoGroupSuccess(result.data));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestDeleteVideo({ payload }) {
    try {
      const { videoId } = payload;
      const result = yield ApolloService.query(DELETE_VIDEO, {
        id: videoId,
      });

      if (result.ok) {
        yield put(AdminActions.deleteVideoSuccess(result.data));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestLoadVideos() {
    try {
      const videoGroupId = yield select(AdminSelectors.selectedVideoGroupId);
      const lang = yield select(AppSelectors.lang);
      const result = yield ApolloService.query(LIST_VIDEOS, {
        lang,
        filters: { videoGroupId: videoGroupId ? videoGroupId : undefined },
      });

      if (result.ok) {
        yield put(AdminActions.loadVideosSuccess(result.data.videos));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestLoadVideoById({ payload }) {
    try {
      const { id, lang } = payload;
      const result = yield ApolloService.query(LOAD_VIDEO, {
        lang,
        id,
      });

      if (result.ok) {
        yield put(AdminActions.loadVideoByIdSuccess(result.data.video));
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestLoadVideoGroupById({ payload }) {
    try {
      const { id, lang } = payload;
      const result = yield ApolloService.query(LOAD_VIDEO_GROUP, {
        lang,
        id,
      });

      if (result.ok) {
        yield put(
          AdminActions.loadVideoGroupByIdSuccess(result.data.videoGroup)
        );
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestCreateVideo({ payload }) {
    try {
      const { lang, video, videoFile, coverFile } = payload;
      yield put(AdminActions.setIsSendingRequest());
      if (videoFile) {
        const token = yield select(AuthSelectors.token);
        const videoUrl = yield uploadVideo(videoFile, token);
        video.videoUrl = videoUrl.source;
      }

      if (coverFile) {
        const token = yield select(AuthSelectors.token);
        const coverUrl = yield uploadFile(coverFile, token);
        video.coverUrl = coverUrl.source;
      }

      const result = yield ApolloService.mutate(CREATE_VIDEO, {
        lang,
        video,
      });

      if (result.ok) {
        yield put(
          AdminActions.requestCreateVideoSuccess(result.data.createVideo)
        );
      } else {
        yield put(AdminActions.requestFailed(500, result.message));
        yield AppSagas.reportError(result);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestUpdateVideo({ payload }) {
    try {
      const { videoId, lang, video, videoFile, coverFile } = payload;
      yield put(AdminActions.setIsSendingRequest());
      if (videoFile) {
        const token = yield select(AuthSelectors.token);
        const videoUrl = yield uploadVideo(videoFile, token);
        video.videoUrl = videoUrl.source;
      }

      if (coverFile) {
        const token = yield select(AuthSelectors.token);
        const coverUrl = yield uploadFile(coverFile, token);
        video.coverUrl = coverUrl.source;
      }

      const result = yield ApolloService.mutate(UPDATE_VIDEO, {
        lang,
        id: videoId,
        video,
      });

      if (result.ok) {
        yield put(
          AdminActions.requestUpdateVideoSuccess(result.data.updateVideo)
        );
      } else {
        yield put(AdminActions.requestFailed(500));
        yield AppSagas.reportError(result);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestCreateBoutique({ payload }) {
    try {
      const { boutique } = payload;
      yield put(AdminActions.setIsSendingRequest());
      const result = yield ApolloService.mutate(CREATE_BOUTIQUE, {
        boutique,
      });

      if (result.ok) {
        yield put(
          AdminActions.requestCreateBoutiqueSuccess(result.data.createBoutique)
        );
      } else {
        yield put(AdminActions.requestFailed(500));
        yield AppSagas.reportError(result);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestUpdateBoutique({ payload }) {
    try {
      const { boutiqueId, boutique } = payload;
      yield put(AdminActions.setIsSendingRequest());
      const result = yield ApolloService.mutate(UPDATE_BOUTIQUE, {
        id: boutiqueId,
        boutique,
      });

      if (result.ok) {
        yield put(
          AdminActions.requestUpdateBoutiqueSuccess(result.data.updateBoutique)
        );
      } else {
        yield put(AdminActions.requestFailed(500));
        yield AppSagas.reportError(result);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestCreateUser({ payload }) {
    try {
      const { email, password, user, baImage } = payload;

      if (baImage) {
        const token = yield select(AuthSelectors.token);
        const imageUrl = yield uploadFile(baImage, token);
        user.imageUrl = imageUrl.source;
      }

      yield put(AdminActions.setIsSendingRequest());
      const result = yield ApolloService.mutate(CREATE_USER, {
        email,
        password,
        user,
      });

      if (result.ok) {
        yield put(
          AdminActions.requestCreateUserSuccess(result.data.createUser)
        );
      } else {
        yield put(AdminActions.requestFailed(500, result.message));
        yield AppSagas.reportError(result);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestLoadUsers() {
    try {
      const boutiqueId = yield select(AdminSelectors.selectedBoutiqueId);
      const result = yield ApolloService.query(LIST_USERS, {
        pagination: { sort: { field: "firstName", order: "ASC" }, limit: 100 },
        filters: { boutiqueId: boutiqueId ? boutiqueId : undefined },
      });

      if (result.ok) {
        yield put(
          AdminActions.requestLoadUsersSuccess(result.data.usersAsAdmin)
        );
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestUpdateUser({ payload }) {
    try {
      const { userId, user, baImage } = payload;

      if (baImage) {
        const token = yield select(AuthSelectors.token);
        const imageUrl = yield uploadFile(baImage, token);
        user.imageUrl = imageUrl.source;
      }

      yield put(AdminActions.setIsSendingRequest());
      const result = yield ApolloService.mutate(UPDATE_USER, {
        id: userId,
        user,
      });

      if (result.ok) {
        yield put(
          AdminActions.requestUpdateUserSuccess(result.data.updateUser)
        );
      } else {
        yield put(AdminActions.requestFailed(500));
        yield AppSagas.reportError(result);
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *loadBoutiquesAsAdmin({ skip, limit }) {
    try {
      const result = yield ApolloService.query(LIST_BOUTIQUES_AS_ADMIN, {
        pagination: {
          skip,
          limit,
          sort: { field: "creationDate", order: "ASC" },
        },
      });
      if (result.ok) return result.data.boutiquesAsAdmin;
      return [];
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *requestLoadBoutiquesAsAdmin() {
    try {
      const limit = 25;
      let skip = 0;
      let boutiques = [];
      let loadedBoutiques = [];
      loadedBoutiques = yield call(AdminSaga.loadBoutiquesAsAdmin, {
        skip: skip,
        limit: limit,
      });
      if (loadedBoutiques && loadedBoutiques.length > 0) {
        boutiques.push(...loadedBoutiques);
        skip += loadedBoutiques.length;
        while (loadedBoutiques.length === limit) {
          loadedBoutiques = yield call(AdminSaga.loadBoutiquesAsAdmin, {
            skip: skip,
            limit: limit,
          });
          if (loadedBoutiques) {
            boutiques.push(...loadedBoutiques);
            skip += loadedBoutiques.length;
          }
        }
        if (boutiques.length) {
          yield put(AdminActions.loadBoutiquesSuccess(boutiques));
        }
      }
    } catch (error) {
      yield AppSagas.reportError(error);
    }
  }

  static *loop() {
    yield all([
      yield takeLeading(
        AdminActions.requestCreateBoutique.getType(),
        AdminSaga.requestCreateBoutique
      ),
      yield takeLeading(
        AdminActions.requestCreateUser.getType(),
        AdminSaga.requestCreateUser
      ),
      yield takeEvery(
        AdminActions.requestLoadUsers.getType(),
        AdminSaga.requestLoadUsers
      ),
      yield takeEvery(
        AdminActions.requestCreateUserSuccess.getType(),
        AdminSaga.requestLoadUsers
      ),
      yield takeEvery(
        AdminActions.requestUpdateUserSuccess.getType(),
        AdminSaga.requestLoadUsers
      ),
      yield takeLeading(
        AdminActions.requestUpdateBoutique.getType(),
        AdminSaga.requestUpdateBoutique
      ),
      yield takeLeading(
        AdminActions.requestUpdateUser.getType(),
        AdminSaga.requestUpdateUser
      ),
      yield takeLeading(
        AdminActions.requestLoadBoutiquesAsAdmin.getType(),
        AdminSaga.requestLoadBoutiquesAsAdmin
      ),
      yield takeLeading(
        AdminActions.requestLoadVideoGroups.getType(),
        AdminSaga.requestLoadVideoGroups
      ),
      yield takeLeading(
        AdminActions.requestLoadVideos.getType(),
        AdminSaga.requestLoadVideos
      ),
      yield takeEvery(
        AdminActions.requestLoadVideoById.getType(),
        AdminSaga.requestLoadVideoById
      ),
      yield takeEvery(
        AdminActions.requestLoadVideoGroupById.getType(),
        AdminSaga.requestLoadVideoGroupById
      ),
      yield takeLeading(
        AdminActions.requestDeleteVideoGroup.getType(),
        AdminSaga.requestDeleteVideoGroup
      ),
      yield takeLeading(
        AdminActions.requestDeleteVideo.getType(),
        AdminSaga.requestDeleteVideo
      ),
      yield takeEvery(
        AdminActions.deleteVideoGroupSuccess.getType(),
        AdminSaga.requestLoadVideoGroups
      ),
      yield takeEvery(
        AdminActions.deleteVideoSuccess.getType(),
        AdminSaga.requestLoadVideos
      ),
      yield takeLeading(
        AdminActions.requestCreateVideoGroup.getType(),
        AdminSaga.requestCreateVideoGroup
      ),
      yield takeLeading(
        AdminActions.requestCreateVideo.getType(),
        AdminSaga.requestCreateVideo
      ),
      yield takeLeading(
        AdminActions.requestUpdateVideoGroup.getType(),
        AdminSaga.requestUpdateVideoGroup
      ),
      yield takeEvery(
        AdminActions.requestUpdateVideoSuccess.getType(),
        AdminSaga.requestLoadVideoGroups
      ),
      yield takeLeading(
        AdminActions.requestUpdateVideo.getType(),
        AdminSaga.requestUpdateVideo
      ),
    ]);
  }
}
