import gql from "graphql-tag";

export const GET_USER = gql`
  query GetUser($userId: ID!) {
    user(id: $userId) {
      id
      firstName
      lastName
      displayName
      isSeller
    }
  }
`;

export const GET_USERS = gql`
  query GetUsersAsGuest($pagination: Pagination, $filters: UserGuestFilters!) {
    usersAsGuest(pagination: $pagination, filters: $filters) {
      id
      firstName
      lastName
      displayName
      isSeller
    }
  }
`;
