import React from "react";
import { Link } from "react-router-dom";

//voir styles/box.scss

type BlocProps = {
  //text
  "text-title1"?: boolean;
  "text-title1-italic"?: boolean;
  "text-title2"?: boolean;
  "text-title2-italic"?: boolean;
  "text-title3"?: boolean;
  "text-title4"?: boolean;
  "text-title4-uppercase"?: boolean;
  "text-title5"?: boolean;

  text1?: boolean;
  "text1-uppercase"?: boolean;
  text2?: boolean;
  "text2-uppercase"?: boolean;
  text3?: boolean;
  text4?: boolean;
  text5?: boolean;
  text6?: boolean;

  //border radius
  "br-xxs"?: boolean;
  "br-xs"?: boolean;
  "br-s"?: boolean;
  "br-m"?: boolean;
  "br-l"?: boolean;

  //padding
  "p-xxs"?: boolean;
  "p-xs"?: boolean;
  "p-s"?: boolean;
  "p-m"?: boolean;
  "p-l"?: boolean;

  //padding horizontal
  "ph-xxs"?: boolean;
  "ph-xs"?: boolean;
  "ph-s"?: boolean;
  "ph-m"?: boolean;
  "ph-l"?: boolean;

  //padding vertical
  "pv-xxs"?: boolean;
  "pv-xs"?: boolean;
  "pv-s"?: boolean;
  "pv-m"?: boolean;
  "pv-l"?: boolean;

  //padding left
  "pl-xxs"?: boolean;
  "pl-xs"?: boolean;
  "pl-s"?: boolean;
  "pl-m"?: boolean;
  "pl-l"?: boolean;

  //padding right
  "pr-xxs"?: boolean;
  "pr-xs"?: boolean;
  "pr-s"?: boolean;
  "pr-m"?: boolean;
  "pr-l"?: boolean;

  //padding top
  "pt-xxs"?: boolean;
  "pt-xs"?: boolean;
  "pt-s"?: boolean;
  "pt-m"?: boolean;
  "pt-l"?: boolean;

  //padding bottom
  "pb-xxs"?: boolean;
  "pb-xs"?: boolean;
  "pb-s"?: boolean;
  "pb-m"?: boolean;
  "pb-l"?: boolean;

  //margin right
  "ml-xxs"?: boolean;
  "ml-xs"?: boolean;
  "ml-s"?: boolean;
  "ml-m"?: boolean;
  "ml-l"?: boolean;

  //margin right
  "mr-xxs"?: boolean;
  "mr-xs"?: boolean;
  "mr-s"?: boolean;
  "mr-m"?: boolean;
  "mr-l"?: boolean;

  //margin top
  "mt-xxs"?: boolean;
  "mt-xs"?: boolean;
  "mt-s"?: boolean;
  "mt-m"?: boolean;
  "mt-l"?: boolean;

  //margin bottom
  "mb-xxs"?: boolean;
  "mb-xs"?: boolean;
  "mb-s"?: boolean;
  "mb-m"?: boolean;
  "mb-l"?: boolean;

  //background color
  "bg-0"?: boolean;
  "bg-1"?: boolean;
  "bg-acc"?: boolean;
  "bg-secondary"?: boolean;
  "bg-error"?: boolean;

  //color
  ac?: boolean;
  al?: boolean;
  sc?: boolean;
  error?: boolean;
  ts?: boolean;
  ti?: boolean;

  horizontal?: boolean;

  flex1?: boolean;

  children?: React.ReactNode;

  id?: string;
  className?: string;
  style?: React.CSSProperties;

  href?: any;
  onClick?: any;
  onScroll?: any;
};

const classNameBlackList = [
  "id",
  "key",
  "children",
  "className",
  "style",

  "href",
  "onClick",
  "onScroll",
];

const computeClassName = (props) => {
  let startName = "bloc";
  if (props.className) startName += " " + props.className;
  if (props.onClick || props.href) startName += " cursor-pointer";

  return Object.keys(props).reduce((acc, v) => {
    if (!classNameBlackList.includes(v) && !!props[v]) {
      return acc + " " + v;
    }
    return acc;
  }, startName);
};

// we can use children even though we haven't defined them in our CardProps
const Bloc: React.FC<BlocProps> = React.memo((props: BlocProps) => {
  const className = computeClassName(props);

  if (props.href) {
    return (
      <Link href={props.href}>
        <div
          id={props.id}
          className={className}
          style={props.style}
          onScroll={props.onScroll}
        >
          {props.children}
        </div>
      </Link>
    );
  }

  return (
    <div
      id={props.id}
      className={className}
      style={props.style}
      onClick={props.onClick}
      onScroll={props.onScroll}
    >
      {props.children}
    </div>
  );
});

Bloc.displayName = "Bloc";

export default Bloc;
