export const TIME = {
  BA_ACTIVE_LINK_BEFORE_START_MIN: 30,
  BA_ACTIVE_LINK_AFTER_END_MIN: 30,
  NEXT_EVENT_INTERVAL_MIN: 45,
  NEXT_EVENT_DISPLAY_BEFORE_END_MIN: 15,
  BA_CAN_OPEN_DOOR_BEFORE_MIN: 30,

  SUNDAY_VALUE: 0,
  END_HOUR_DIFF_MIN: 60,
};

export const ROLES = {
  ADMIN: "ADMIN",
  HQ_GLOBAL: "HQ_GLOBAL",
  BA_MANAGER: "BA_MANAGER",
  BA: "BA",
  BA_MOBILE: "BA_MOBILE",
  TEST: "TEST",
};
